<template>
  <header>
    <div class="verde">
      <div class="centro">
        <input id="atv-menu" type="checkbox" />
        <label for="atv-menu">
          <div class="menu">
            <span class="hamburger"></span>
          </div>
        </label>
        <div class="logo">
          <router-link :to="{ path: '/' }" class="linha">
            <img
              src="../assets/images/logo_legisla.png"
              class="logo-legisla"
              alt="logo do Legisla"
            />
          </router-link>
        </div>
        <nav>
          <div class="menu2">
            <router-link
              :to="{ name: 'PesquisaLegislacao' }"
              :class="{ 'router-link-active': isSessaoLegislacao }"
            >
              <span>Legislação</span>
            </router-link>
            <div class="submenu">
              <router-link
                :to="{ name: 'PesquisaLegislacao' }"
                class="linha"
                :class="{ 'router-link-active': isPesquisaLegislacao }"
              >
                <img
                  src="../assets/images/icon_leis.png"
                  class="icon-menu"
                  alt=" "
                />
                Leis e Decretos
                <br />
              </router-link>
              <router-link
                :to="{ name: 'ConstituicaoEstadual' }"
                target="_blank"
              >
                <img
                  src="../assets/images/icon_estadual.png"
                  class="icon-menu"
                  alt=" "
                />
                Constituição Estadual
              </router-link>
              <router-link :to="{ name: 'CadastroAcompanhamentoPublicacoes' }">
                <img
                  src="../assets/images/icon_acompanhar.png"
                  class="icon-menu"
                  alt=" "
                />
                Acompanhamento de <br />publicações
              </router-link>
              <router-link :to="{ name: 'EntendaLegislacao' }">
                <img
                  src="../assets/images/icon_entenda.png"
                  class="icon-menu"
                  alt=" "
                />
                Entenda a Legislação
                <br />Estadual
              </router-link>
            </div>
          </div>
          <router-link :to="{ name: 'PesquisaIndexAtoAdministrativo' }">
            Índice de Atos
            <br />Administrativos
          </router-link>
          <router-link :to="{ name: 'PesquisaAtoInfralegal' }">
            Atos
            <br />Infralegais
          </router-link>
          <router-link :to="{ name: 'ProjetosGovernador' }">
            Projetos de Autoria
            <br />do Governador
          </router-link>
          <router-link :to="{ name: 'PesquisaMensagemVeto' }">
            Mensagem
            <br />de Veto
          </router-link>
        </nav>
      </div>
    </div>
    <div class="centro">
      <Acessibilidade />
    </div>
  </header>
</template>

<script>
import $ from "jquery";
import Acessibilidade from "./Acessibilidade";

export default {
  name: "Menu",
  components: {
    Acessibilidade,
  },
  watch: {
    $route: function () {
      $("#atv-menu").prop("checked", false);
    },
  },
  computed: {
    // Gambiarra porque o vue-router nao esta colocando o active-router-class para um alias
    // https://github.com/vuejs/vue-router/issues/419
    isPesquisaLegislacao: function () {
      return this.$route.name === "PesquisaLegislacao";
    },
    isSessaoLegislacao: function () {
      return (
        this.$route.name === "PesquisaLegislacao" ||
        this.$route.name === "EntendaLegislacao" ||
        this.$route.name === "ConstituicaoEstadual" ||
        this.$route.name === "CadastroAcompanhamentoPublicacoes"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  height: 3rem;
}
.verde {
  background-color: #375a3a;
  width: 100%;
  height: 3.5rem;
  top: 0rem;
  position: fixed;
  z-index: 15;
  box-shadow: 0px 5px 5px rgba(73, 73, 73, 0.2);
}
.logo-legisla {
  width: 240px;
  margin: auto;
  display: flex;
  padding-top: 1rem;
}
nav {
  display: grid;
  text-align: center;
  overflow: hidden;
  position: absolute;
  top: -40rem;
  background-color: white;
  width: 100%;
  height: auto;
  margin: 0rem;
  transition: 0.5s;
  margin-top: 0.5rem;
  z-index: 11;
  border-bottom: 0.15rem solid #ffa900;
  a {
    font-family: "Poppins-ExtraLight";
    text-decoration: none;
    color: #375a3a;
    margin: 1rem;
    letter-spacing: 0.03rem;
  }
  span {
    display: none;
  }
  .submenu {
    display: grid;
  }
  img {
    display: none;
  }
}
.menu {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 0.6rem;
  left: 0.5rem;
  z-index: 3;

  .hamburger {
    display: block;
    background-color: white;
    width: 1.4rem;
    height: 2px;
    position: relative;
    z-index: 12;
    margin: 1rem auto;
  }

  .hamburger::before,
  .hamburger::after {
    background-color: white;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    transition: 0.5s ease-in-out;
  }

  .hamburger::before {
    top: 0.4em;
  }

  .hamburger::after {
    bottom: 0.35em;
  }
}

input {
  display: none;
}
input:checked ~ nav {
  top: 3rem;
}
input:checked ~ label {
  .hamburger {
    transform: rotate(45deg);
  }

  .hamburger::before,
  .hamburger::after {
    transform: rotate(90deg);
    top: 0;
  }
}
.router-link-active {
  font-family: "Poppins-Bold";
  color: #375a3a;
}
.alto-contraste {
  .verde {
    background-color: #0d2b10;
  }
  nav {
    background-color: #202020;
    a {
      color: white;
    }
    a:hover {
      color: #ffa900;
    }
  }
  .submenu {
    background-color: #202020;
    a {
      color: white;
    }
    a:hover {
      color: #ffa900;
    }
  }
}
@media screen and (min-width: 64rem) {
  .verde {
    height: 4rem;
    position: relative;
    box-shadow: none;
    .logo-legisla {
      margin: 0rem 0.5rem;
      width: 15rem;
      padding-top: 1rem;
    }
  }
  .menu {
    display: none;
  }
  nav {
    display: grid;
    position: static;
    grid-template-columns: auto auto auto auto auto;
    height: auto;
    margin: 0rem;
    background-color: transparent;
    margin: -1.8rem auto;
    width: 46rem;
    right: 1rem;
    float: right;
    border-bottom: none;
    .menu2 {
      height: 3.5rem;
      display: flex;
      span {
        margin-top: 0.75rem;
        display: block;
        font-family: "Poppins";
        font-size: 0.9rem;
      }
    }
    .submenu {
      background-color: #edf2ee;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      position: absolute;
      z-index: 4000;
      top: 4rem;
      left: 0rem;
      width: 100%;
      overflow: hidden;
      height: 0rem;
      transition: ease-in-out 0.3s;
      text-align: center;
      justify-items: center;
      a {
        color: #375a3a;
        display: flex;
        font-family: "Poppins";
        align-items: center;
      }
      a:hover {
        font-family: "Poppins-Medium";
        color: #375a3a;
        border-bottom: none;
      }
      img {
        height: 2rem;
        display: block;
        margin-top: -0.2rem;
        margin-right: 0.5rem !important;
      }
      .router-link-active {
        font-family: "Poppins-Medium";
        color: #375a3a;
        border-bottom: none;
        font-family: "Poppins";
      }
    }
    .menu2:hover {
      .submenu {
        height: 3.7rem;
        border-bottom: #ffa900 solid 0.15rem;
        padding-top: 1rem;
      }
    }
    a {
      color: white;
      margin: 0rem 1rem;
      height: 3.1rem;
      letter-spacing: 0.05rem;
      font-family: "Poppins";
      font-size: 0.9rem;
    }
    a:hover {
      border-bottom: solid 0.17rem #ffa900;
    }
  }

  .router-link-active {
    border-bottom: solid 0.17rem #ffa900;
    font-family: "Poppins-ExtraLight";
    color: white;
    font-family: "Poppins";
  }
  .alto-contraste {
    nav {
      background-color: transparent;
    }
    .submenu {
      a {
        color: white;
      }
    }
  }
}
@media screen and (min-width: 71rem) {
  .verde {
    .logo-legisla {
      width: 20rem;
    }
    nav {
      margin-top: -2.3rem;
    }
  }
}
</style>