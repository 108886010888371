<template>
  <div>
    <div class="carregando-show" v-if="carregando">
      <Loading size="medium"></Loading>
    </div>
    <div class="erro-carregando" v-if="!carregando && erro">
      <ErroResultados></ErroResultados>
    </div>
    <template v-if="!carregando && !erro">
      <ExibirLei :legislacao="legislacao" />
      <DownloadPdf 
        :url="urlPdf" 
        :evento="'visualizacao_pdf_constituicao'" 
        :categoria="'download_pdf_constituicao'" 
        :label="labelLegislacao" 
      />
    </template>
  </div>
</template>

<script>
import LegislacaoApi from "../services/LegislacaoApi";
import Loading from "../components/Loading";
import ErroResultados from "../components/ErroResultados";
import DownloadPdf from "../components/DownloadPdf";
import ExibirLei from "../components/pesquisa_legislacao/ExibirLei";

import metaDados from "../helpers/metaDados";

export default {
  name: "ConstituicaoEstadual",
  metaInfo() {
    return {
      title: "Constituição Estadual",
      titleTemplate: "%s - Casa Civil do Estado de Goiás",
      meta: [
        {
          vmid: "description",
          name: "description",
          property: "description",
          content: "Constituição do Estado de Goiás - 05/10/1989"
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: "Constituição do Estado de Goiás - 05/10/1989"
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: "Constituição do Estado de Goiás - 05/10/1989"
        },
        {
          vmid: "og:type",
          property: "og:type",
          content: "article"
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          property: "twitter:description",
          content:
            "Constituição do Estado de Goiás - 05/10/1989 - Casa Civil do Estado de Goiás"
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          property: "twitter:title",
          content:
            "Constituição do Estado de Goiás - Casa Civil do Estado de Goiás"
        },
        ...metaDados(this.$route)
      ]
    };
  },
  data() {
    return {
      carregando: true,
      erro: false,
      legislacao: null,
      urlPdf: null,
      labelLegislacao: 'Constituição Estadual',
    };
  },
  components: {
    Loading,
    ErroResultados,
    DownloadPdf,
    ExibirLei
  },
  created() {
    this.legislacaoApi = new LegislacaoApi();

    this.carregarConstituicao();
  },
  methods: {
    carregarConstituicao: async function() {
      try {
        this.carregando = true;
        this.erro = false;

        this.legislacao = await this.legislacaoApi.getConstituicaoEstadual();
        this.urlPdf = `${process.env.VUE_APP_BASE_API_URL}/api/v2/pesquisa/legislacoes/${this.legislacao.id}/pdf`;

        this.carregando = false;
      } catch (err) {
        console.log(err);

        this.erro = true;
        this.carregando = false;
        this.legislacao = null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.carregando-show {
  // position: absolute;
  // top: 40%;
  // left: 47%;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.erro-carregando {
  // position: absolute;
  // top: 40%;
  // left: 40%;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
</style>
