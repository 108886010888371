import VueRouter from "vue-router";

import ConstituicaoEstadual from "../views/ConstituicaoEstadual";
import PesquisaLegislacao from "../views/pesquisa_legislacao/PesquisaLegislacao";
import PorAno from "../views/pesquisa_legislacao/PorAno";
import PorAutor from "../views/pesquisa_legislacao/PorAutor";
import PorCategoria from "../views/pesquisa_legislacao/PorCategoria";
import PorTipo from "../views/pesquisa_legislacao/PorTipo";
import PesquisaLegislacaoShow from "../views/pesquisa_legislacao/PesquisaLegislacaoShow";
import PesquisaAtoInfralegalShow from "../views/pesquisa_ato_infralegal/PesquisaAtoInfralegalShow";
import PesquisaAtoInfralegal from "../views/pesquisa_ato_infralegal/PesquisaAtoInfralegal";
import PorAnoAtoInfralegal from "../views/pesquisa_ato_infralegal/PorAnoAtoInfralegal";
import PorTipoAtoInfralegal from "../views/pesquisa_ato_infralegal/PorTipoAtoInfralegal";
import PorOrgaoAtoInfralegal from "../views/pesquisa_ato_infralegal/PorOrgaoAtoInfralegal";
import PesquisaMensagemVeto from "../views/pesquisa_mensagem_veto/PesquisaMensagemVeto";
import PesquisaMensagemVetoShow from "../views/pesquisa_mensagem_veto/PesquisaMensagemVetoShow";
import PesquisaIndexAtoAdministrativo from "../views/pesquisa_index_ato_administrativo/PesquisaIndexAtoAdministrativo";
import ProjetosGovernador from "../views/pesquisa_projeto_governador/ProjetosGovernador";
import PorTipoVetoMensagemVeto from "../views/pesquisa_mensagem_veto/PorTipoVetoMensagemVeto";
import PorAnoMensagemVeto from "../views/pesquisa_mensagem_veto/PorAnoMensagemVeto";
import PorTipoLegislacaoMensagemVeto from "../views/pesquisa_mensagem_veto/PorTipoLegislacaoMensagemVeto";
import PorAnoProjetoGovernador from "../views/pesquisa_projeto_governador/PorAnoProjetoGovernador";
import PorAnoAtoAdministrativo from "../views/pesquisa_index_ato_administrativo/PorAnoAtoAdministrativo";
import PorTipoAtoAdministrativo from "../views/pesquisa_index_ato_administrativo/PorTipoAtoAdministrativo";
import EntendaLegislacao from "../views/EntendaLegislacao";
import NaoEncontrado from "../views/NaoEncontrado";
import CadastroAcompanhamentoPublicacoes from "../views/CadastroAcompanhamentoPublicacoes";
import DescadastroAcompanhamentoPublicacoes from "../views/DescadastroAcompanhamentoPublicacoes";
import AlteraAcompanhamentoPublicacoes from "../views/AlteraAcompanhamentoPublicacoes";

const router = new VueRouter({
  routes: [
    {
      path: "/pesquisa_ato_infralegal/:id/:slug?",
      component: PesquisaAtoInfralegalShow,
      name: "PesquisaAtoInfralegalShow",
    },
    {
      path: "/pesquisa_ato_infralegal",
      component: PesquisaAtoInfralegal,
      name: "PesquisaAtoInfralegal",
    },
    {
      path: "/pesquisa_ato_infralegal/por-ano",
      component: PorAnoAtoInfralegal,
      name: "PorAnoAtoInfralegal",
    },
    {
      path: "/pesquisa_ato_infralegal/por-tipo",
      component: PorTipoAtoInfralegal,
      name: "PorTipoAtoInfralegal",
    },
    {
      path: "/pesquisa_ato_infralegal/por-orgao",
      component: PorOrgaoAtoInfralegal,
      name: "PorOrgaoAtoInfralegal",
    },
    {
      path: "/pesquisa_legislacao",
      component: PesquisaLegislacao,
      name: "PesquisaLegislacao",
      alias: "/",
    },
    {
      path: "/pesquisa_legislacao/por-ano",
      component: PorAno,
      name: "PorAnoLegislacao",
    },
    {
      path: "/pesquisa_legislacao/por-tipo",
      component: PorTipo,
      name: "PorTipoLegislacao",
    },
    {
      path: "/pesquisa_legislacao/por-autor",
      component: PorAutor,
      name: "PorAutorLegislacao",
    },
    {
      path: "/pesquisa_legislacao/por-categoria",
      component: PorCategoria,
      name: "PorCategoriaLegislacao",
    },
    {
      path: "/pesquisa_legislacao/:id/:slug?",
      component: PesquisaLegislacaoShow,
      name: "PesquisaLegislacaoShow",
    },
    {
      path: "/constituicao-estadual",
      component: ConstituicaoEstadual,
      name: "ConstituicaoEstadual",
    },
    {
      path: "/pesquisa_mensagem_veto",
      component: PesquisaMensagemVeto,
      name: "PesquisaMensagemVeto",
    },
    {
      path: "/pesquisa_mensagem_veto/por-tipo-veto",
      component: PorTipoVetoMensagemVeto,
      name: "PorTipoVetoMensagemVeto",
    },
    {
      path: "/pesquisa_mensagem_veto/por-ano",
      component: PorAnoMensagemVeto,
      name: "PorAnoMensagemVeto",
    },
    {
      path: "/pesquisa_mensagem_veto/por-tipo-legislacao",
      component: PorTipoLegislacaoMensagemVeto,
      name: "PorTipoLegislacaoMensagemVeto",
    },
    {
      path: "/pesquisa_mensagem_veto/:id/:slug?",
      component: PesquisaMensagemVetoShow,
      name: "PesquisaMensagemVetoShow",
    },
    {
      path: "/pesquisa_ato_administrativo",
      component: PesquisaIndexAtoAdministrativo,
      name: "PesquisaIndexAtoAdministrativo",
    },
    {
      path: "/pesquisa_ato_administrativo/por-ano",
      component: PorAnoAtoAdministrativo,
      name: "PorAnoAtoAdministrativo",
    },
    {
      path: "/pesquisa_ato_administrativo/por-tipo",
      component: PorTipoAtoAdministrativo,
      name: "PorTipoAtoAdministrativo",
    },
    {
      path: "/projetos-governador",
      component: ProjetosGovernador,
      name: "ProjetosGovernador",
    },
    {
      path: "/projetos-governador/por-ano",
      component: PorAnoProjetoGovernador,
      name: "PorAnoProjetoGovernador",
    },
    {
      path: "/entenda_legislacao",
      component: EntendaLegislacao,
      name: "EntendaLegislacao",
      alias: "/entenda-legislacao",
    },
    {
      path: "/cadastro-acompanhamento-publicacoes",
      component: CadastroAcompanhamentoPublicacoes,
      name: "CadastroAcompanhamentoPublicacoes",
    },
    {
      path: "/descadastro-acompanhamento-publicacoes",
      component: DescadastroAcompanhamentoPublicacoes,
      name: "DescadastroAcompanhamentoPublicacoes",
    },
    {
      path: "/altera-acompanhamento-publicacoes",
      component: AlteraAcompanhamentoPublicacoes,
      name: "AlteraAcompanhamentoPublicacoes",
    },    {
      name: "NaoEncontrado",
      path: "/nao-encontrado",
      component: NaoEncontrado,
    },
    {
      path: "*",
      redirect: (to) => {
        return { name: "NaoEncontrado", query: { url: to.path } };
      },
    },
  ],
  mode: "history",
  scrollBehavior(to, from) {
    if (
      "PesquisaLegislacao" === to.name &&
      [
        "PorAnoLegislacao",
        "PorTipoLegislacao",
        "PorAutorLegislacao",
        "PorCategoriaLegislacao",
      ].includes(from.name)
    ) {
      return { selector: "#resultados" };
    }

    if (
      "PesquisaMensagemVeto" === to.name &&
      [
        "PorTipoVetoMensagemVeto",
        "PorAnoMensagemVeto",
        "PorTipoLegislacaoMensagemVeto",
      ].includes(from.name)
    ) {
      return { selector: "#resultados" };
    }

    if (
      "ProjetosGovernador" === to.name &&
      "PorAnoProjetoGovernador" === from.name
    ) {
      return { selector: "#resultados" };
    }

    if (
      "PesquisaIndexAtoAdministrativo" === to.name &&
      ["PorAnoAtoAdministrativo", "PorTipoAtoAdministrativo"].includes(
        from.name
      )
    ) {
      return { selector: "#resultados" };
    }

    return { x: 0, y: 0 };
  },
});

export default router;
