import { format, parse } from 'date-fns';

export const datepickerFormatter = {
    // Date to String
    stringify: (date) => {
        // console.log('stringify', date)
        return date ? format(date, 'dd/MM/yyyy') : ''
    },
    // String to Date
    parse: (value) => {
        value = value.replace(/[^0-9]/g, '')
        var parsedValue = value ? parse(value, 'ddMMyyyy', new Date()) : null
        if (parsedValue) {
            return parsedValue;
        }
        return null;
    }
}
