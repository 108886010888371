<template>
  <div>
    <div v-if="legislacao.autor">
      <p>
        <strong>Autor:</strong>
        {{legislacao.autor}}
      </p>
    </div>
    <div v-if="legislacao.numero_processo_projeto_lei">
      <p>
        <strong>Número Projeto Lei Alego:</strong>
        {{legislacao.numero_processo_projeto_lei}}
      </p>
    </div>
    <template v-if="legislacao.projeto_legislacao">
      <div v-if="legislacao.projeto_legislacao.numero_autografo_lei">
        <p>
          <strong>Autógrafo Lei:</strong>
          {{numeroAutografoLeiFormatado}}
        </p>
      </div>
      <div v-if="legislacao.projeto_legislacao.processo_sei_autografo">
        <p>
          <strong>Processo SEI Autógrafo:</strong>
          {{ legislacao.projeto_legislacao.processo_sei_autografo }}
        </p>
      </div>
      <div v-if="legislacao.projeto_legislacao.numero_oficio_msg">
        <p>
          <strong>Ofício Mensagem:</strong>
          {{ legislacao.projeto_legislacao.numero_oficio_msg }}
        </p>
      </div>
      <div v-if="legislacao.projeto_legislacao.processo_sei_oficio_msg">
        <p>
          <strong>Processo SEI Ofício Mensagem:</strong>
          {{ legislacao.projeto_legislacao.processo_sei_oficio_msg }}
        </p>
      </div>
      <div v-if="legislacao.projeto_legislacao.numero_oficio_veto">
        <p>
          <strong>Dados do Veto:</strong>
          {{ dadosDoVeto }}
        </p>
      </div>
      <div v-if="legislacao.projeto_legislacao.processo_sei_veto">
        <p>
          <strong>Processo SEI Veto:</strong>
          {{ legislacao.projeto_legislacao.processo_sei_veto }}
        </p>
      </div>
      <div v-if="legislacao.projeto_legislacao.numero_oficio_derrubada_veto">
        <p>
          <strong>Ofício de Derrubada Veto:</strong>
          {{ oficioDerrubadaVeto }}
        </p>
      </div>
    </template>
    <div v-if="legislacao.categorias_legislacoes && legislacao.categorias_legislacoes.length > 0">
      <p>
        <strong>Categoria(s):</strong>
        {{categorias}}
      </p>
    </div>
    <div v-if="legislacao.orgaos && legislacao.orgaos.length > 0">
      <p>
        <strong>Órgão(s):</strong>
        {{orgaos}}
      </p>
    </div>
    <div
      v-if="legislacao.modificada_por_legislacoes && legislacao.modificada_por_legislacoes.length > 0"
    >
      <span>
        <strong>Atualizado(a) por:</strong>
      </span>
      <ul>
        <li
          v-for="(leiRelacionada, index) in legislacao.modificada_por_legislacoes"
          :key="`${leiRelacionada.id}${leiRelacionada.numero}${index}`"
        >
          <router-link
            :to="{ name: 'PesquisaLegislacaoShow', params: { id: leiRelacionada.id} }"
            target="_blank"
          >{{detalhesLei(leiRelacionada)}}</router-link>
        </li>
      </ul>
    </div>
    <div v-if="legislacao.legislacoes_que_altera && legislacao.legislacoes_que_altera.length > 0">
      <span>
        <strong>Modifica Leis:</strong>
      </span>
      <ul>
        <li
          v-for="(leiRelacionada, index) in legislacao.legislacoes_que_altera"
          :key="`${leiRelacionada.id}${leiRelacionada.numero}${index}`"
        >
          <router-link
            :to="{ name: 'PesquisaLegislacaoShow', params: { id: leiRelacionada.id} }"
            target="_blank"
          >{{detalhesLei(leiRelacionada)}}</router-link>
        </li>
      </ul>
    </div>
    <div v-if="legislacao.mensagens_veto && legislacao.mensagens_veto.length > 0">
      <span>
        <strong>Mensagens de Veto:</strong>
      </span>
      <ul>
        <li
          v-for="(mensagem, index) in legislacao.mensagens_veto"
          :key="`${mensagem.id}${mensagem.numero}${index}`"
        >
          <router-link
            :to="{
                name: 'PesquisaMensagemVetoShow',
                params: { id: mensagem.id },
              }"
            target="_blank"
          >{{descricaoMensagemVeto(mensagem)}}</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";

import capitalize from "../../helpers/capitalize";

export default {
  name: "DetalhesLegislacao",
  props: {
    legislacao: Object
  },
  methods: {
    detalhesLei: function(leiRelacionada) {
      if (leiRelacionada) {
        let dados = "";
        if (leiRelacionada.tipo_legislacao) {
          dados += `${leiRelacionada.tipo_legislacao} `;
        }
        if (leiRelacionada.numero) {
          dados += `Nº ${leiRelacionada.numero}`;
        }
        if (leiRelacionada.ano) {
          dados += `/${leiRelacionada.ano}`;
        }
        return dados;
      }
      return "";
    },
    descricaoMensagemVeto: function(mensagemVeto) {
      if (mensagemVeto) {
        let dados = "Ofício ";

        dados += `nº ${mensagemVeto.numero}`;
        if (mensagemVeto.ano) {
          dados += ` / ${mensagemVeto.ano}`;
        }
        if (mensagemVeto.tipo_veto) {
          dados += ` (${capitalize(mensagemVeto.tipo_veto)}) `;
        }
        return dados;
      }
      return "";
    }
  },
  computed: {
    numeroAutografoLeiFormatado: function() {
      let numero = `Nº ${this.legislacao.projeto_legislacao.numero_autografo_lei}`;
      if (this.legislacao.projeto_legislacao.data_autografo_lei) {
        const data = parseISO(
          this.legislacao.projeto_legislacao.data_autografo_lei
        );
        numero += ` - ${format(data, "dd/MM/yyyy")}`;
      }
      return numero;
    },
    dadosDoVeto: function() {
      let dados = `Nº ${this.legislacao.projeto_legislacao.numero_oficio_veto}`;

      if (this.legislacao.projeto_legislacao.data_oficio_veto) {
        const data = parseISO(
          this.legislacao.projeto_legislacao.data_oficio_veto
        );
        dados += ` - ${format(data, "dd/MM/yyyy")}`;
      }

      if (this.legislacao.projeto_legislacao.tipo_veto) {
        dados += ` - ${capitalize(
          this.legislacao.projeto_legislacao.tipo_veto
        )}`;
      }

      return dados;
    },
    oficioDerrubadaVeto: function() {
      let oficio = `Nº ${this.legislacao.projeto_legislacao.numero_oficio_derrubada_veto}`;

      if (this.legislacao.projeto_legislacao.data_oficio_derrubada_veto) {
        const data = parseISO(
          this.legislacao.projeto_legislacao.data_oficio_derrubada_veto
        );
        oficio += ` - ${format(data, "dd-MM-yyyy")}`;
      }

      return oficio;
    },
    categorias: function() {
      return this.legislacao.categorias_legislacoes
        .map(cat => cat.nome)
        .join(", ");
    },
    orgaos: function() {
      return this.legislacao.orgaos.map(orgao => orgao.nome).join(", ");
    }
  }
};
</script>

<style lang="scss" scoped>
strong {
  font-family: "Poppins-Medium";
  color: #0a4a22;
  font-size: .9rem;
}
.alto-contraste {
  strong {
    color: #ffa900;
  }
}
ul{
  a{
    color: #375a3a;
  }
}
</style>