<template>
  <div>
    <div class="ambiente-banner ambiente-desenvolvimento" v-if="ambienteAtual == 'development'">
      <p>Ambiente de Desenvolvimento</p>
    </div>
    <div class="ambiente-banner ambiente-homologacao" v-if="ambienteAtual == 'homologacao'">
      <p>Ambiente de Homologação</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ambienteAtual: {
      type: String,
      required: true
    }
  }
}
</script>

<style>
  .ambiente-banner {
    color: white;
    padding: 3px 0;
    position: relative;
    width: 100%;
    z-index: 9999;
  }
  .ambiente-banner p {
    margin: 0;
    margin-right: 5%;
    text-align: right;
    text-transform: uppercase;
    font-weight: bold;
    font-size: .715em;
  }
  .ambiente-desenvolvimento {
    background-color: orange;
  }
  .ambiente-homologacao {
    background-color: red;
  }
</style>