<template>

    <span class="item-autocomplete">{{ item.nome }}</span>

</template>

<script>
export default {
  props: {
    item: { required: true },
    searchText: { required: true }
  }
}
</script>

<style scoped>
  span.item-autocomplete {
    font-size: 11px;
  }
</style>