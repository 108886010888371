<template>
  <div class="tipo">
    <div class="centro">
      <h1>Consulta por Tipo de Legislação</h1>
      <BuscaSimplesCards />
      <Loading v-if="carregando" />
      <ErroResultados v-if="!carregando && erro" />
      <PainelBuscaSimplificada
        :dados="tiposFormatados"
        :generateUrl="generateUrl"
        titulo="Tipo de Legislação"
        v-if="!carregando && !erro"
      />
    </div>
  </div>
</template>

<script>
import LegislacaoApi from "../../services/LegislacaoApi";
import Loading from "../../components/Loading";
import ErroResultados from "../../components/ErroResultados";
import PainelBuscaSimplificada from "../../components/PainelBuscaSimplificada";
import BuscaSimplesCards from "../../components/pesquisa_legislacao/BuscaSimplesCards";

import metaDados from "../../helpers/metaDados";

export default {
  name: "PorTipo",
  metaInfo() {
    return {
      title: "Pesquisa de Legislação por Tipo",
      titleTemplate: "%s - Casa Civil do Estado de Goiás",
      meta: [
        {
          vmid: "description",
          name: "description",
          property: "description",
          content:
            "Página de acesso e pesquisa a toda legislação e atos normativos do Governo do Estado de Goiás.",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content:
            "Página de acesso e pesquisa a toda legislação e atos normativos do Governo do Estado de Goiás.",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content:
            "Pesquisa a Legislação por Tipo de Legislação - Casa Civil do Estado de Goiás",
        },
        {
          vmid: "og:type",
          property: "og:type",
          content: "article",
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          property: "twitter:description",
          content:
            "Página de acesso e pesquisa a toda legislação e atos normativos do Governo do Estado de Goiás.",
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          property: "twitter:title",
          content:
            "Pesquisa a Legislação por Tipo de Legislação - Casa Civil do Estado de Goiás",
        },
        ...metaDados(this.$route),
      ],
    };
  },
  data() {
    return {
      carregando: true,
      erro: false,
      tipos: [],
    };
  },
  computed: {                                         // Parte para formatar os números com separador milhar.
    tiposFormatados() {
      return this.tipos.map((tipo) => {
        return {
          ...tipo,
          quantidade: this.formatNumber(tipo.quantidade), 
        };
      });
    },
  },
  components: {
    PainelBuscaSimplificada,
    Loading,
    ErroResultados,
    BuscaSimplesCards,
  },
  created() {
    this.legislacaoApi = new LegislacaoApi();

    this.carregarTipos();
  },
  methods: {
    carregarTipos: async function() {
      try {
        if (this.legislacaoApi.cancelTokens.getTipos) {
          this.legislacaoApi.cancelTokens.getTipos.cancel();
        }

        this.carregando = true;
        this.erro = false;
        this.tipos = await this.legislacaoApi.getTipos();
        this.carregando = false;
      } catch (err) {
        if (this.legislacaoApi.isCancel(err)) {
          return;
        }
        console.log(err);
        this.erro = true;
        this.carregando = false;
      }
    },
    generateUrl: function(ano, tipo) {
      return {
        name: "PesquisaLegislacao",
        query: { tipo_legislacao: tipo.id, ano, busca_avancada: 1 },
      };
    },
    formatNumber(number) {                          // Método para formatar os números com separador milhar.
      return number.toLocaleString('pt-BR');
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-family: "Poppins-Bold";
  color: #2c3f2b;
  padding-left: 0.5rem;
  border-left: #ffa900 solid 0.15rem;
  margin: 2rem 0rem 1.5rem 0.5rem;
  font-size: 1.5rem;
}
.tipo {
  min-height: calc(100vh - 10rem);
  margin-bottom: 5rem;
}
</style>
