<template>
  <div>
    <div class="carregando-show" v-if="carregando">
      <Loading size="medium"></Loading>
    </div>
    <div class="erro-carregando" v-if="!carregando && erro">
      <ErroResultados></ErroResultados>
    </div>
    <template v-if="!carregando && !erro">
      <ExibirMensagemVeto :mensagemVeto="mensagemVeto" />
      <DownloadPdf 
        :url="urlPdf" 
        :evento="'visualizacao_pdf_mensagemveto'" 
        :categoria="'download_pdf_mensagemveto'" 
        :label="labelMensagemVeto" 
      />
    </template>
  </div>
</template>

<script>
import slugify from "slugify";

import MensagemVetoApi from "../../services/MensagemVetoApi";
import Loading from "../../components/Loading";
import ErroResultados from "../../components/ErroResultados";
import DownloadPdf from "../../components/DownloadPdf";
import ExibirMensagemVeto from "../../components/pesquisa_mensagem_veto/ExibirMensagemVeto";
import metaDados from "../../helpers/metaDados";

const truncate = require("lodash.truncate");

export default {
  name: "PesquisaMensagemVetoShow",
  metaInfo() {
    if (!this.mensagemVeto) {
      return {};
    }

    let title = `Ofício Nº ${this.mensagemVeto.numero}/${this.mensagemVeto.ano}`;
    const description = truncate(this.mensagemVeto.ementa, { length: 160 });

    return {
      title: `Mensagem de Veto - ${title}`,
      titleTemplate: "%s - Casa Civil do Estado de Goiás",
      meta: [
        {
          vmid: "description",
          name: "description",
          property: "description",
          content: description,
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: description,
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: `${title} - Casa Civil do Estado de Goiás`,
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          property: "twitter:description",
          content: description,
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          property: "twitter:title",
          content: `${title} - Casa Civil do Estado de Goiás`,
        },
        ...metaDados(this.$route),
      ],
    };
  },
  data() {
    return {
      carregando: true,
      erro: false,
      mensagemVeto: null,
      urlPdf: null,
      labelMensagemVeto: '',
    };
  },
  components: {
    Loading,
    ErroResultados,
    DownloadPdf,
    ExibirMensagemVeto,
  },
  created() {
    this.mensagemVetoApi = new MensagemVetoApi();

    this.carregarMensagemVeto();
  },
  watch: {
    $route: function(to, from) {
      if (to.params.id != from.params.id) {
        this.carregarMensagemVeto();
      }
    },
  },
  methods: {
    carregarMensagemVeto: async function() {
      try {
        this.carregando = true;
        this.erro = false;

        if (this.mensagemVetoApi.cancelTokens.getMensagemVeto) {
          this.mensagemVetoApi.cancelTokens.getMensagemVeto.cancel();
        }

        this.mensagemVeto = await this.mensagemVetoApi.getMensagemVeto(
          this.$route.params.id
        );
        this.urlPdf = `${process.env.VUE_APP_BASE_API_URL}/api/v1/pesquisa/mensagens_veto/${this.mensagemVeto.id}/pdf`;

        if (this.mensagemVeto) {
          this.labelMensagemVeto = `Ofício Nº ${this.mensagemVeto.numero}/${this.mensagemVeto.ano}`;
        }

        const slug = this.createSlug();
        if (this.$route.params && this.$route.params.slug != slug) {
          this.$router.replace({
            name: "PesquisaMensagemVetoShow",
            params: { ...this.$route.params, slug },
            query: this.$route.query,
          });
        }
        this.carregando = false;
      } catch (err) {
        if (this.mensagemVetoApi.isCancel(err)) {
          return;
        }

        console.log(err);
        this.erro = true;
        this.carregando = false;
        this.mensagemVeto = null;
      }
    },
    createSlug: function() {
      if (this.mensagemVeto) {
        const slug = `oficio ${this.mensagemVeto.numero.replace(/\./g, "")} ${
          this.mensagemVeto.ano
        }`;
        return slugify(slug, {
          lower: true,
          strict: true,
          locale: "pt",
        });
      }
      return undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.carregando-show {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.erro-carregando {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
</style>
