var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resultados"},[_c('table',[_vm._m(0),_c('tbody',[_vm._l((_vm.atosInfralegais),function(atoInfralegal,index){return [_c('tr',{key:atoInfralegal.id},[_c('td',{staticClass:"topo-card"},[_c('router-link',{attrs:{"to":{
                name: 'PesquisaAtoInfralegalShow',
                params: { id: atoInfralegal.id },
              },"target":"_blank"}},[_c('span',[_vm._v(_vm._s(_vm.tipoAtoInfralegal(atoInfralegal)))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.nomeAtoInfralegal(atoInfralegal)))])])],1),_c('td',{attrs:{"data-label":"Data do Ato Infralegal: "}},[_vm._v(" "+_vm._s(atoInfralegal.data)+" ")]),_c('td',{attrs:{"data-label":"Situação"}},[_vm._v(" "+_vm._s(atoInfralegal.situacao_ato_infralegal ? atoInfralegal.situacao_ato_infralegal : "")+" ")]),_c('td',{attrs:{"data-label":"Texto:"}},[_c('router-link',{attrs:{"to":{
                name: 'PesquisaAtoInfralegalShow',
                params: { id: atoInfralegal.id },
              },"target":"_blank"}},[_c('img',{staticClass:"icon-txt t-preto",attrs:{"src":require("../../assets/images/icon_text.svg"),"alt":"Acessar Texto","title":"Acessar texto"}}),_c('img',{staticClass:"icon-txt t-branco",attrs:{"src":require("../../assets/images/icon_text_bco.svg"),"alt":"Acessar Texto","title":"Acessar texto"}})]),_c('a',{attrs:{"href":_vm.urlPdf(atoInfralegal),"target":"_blank"},on:{"click":function($event){return _vm.registra_GA4(atoInfralegal)}}},[_c('img',{staticClass:"icon-pdf",attrs:{"src":require("../../assets/images/icon_pdf.svg"),"alt":"Abrir texto em pdf","title":"Abrir texto em PDF"}})])],1),_c('td',{attrs:{"data-label":"Órgão Relacionado:"}},[_vm._v(" "+_vm._s(atoInfralegal.orgao_relacionado_sigla)+" ")]),_c('td',{staticClass:"botao"},[(_vm.exibirBotao(index))?_c('button',{on:{"click":function($event){return _vm.exibir(index)}}},[_vm._v(" "+_vm._s(_vm.exibirMais[index] ? "ocultar" : "ver mais")+" ")]):_vm._e()])]),_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.exibirMais[index]),expression:"exibirMais[index]"}],key:`${atoInfralegal.id}mais`,staticClass:"exibir-mais"},[_c('td',{attrs:{"colspan":"7"}},[_c('DetalhesAtoInfralegal',{attrs:{"atoInfralegal":atoInfralegal}})],1)])]}),(!_vm.atosInfralegais || _vm.atosInfralegais.length == 0)?_c('tr',{staticClass:"sem-resultados"},[_c('td',{attrs:{"colspan":"7"}},[_vm._v("Nenhum resultado encontrado")])]):_vm._e()],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Ato Infralegal")]),_c('th',[_vm._v("Data do Ato Infralegal")]),_c('th',[_vm._v("Situação")]),_c('th',[_vm._v("Texto")]),_c('th',[_vm._v("Órgão de Origem")]),_c('th')])])
}]

export { render, staticRenderFns }