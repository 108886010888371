var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resultados"},[_c('table',[_vm._m(0),_c('tbody',[_vm._l((_vm.legislacoes),function(legislacao,index){return [_c('tr',{key:legislacao.id},[_c('td',{staticClass:"topo-card"},[_c('router-link',{attrs:{"to":{
                name: 'PesquisaLegislacaoShow',
                params: { id: legislacao.id },
              },"target":"_blank"}},[_c('span',[_vm._v(_vm._s(_vm.tipoLegislacao(legislacao)))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.nomeLegislacao(legislacao)))])])],1),_c('td',{staticClass:"publi-do",attrs:{"data-label":"Publicado no D.O.:"}},_vm._l((legislacao.diarios),function(diario){return _c('div',{key:diario.numero},[(diario.link_download)?_c('a',{attrs:{"href":diario.link_download,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.descricaoDiario(diario))+" "),(diario.suplemento == 'Sim')?_c('span',[_vm._v("Suplemento")]):_vm._e()]):_c('p',[_vm._v(" "+_vm._s(_vm.descricaoDiario(diario))+" "),(diario.suplemento == 'Sim')?_c('span',[_vm._v("Suplemento")]):_vm._e()])])}),0),_c('td',{attrs:{"data-label":"Data da Legislação: "}},[_vm._v(" "+_vm._s(legislacao.data_legislacao)+" ")]),_c('td',{attrs:{"data-label":"Situação"}},[_vm._v(" "+_vm._s(legislacao.estado_legislacao ? legislacao.estado_legislacao.nome : "")+" ")]),_c('td',{attrs:{"data-label":"Texto:"}},[_c('router-link',{attrs:{"to":{
                name: 'PesquisaLegislacaoShow',
                params: { id: legislacao.id },
              },"target":"_blank"}},[_c('img',{staticClass:"icon-txt t-preto",attrs:{"src":require("../../assets/images/icon_text.svg"),"alt":"Acessar Texto","title":"Acessar texto"}}),_c('img',{staticClass:"icon-txt t-branco",attrs:{"src":require("../../assets/images/icon_text_bco.svg"),"alt":"Acessar Texto","title":"Acessar texto"}})]),_c('a',{attrs:{"href":_vm.urlPdf(legislacao),"target":"_blank"},on:{"click":function($event){return _vm.registra_GA4(legislacao)}}},[_c('img',{staticClass:"icon-pdf",attrs:{"src":require("../../assets/images/icon_pdf.svg"),"alt":"Abrir texto em pdf","title":"Abrir texto em PDF"}})])],1),_c('td',{attrs:{"data-label":"Ementa:"}},[_vm._v(" "+_vm._s(_vm.truncateString(legislacao.ementa, 150))+" ")]),_c('td',{staticClass:"botao"},[(_vm.exibirBotao(index))?_c('button',{on:{"click":function($event){return _vm.exibir(index)}}},[_vm._v(" "+_vm._s(_vm.exibirMais[index] ? "ocultar" : "ver mais")+" ")]):_vm._e()])]),_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.exibirMais[index]),expression:"exibirMais[index]"}],key:`${legislacao.id}mais`,staticClass:"exibir-mais"},[_c('td',{attrs:{"colspan":"7"}},[_c('DetalhesLegislacao',{attrs:{"legislacao":legislacao}})],1)])]}),(!_vm.legislacoes || _vm.legislacoes.length == 0)?_c('tr',{staticClass:"sem-resultados"},[_c('td',{attrs:{"colspan":"7"}},[_vm._v("Nenhum resultado encontrado")])]):_vm._e()],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Legislação")]),_c('th',[_vm._v("Publicado no D.O")]),_c('th',[_vm._v("Data da Legislação")]),_c('th',[_vm._v("Situação")]),_c('th',[_vm._v("Texto")]),_c('th',[_vm._v("Ementa")]),_c('th')])])
}]

export { render, staticRenderFns }