<template>
  <div class="centro">
    <h1>Alteração de frequencia de emails</h1>

    <Loading v-if="carregando" />
    <ErroResultados
      texto="Houve um erro ao carregar os dados"
      v-if="!carregando && erroCarregando"
    />

    <template v-if="!carregando && !erroCarregando">
      <form @submit="cadastrar">
        <!-- aparece so quando cadastra um email com sucesso -->
        <div class="cad-sucesso" v-if="exibirMsgSucesso">
          <p><span>&#10004;</span> Frequencia alterada com sucesso!</p>
        </div>

        <!-- aparece so quando dar erro ao salvar email (ex: salvar email repetido) -->
        <div class="cad-erro" v-if="erroSalvando">
          <p><span> &#10008;</span> {{erroSalvando}}</p>
        </div>
        <div class="form">          
          <p>
            Frequência de recebimento de atualizações para o email <strong>{{ email }}</strong>
          </p>        
          <div class="linha1">
            <div class="item">
              <label>Frequência:<span>*</span></label>
              <select v-model="frequencia" required>
                <option :value="0">Diariamente</option>
                <option :value="1">Semanalmente</option>
                <option :value="2">Quinzenalmente</option>
              </select>
            </div>
          </div>
          <div class="botao">
            <button type="submit" :disabled="salvando">{{salvando ? 'Salvando' : 'Alterar'}}</button>
          </div>
        </div>
      </form>
    </template>
  </div>
</template>

<script>
import EmailAcompanhamentoPublicacaoApi from "../services/EmailAcompanhamentoPublicacaoApi";
import Loading from "../components/Loading";
import ErroResultados from "../components/ErroResultados";

import metaDados from "../helpers/metaDados";

const get = require("lodash.get");

export default {
  name: "AlteraAcompanhamentoPublicacoes",
  metaInfo() {
    return {
      title: "Alteração em acompanhamento de publicações",
      titleTemplate: "%s - Casa Civil do Estado de Goiás",
      meta: [
        {
          vmid: "description",
          name: "description",
          property: "description",
          content: "Alteração em acompanhamento de publicações"
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: "Alteração em acompanhamento de publicações"
        },
        {
          vmid: "og:title",
          property: "og:title",
          content:
            "Alteração em acompanhamento de publicações - Casa Civil do Estado de Goiás"
        },
        {
          vmid: "og:type",
          property: "og:type",
          content: "article"
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          property: "twitter:description",
          content:
            "Alteração em acompanhamento de publicações - Casa Civil do Estado de Goiás"
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          property: "twitter:title",
          content:
            "Alteração em acompanhamento de publicações - Casa Civil do Estado de Goiás"
        },
        ...metaDados(this.$route)
      ]
    };
  },
  data() {
    return {
      email: this.$route.query.email,
      frequencia: this.$route.query.frequencia,
      erroCarregando: false,
      carregando: false,
      erroSalvando: null,
      salvando: false,
      exibirMsgSucesso: false
    };
  },
  components: {
    Loading,
    ErroResultados
  },
  created() {
    this.emailAcompanhamentoPublicacaoApi = new EmailAcompanhamentoPublicacaoApi();
  },
  methods: {
    async cadastrar(e) {
      e.preventDefault();

      try {
        this.exibirMsgSucesso = false;
        this.salvando = true;
        this.erroSalvando = null;

        await this.emailAcompanhamentoPublicacaoApi.alterarFrequencia({
          email: this.email,
          frequencia: this.frequencia,
        });
        this.exibirMsgSucesso = true;
        this.salvando = false;

      } catch (err) {
        console.log(err);
        const mensagem = get(err, "response.data.mensagem");
        this.erroSalvando =
          mensagem ||
          "Houve um erro ao efetuar a alteração. Por favor tente novamente";
        this.salvando = false;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-family: "Poppins-Bold";
  color: #2c3f2b;
  padding-left: 0.5rem;
  border-left: #ffa900 solid 0.15rem;
  margin: 2.5rem 0rem 1.5rem 1rem;
  font-size: 1.5rem;
  line-height: 2rem;
}
.cad-sucesso{
  margin-left: 1rem;
  background-color: rgb(4, 104, 4);
  padding: .5rem 0rem;
  margin: 1rem auto;
  text-align: center;
  span{
    color: white;
    font-size: 1.2rem;
  }
  p{
    color: white;
    font-size: .9rem;
    margin: 0rem;
  }
}
.cad-erro{
  margin-left: 1rem;
  background-color: rgb(192, 0, 0);
  padding: .5rem 0rem;
  margin: 1rem auto;
  text-align: center;
  p{
    color: white;
    font-size: .9rem;
    margin: 0rem;
    span{
      font-size: 1.2rem;
    }
  }
}
.form{
  padding: 0.5rem 1rem 5rem;
  min-height: calc(100vh - 17rem);
  p{
    margin: 0rem 0rem 1rem;
  }
  .item{
    display: grid;
    margin-bottom: .5rem;
    label{
      font-size: .9rem;
    }
    input{
      width: calc(100% - 1rem);
      height: 2rem;
      border-radius: 0.2rem;
      border: #2c3f2b solid .05rem;
      padding-left: 0.5rem;
      font-family: "Poppins";
      font-size: 1rem;
      letter-spacing: 0.03rem;
    }
    select{
      width: calc(100% - .25rem);
      height: 2rem;
      border-radius: 0.2rem;
      border: #2c3f2b solid .05rem;
      padding-left: 0.5rem;
      font-family: "Poppins";
      font-size: 1rem;
      letter-spacing: 0.03rem;
      background-color: white;
    }
    span{
      color: red;
    }
  }
}
.check-ciente{
  margin-top: 2rem;
  span{
    font-size: .9rem;
    text-indent: 3rem;
  }
  .checkbox {
      --background: #fff;
      --border: #D1D6EE;
      --border-hover: #BBC1E1;
      --border-active: #2c3f2b;
      --tick: #fff;
      position: relative;
      input,
      svg {
          width: 21px;
          height: 21px;
          display: inline-block;
      }
      input {
          -webkit-appearance: none;
          -moz-appearance: none;
          position: relative;
          outline: none;
          background: var(--background);
          border: none;
          margin: 0 1rem -.3rem 0rem;
          padding: 0;
          cursor: pointer;
          border-radius: 4px;
          transition: box-shadow .3s;
          box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
          &:hover {
              --s: 2px;
              --b: var(--border-hover);
          }
          &:checked {
              --b: var(--border-active);
          }
      }
      svg {
          pointer-events: none;
          fill: none;
          stroke-width: 2px;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke: var(--stroke, var(--border-active));
          position: absolute;
          top: 0;
          left: 0;
          width: 21px;
          height: 21px;
          transform: scale(var(--scale, 1)) translateZ(0);
      }
      &.path {
          input {
              &:checked {
                  --s: 2px;
                  transition-delay: .4s;
                  & + svg {
                      --a: 16.1 86.12;
                      --o: 102.22;
                  }
              }
          }
          svg {
              stroke-dasharray: var(--a, 86.12);
              stroke-dashoffset: var(--o, 86.12);
              transition: stroke-dasharray .6s, stroke-dashoffset .6s;
          }
      }
      &.bounce {
          --stroke: var(--tick);
          input {
              &:checked {
                  --s: 11px;
                  & + svg {
                      animation: bounce .4s linear forwards .2s;
                  }
              }
          }
          svg {
              --scale: 0;
          }
      }
  }

  @keyframes bounce {
      50% {
          transform: scale(1.2);
      }
      75% {
          transform: scale(.9);
      }
      100% {
          transform: scale(1);
      }
  }

  html {
      box-sizing: border-box;
      -webkit-font-smoothing: antialiased;
  }
}
.botao{
  margin-top: 2rem;
  button{
      background-color: #2c3f2b;
      color: #ffa900;
      border: solid 0.15rem #2c3f2b;
      font-size: .9rem;
      letter-spacing: .05rem;
      padding: .5rem 2rem;
      cursor: pointer;
      border-radius: .5rem;
  }
}
@media screen and (min-width: 64rem){
  .form{
    min-height: calc(100vh - 40rem);
  }
  .linha1{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1%;
  }
}
.alto-contraste{
  p{
    color: white;
  }
  .form{
    label{
      color: white;
    }
  }.botao{
  button{
      background-color: #ffa900;
      color: #2c3f2b;
      border: solid 0.15rem #ffa900;
      font-size: .9rem;
      letter-spacing: .05rem;
      padding: .5rem 2rem;
      cursor: pointer;
      border-radius: .5rem;
  }
}
}
</style>