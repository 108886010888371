import axios from "axios";
import axiosInstance from "./axiosInstance";


class EmailAcompanhamentoPublicacao {
    cancelTokens = {
        cadastrarEmail: null,
        removerEmail: null,
        alterarFrequencia: null,
    };

    isCancel(exceptionThrown) {
        return axios.isCancel(exceptionThrown);
    }

    async cadastrarEmail({
        nome,
        frequencia,
        paisId,
        estadoId,
        email
    }) {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        this.cancelTokens.cadastrarEmail = source;

        const { data } = await axiosInstance.post(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/emails_acompanhamento_legislacoes`,
            {
                nome,
                frequencia: frequencia,
                pais_id: paisId,
                estado_id: estadoId,
                email,
            },
            {
                cancelToken: source.token,
            }
        );

        this.cancelTokens.cadastrarEmail = null;

        return data;
    }

    async alterarFrequencia({
        frequencia,
        email
    }) {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        this.cancelTokens.alterarFrequencia = source;

        const { data } = await axiosInstance.put(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/emails_acompanhamento_legislacoes/alterar_frequencia?email=${email}`,
            {
                frequencia: frequencia,
                email,
            },
            {
                cancelToken: source.token,
            }
        );

        this.cancelTokens.alterarFrequencia = null;

        return data;
    }

    async removerEmail({ email }) {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        this.cancelTokens.removerEmail = source;

        const { data } = await axiosInstance.delete(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/emails_acompanhamento_legislacoes/remover?email=${email}`,
            {
                cancelToken: source.token,
            }
        );

        this.cancelTokens.removerEmail = null;

        return data;
    }
}

export default EmailAcompanhamentoPublicacao;
