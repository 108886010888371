<template>
  <div>
    <input
      type="checkbox"
      id="exibir-ficha-tecnica"
      class="exibir-ficha-tecnica"
      v-model="fichaTecnica"
    />
    <label
      for="exibir-ficha-tecnica"
      class="exibir-ficha-tecnica-label"
      id="exibir-fich-tecnica-label"
    >
      &nbsp;
      <span>Ficha Técnica</span>
    </label>

    <div id="ficha-tecnica" class="ficha-tecnica">
      <table>
        <tbody>
          <tr v-if="legislacao.autores.length > 0">
            <td>
              <template v-if="legislacao.autores.length == 1">Autor</template>
              <template v-else>Autores</template>
            </td>
            <td>
              <template>
                <span v-for="autor in legislacao.autores" :key="autor.id">{{autor.nome}}<br /></span>
              </template>
            </td>
          </tr>
          <tr v-if="leisrelacionadasOrdenadas.length > 0">
            <td>
              <template v-if="leisrelacionadasOrdenadas.length == 1">Legislação Relacionada</template>
              <template v-else>Legislações Relacionadas</template>
            </td>
            <td>
              <template>
                <span v-for="legislacao in leisrelacionadasOrdenadas" :key="legislacao.id">
                  {{ titleLegislacao(legislacao) }} ({{ legislacao.tipo_relacionamento }}) <br />
                </span>
              </template>
            </td>
          </tr>
          <tr v-if="legislacao.numero_processo_projeto_lei">
            <td>Nº do Projeto de Lei</td>
            <td>{{legislacao.numero_processo_projeto_lei}}</td>
          </tr>
          <tr v-if="legislacao.orgaos_relacionados.length > 0">
            <td>
              <template v-if="legislacao.orgaos_relacionados.length == 1">Órgão Relacionado</template>
              <template v-else>Órgãos Relacionados</template>
            </td>
            <td>
              <template>
                <span v-for="orgao in legislacao.orgaos_relacionados" :key="orgao.id">
                  {{orgao.nome}} <br />
                </span>
              </template>
            </td>
          </tr>
          <tr v-if="legislacao.mensagens_veto.length > 0">
            <td>
              <template v-if="legislacao.mensagens_veto.length == 1">Veto</template>
              <template v-else>Vetos</template>
            </td>
            <td>
              <template>
                <span v-for="veto in legislacao.mensagens_veto" :key="veto.id">
                  Ofício Nº {{veto.numero}} / {{veto.ano}}<br />
                </span>
              </template>
            </td>
          </tr>
          <tr v-if="legislacao.categorias.length > 0">
            <td>
              <template v-if="legislacao.categorias.length == 1">Categoria</template>
              <template v-else>Categorias</template>
            </td>
            <td>
              <template>
                <span v-for="categoria in legislacao.categorias" :key="categoria.id">
                  {{categoria.nome}} <br />
                </span>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
      <template v-if="legislacao.adis.length > 0">
      <table v-for="adi in legislacao.adis" :key="adi.id" class="adi">
        <caption>Ação Direta de Inconstitucionalidade Nº {{adi.numero}}</caption>
        <tbody>
          <tr v-if="adi.situacao">
            <td>Situação</td>
            <td>{{adi.situacao}}</td>
          </tr>
          <tr v-if="adi.liminar_deferida">
            <td>Liminar Deferida</td>
            <td>Sim</td>
          </tr>
          <tr>
            <td>Extrato da Decisão</td>
            <td v-html="formatarTexto(adi.resultado)"></td>
          </tr>
          <tr v-if="adi.link">
            <td>Link</td>
            <td><a :href="adi.link" target="_blank">{{adi.link}}</a></td>
          </tr>
          <tr>
            <td>Trânsito Julgado</td>
            <td v-if="adi.transito_julgado">Sim. Em {{formatarData(adi.data_transito_julgado)}}.</td>
            <td v-else>Não</td>
          </tr>
          <tr v-if="adi.historico">
            <td>Histórico</td>
            <td v-html="formatarTexto(adi.historico)"></td>
          </tr>
        </tbody>
      </table>
      </template>
    </div>
  </div>
</template>

<script>
import titleLegislacao from "../../helpers/legislacao/titleLegislacao";
import { parseISO, format } from "date-fns";

export default {
  name: "FichaTecnica",
  props: {
    legislacao: {
      type: Object,
      required: true,
    },
    leisrelacionadasOrdenadas: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fichaTecnica: false,
    };
  },
  watch: {
    fichaTecnica: function (novoValor) {
      const elemento = document.getElementById("ficha-tecnica");
      console.log(elemento);
      elemento.style.display = novoValor ? "block" : "none";
    },
  },
  methods: {
    titleLegislacao,
    formatarData: function (data) {
      return format(parseISO(data), "dd/MM/yyyy");
    },
    formatarTexto: function (texto) {
      return texto.replace(/\n/g,"<br>");
    }
  }
};
</script>

<style lang="scss" scoped>
  .exibir-ficha-tecnica {
    visibility: hidden;
  }

  .exibir-ficha-tecnica-label {
    position: relative;
    cursor: pointer;
    text-transform: uppercase;
    color: #49a054;
  }

  .exibir-ficha-tecnica-label::before,
  .exibir-ficha-tecnica-label::after {
    content: "";
    transition: 0.1s ease-in-out;
  }

  .exibir-ficha-tecnica-label::before {
    display: inline-block;
    width: 2em;
    height: 1em;
    background-color: silver;
    border-radius: 1em;
    margin-top: -0.3rem;
  }

  .exibir-ficha-tecnica-label::after {
    position: absolute;
    top: -0.2em;
    left: 0.05rem;
    background-color: white;
    border-radius: 100%;
    width: 0.9em;
    height: 0.9em;
  }

  .exibir-ficha-tecnica:checked + .exibir-ficha-tecnica-label::before {
    background-color: #49a054;
  }

  .exibir-ficha-tecnica:checked + .exibir-ficha-tecnica-label::after {
    left: 1.05rem;
  }

  .exibir-ficha-tecnica:checked ~ .revogado {
    visibility: hidden;
  }

  .adi{
    caption{
      text-align: left;
    }
  }

  #ficha-tecnica {
    display: none;
  }

  table {
    border-collapse: collapse;
    margin-top: 2rem;

    .texto-centralizado {
      text-align: center;
    }

    p {
      text-indent: 0rem;
      margin: 0rem;
      text-align: left;
      line-height: 100%;
      font-size: 90%;
    }

    table,
    th,
    td {
      border: solid 0.03rem black;
      font-size: 85%;
    }

    th[colspan] {
      padding: 0.7rem 0rem;
    }

    th,
    td {
      padding: 0.25rem;
    }

    td[colspan] {
      p {
        text-align: center;
      }
    }

    th {
      background-color: #efefef;
    }
  }
  
  @media print {
    #exibir-ficha-tecnica,
    #exibir-fich-tecnica-label {
      display: none;
    }
    .ficha-tecnica table {
      width: 100%;
    }
    .ficha-tecnica {
      page-break-before: always;
    }
  }
</style>
