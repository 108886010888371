<template>
  <div class="modal-descadastramento-email">
    <div class="modal-header">
      <h2>Descadastrar email</h2>
    </div>
    <div class="modal-body">
      <p>Deseja realmente descadastrar o email <strong>{{ email }}</strong>?</p>
    </div>
    <div class="modal-footer">
      <a class="descadastrar-confirma" @click="confirmar">Sim, descadastrar</a>
      <a class="descadastrar-cancela" @click="cancelar">Cancelar</a>
    </div>
  </div>
</template>
  
<script>
export default {
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  methods: {
    confirmar() {
      this.$emit('confirmar');
    },
    cancelar() {
      this.$emit('cancelar');
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-descadastramento-email {
  padding-left: 20px;

  .modal-header {
    h2 {
      font-size: 1.5rem;
      font-family: "Poppins-Bolder";
      color: #375a3a;
    }
  }

  .modal-footer {
    margin-top: 25px;

    a{
      margin-right: .6rem;
      padding: 0.1rem .7rem;
      border: solid .09rem #375a3a;
      text-decoration: none;
      color: #375a3a;
      border-radius: .5rem;
      font-size: .9rem;
      font-weight: 500;
      letter-spacing: .05rem;
      min-width: 2.5rem;
      text-align: center;
      font-family: 'Poppins-Regular';
    }

    a.descadastrar-confirma:hover{
      background-color: #375a3a;
      color: white;
    }

    a.descadastrar-cancela:hover{
      background-color: #b50c0c;
      border: solid .09rem #b50c0c;
      color: white;
    }
  }
}
</style>