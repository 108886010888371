<template>
  <div class="grupo-parametros">
    <div v-for="param in parametrosDeBusca" :key="param.key.toString()">
      <BotaoParametroBusca
        :label="param.label"
        :valor="param.valor"
        :onClick="(event) => onClick(event, param)"
      />
    </div>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";

import BotaoParametroBusca from "../BotaoParametroBusca";

export default {
  name: "ParametrosBusca",
  props: {
    funcaoBuscaAvancada: {
      type: Function,
      required: true
    },
    filtro: {
      type: Object,
      required: true
    }
  },
  components: {
    BotaoParametroBusca
  },
  methods: {
    onClick: function(event, parametro) {
      const keys = Array.isArray(parametro.key)
        ? parametro.key
        : [parametro.key];
      for (let key of keys) {
        this.filtro[key] = undefined;
      }

      this.funcaoBuscaAvancada(event);
    }
  },
  computed: {
    parametrosDeBusca() {
      //parametros de busca avancada
      const labelsPermitidas = [
        "numero_processo_projeto_lei",
        "assunto",
        "numero_oficio_msg",
        "numero_autografo_lei",
        "numero_lei",
        "em_tramitacao",
        "ano"
      ];

      const keys = Object.keys(this.$route.query).filter(key =>
        labelsPermitidas.includes(key)
      );

      let parametros = [];
      const labels = {
        numero_processo_projeto_lei: {
          label: "Nº do Projeto de Lei",
          valor: valor => valor
        },
        assunto: { label: "Assunto", valor: valor => valor },
        ano: { label: "Ano", valor: valor => valor },
        numero_oficio_msg: {
          label: "Nº do Ofício mensagem",
          valor: valor => valor
        },
        numero_autografo_lei: {
          label: "Nº do Autográfo de Lei",
          valor: valor => valor
        },
        numero_lei: {
          label: "Nº Lei",
          valor: valor => valor
        },
        em_tramitacao: {
          label: "Status andamento",
          valor: valor =>
            valor !== "false" && valor ? "Em tramitação" : "Finalizado"
        }
      };

      for (let key of keys) {
        if (
          this.$route.query[key] != null &&
          this.$route.query[key] != undefined &&
          this.$route.query[key] !== ""
        ) {
          parametros.push({
            label: labels[key].label,
            key,
            valor: labels[key].valor(this.$route.query[key])
          });
        }
      }

      const formatData = data => format(parseISO(data), "dd/MM/yyyy");

      if (
        this.$route.query.periodo_inicial_ato &&
        this.$route.query.data_oficio_fim
      ) {
        parametros.push({
          label: "Data do Ofício",
          key: ["data_oficio_inicio", "data_oficio_fim"],
          valor: `${formatData(
            this.$route.query.data_oficio_inicio
          )} a ${formatData(this.$route.query.data_oficio_fim)}`
        });
      } else if (this.$route.query.data_oficio_inicio) {
        parametros.push({
          label: "Data do Ofício",
          key: ["data_oficio_inicio", "data_oficio_fim"],
          valor: `A partir de ${formatData(
            this.$route.query.data_oficio_inicio
          )}`
        });
      } else if (this.$route.query.data_oficio_fim) {
        parametros.push({
          label: "Data do Ofício",
          key: ["data_oficio_inicio", "data_oficio_fim"],
          valor: `Até ${formatData(this.$route.query.data_oficio_fim)}`
        });
      }

      return parametros;
    }
  }
};
</script>

<style lang="scss" scoped>
.grupo-parametros {
  margin: 0.5rem 0rem -0.5rem;
}
div {
  display: flex;
  flex-wrap: wrap;
  margin: 0rem 0rem 0.2rem 0.2rem;
}
@media screen and (min-width: 64rem) {
  .grupo-parametros {
    margin-top: 1.5rem;
  }
}
</style>