<template>
  <div class="grupo-parametros">
    <div v-for="param in parametrosDeBusca" :key="param.key.toString()">
      <BotaoParametroBusca
        :label="param.label"
        :valor="param.valor"
        :onClick="(event) => onClick(event, param)"
      />
    </div>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";

import BotaoParametroBusca from "../BotaoParametroBusca";

export default {
  name: "ParametrosBusca",
  props: {
    funcaoBuscaAvancada: {
      type: Function,
      required: true
    },
    tiposVeto: {
      type: Array,
      required: true
    },
    tiposLegislacao: {
      type: Array,
      required: true
    },
    filtro: {
      type: Object,
      required: true
    }
  },
  components: {
    BotaoParametroBusca
  },
  methods: {
    onClick: function(event, parametro) {
      const keys = Array.isArray(parametro.key)
        ? parametro.key
        : [parametro.key];
      for (let key of keys) {
        this.filtro[key] = undefined;
      }

      this.funcaoBuscaAvancada(event);
    }
  },
  computed: {
    parametrosDeBusca() {
      //parametros de busca avancada
      const labelsPermitidas = [
        "tipo_veto",
        "tipo_legislacao",
        "texto",
        "numero",
        "ano"
      ];

      const keys = Object.keys(this.$route.query).filter(key =>
        labelsPermitidas.includes(key)
      );

      let parametros = [];
      const labels = {
        numero: {
          label: "Número",
          valor: valor => valor
        },
        texto: { label: "Texto", valor: valor => valor },
        tipo_veto: {
          label: "Tipo de Veto",
          valor: valor => {
            const t = this.tiposVeto.find(
              tipo => parseInt(tipo[1]) === parseInt(valor)
            );
            if (t) {
              return t[0];
            }
            return "";
          }
        },
        tipo_legislacao: {
          label: "Tipo de Legislação",
          valor: valor => {
            const t = this.tiposLegislacao.find(
              tipo => parseInt(tipo.id) === parseInt(valor)
            );
            if (t) {
              return t.nome;
            }
            return "";
          }
        },
        ano: {
          label: "Ano",
          valor: valor => valor
        }
      };

      for (let key of keys) {
        if (
          this.$route.query[key] != null &&
          this.$route.query[key] != undefined &&
          this.$route.query[key] !== ""
        ) {
          parametros.push({
            label: labels[key].label,
            key,
            valor: labels[key].valor(this.$route.query[key])
          });
        }
      }

      const formatData = data => format(parseISO(data), "dd/MM/yyyy");

      if (
        this.$route.query.periodo_inicial &&
        this.$route.query.periodo_final
      ) {
        parametros.push({
          label: "Data do Ato",
          key: ["periodo_inicial", "periodo_final"],
          valor: `${formatData(
            this.$route.query.periodo_inicial
          )} a ${formatData(this.$route.query.periodo_final)}`
        });
      } else if (this.$route.query.periodo_inicial) {
        parametros.push({
          label: "Data do Ato",
          key: ["periodo_inicial", "periodo_final"],
          valor: `A partir de ${formatData(this.$route.query.periodo_inicial)}`
        });
      } else if (this.$route.query.periodo_final) {
        parametros.push({
          label: "Data do Ato",
          key: ["periodo_inicial", "periodo_final"],
          valor: `Até ${formatData(this.$route.query.periodo_final)}`
        });
      }

      return parametros;
    }
  }
};
</script>

<style lang="scss" scoped>
.grupo-parametros {
  margin: 0.5rem 0rem -0.5rem;
}
div {
  display: flex;
  flex-wrap: wrap;
  margin: 0rem 0rem 0.2rem 0.2rem;
}
@media screen and (min-width: 64rem) {
  .grupo-parametros {
    margin-top: 1.5rem;
  }
}
</style>