export default function tryParseInt(numberString, base = 10) {
    if(numberString == undefined || numberString == null || numberString == '') {
        return null;
    }

    const convertedNumber = parseInt(numberString, base);
    if(isNaN(convertedNumber)) {
        return null;
    }
    return convertedNumber;
}