<template>
  <div>
    <div class="centro">
      <h1>Legislação</h1>
      <BuscaSimplesCards />
    </div>
    <div class="pesquise">
      <div class="centro">
        <div>
          <h2>Pesquise aqui:</h2>
          <!--<div class="busca-info">
            <p class="info-texto">Experimente os novos recursos de busca!</p>
            <p class="info-texto">A nova busca permite a combinação de vários critérios de pesquisa. Além do texto, você pode utilizar operadores especiais. Clique na opção desejada <span>(E, OU, NÃO, FRASE ÚNICA)</span> para aplicar o critério.</p>
          </div>-->
          <div class="item row1">
            <form @submit="fazerBuscaSimples">
              <div class="busca-simples">
                <input
                  type="text"
                  placeholder="Digite o número ou texto da legislação"
                  id="inputPesquisa"
                  v-model="termo"
                />
                <button title="Pesquisar" type="submit" class="submit-busca-simples">
                  <img src="../../assets/images/pesquisa.png" class="pesquisa" alt=" " />
                </button>
              </div>
            </form>
          </div>
          <div class="text-center my-3 tooltip">
            <div class="container-tooltip">
              <button @click.prevent="addOperatorPesquisa('&')"
                      @mouseover="showModal(1)" @mouseleave="hideModal(1)"
                      id="tooltip-target-1"
                      class="button">
                E
              </button>
              <div id="modal1" class="modal modal-1">
                <p>Para buscar vários termos, insira-os separados por um espaço ou pelo caractere <span>"&".</span></p>
                <p><span>Exemplo:</span> Se você deseja encontrar uma legislação que contenha a frase "Declara de utilidade pública a entidade que especifica" e também inclua o termo "segurança", a busca deve ser feita da seguinte forma:</p>
                <p><span>"Declara de utilidade pública a entidade que especifica" & segurança</span></p>
              </div>
            </div>
            <div class="container-tooltip">
              <button @click.prevent="addOperatorPesquisa('OR')"
                      @mouseover="showModal(2)" @mouseleave="hideModal(2)"
                      id="tooltip-target-2"
                      class="button">
                OU
              </button>
              <div id="modal2" class="modal modal-2">
                <p>Para buscar um termo ou outro, insira os termos separados pela palavra <span>"OR".</span></p>
                <p><span>Exemplo:</span> Se você deseja encontrar uma legislação que contenha os termos "segurança" ou "educação", a busca deve ser feita da seguinte forma:</p>
                <p><span>segurança OR educação</span></p>
              </div>
            </div>
            <div class="container-tooltip">
              <button @click.prevent="addOperatorPesquisa('-')"
                      @mouseover="showModal(3)" @mouseleave="hideModal(3)"
                      id="tooltip-target-3"
                      class="button">
                NÃO
              </button>
              <div id="modal3" class="modal modal-3">
                <p>Para excluir um termo da busca, insira o caractere <span>"-"</span> antes do termo.</p>
                <p><span>Exemplo:</span> Se você deseja encontrar uma legislação que não contenha o termo "segurança", a busca deve ser feita da seguinte forma:</p>
                <p><span>-segurança</span></p>
              </div>
            </div>
            <div class="container-tooltip">
              <button @click.prevent="addOperatorPesquisa('&quot; &quot;')" 
                      @mouseover="showModal(4)" @mouseleave="hideModal(4)"
                      id="tooltip-target-4"
                      class="button">
                FRASE ÚNICA
              </button>
              <div id="modal4" class="modal modal-4">
                <p>Para buscar uma frase, insira a frase entre aspas <span>(" ").</span></p>
                <p><span>Exemplo:</span> Se você deseja encontrar uma legislação pela frase "Declara de utilidade pública a entidade que especifica", a busca deve ser feita da seguinte forma:</p>
                <p><span>"Declara de utilidade pública a entidade que especifica"</span></p>
              </div>
            </div>
          </div>
          <div class="botao">
            <button type="button" @click="tooglePesquisaAvancada">
              Pesquisa avançada
              <span>
                <template v-if="!exibirPesquisaAvancada">&#9660;</template>
                <template v-else>&#9650;</template>
              </span>
            </button>
          </div>
          <transition name="slide">
            <form v-if="exibirPesquisaAvancada" @submit.prevent="fazerBuscaAvancada" @keydown.enter.prevent="fazerBuscaAvancada">
              <div class="avancado">
                <div class="row2">
                  <div class="item">
                    <label>Tipo</label>
                    <select v-model="filtro.tipo_legislacao">
                      <option :value="undefined">Todos</option>
                      <option
                        v-for="tipo in tipos_legislacoes"
                        :key="tipo.id"
                        :value="tipo.id"
                      >{{ tipo.nome }}</option>
                    </select>
                  </div>
                  <div class="row2mobile">
                    <div class="item">
                      <label>Nº da Legislação</label>
                      <input type="text" v-model="filtro.numero" />
                    </div>
                    <div class="item">
                      <label>Ano da Legislação</label>
                      <input type="number" v-model="filtro.ano" />
                    </div>
                  </div>
                </div>
                <div class="row2">
                  <div class="grupo">
                    <p>Data da Legislação</p>
                    <div class="data">
                      <div class="item">
                        <label>De</label>
                        <date-picker
                          v-model="filtro.periodo_inicial_legislacao"
                          :formatter="datepickerFormatter"
                        ></date-picker>
                      </div>
                      <div class="item">
                        <label>Até</label>
                        <date-picker
                          v-model="filtro.periodo_final_legislacao"
                          :formatter="datepickerFormatter"
                        ></date-picker>
                      </div>
                    </div>
                  </div>
                  <div class="grupo">
                    <p>Data do Diário</p>
                    <div class="data">
                      <div class="item">
                        <label>De</label>
                        <date-picker
                          v-model="filtro.periodo_inicial_diario"
                          :formatter="datepickerFormatter"
                        ></date-picker>
                      </div>
                      <div class="item">
                        <label>Até</label>
                        <date-picker
                          v-model="filtro.periodo_final_diario"
                          :formatter="datepickerFormatter"
                        ></date-picker>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row2">
                  <div class="item">
                    <label>Autor (A partir de 2019)</label>
                    <input type="text" v-model="filtro.autor" />
                  </div>
                  <div class="item">
                    <label>Categoria</label>
                    <Autocomplete
                        ref="autocomplete"
                        @update-items="filtrarCategoria"
                        @item-selected="atribuiCategoria"
                        @focus="filtrarCategoria('')"
                        :items="categorias_legislacao_filtradas"
                        :component-item='ItemAutocompleteTemplate'
                        :get-label="getLabelCategoria" 
                        :auto-select-one-item=false
                        :min-len="0"
                        :wait="100"
                        placeholder="Digite o nome da categoria para filtrar"
                        clearable>
                    </Autocomplete>
                  </div>
                </div>
                <div class="row2">
                  <div class="item">
                    <div class="gridbox-1">
                      <div class="input">
                        <label>Texto</label>
                        <input 
                          id="inputTexto" 
                          type="text" 
                          v-model="filtro.conteudo" />
                      </div>
                      <div class="text-center my-3 tooltip">
                        <div class="container-tooltip">
                          <button @click.prevent="addOperatorTexto('&')"
                                  @mouseover="showModal(9)" @mouseleave="hideModal(9)"
                                  id="tooltip-target-avancado-5"
                                  class="button">
                            E
                          </button>
                          <div id="modal9" class="modal modal-1">
                            <p>Para buscar vários termos, insira-os separados por um espaço ou pelo caractere <span>"&".</span></p>
                            <p><span>Exemplo:</span> Se você deseja encontrar uma legislação pela frase "Declara de utilidade pública a entidade que especifica" e que contenha o termo "segurança", a busca deve ser feita da seguinte forma:</p>
                            <p><span>"Declara de utilidade pública a entidade que especifica" & segurança</span></p>
                          </div>
                        </div>
                        <div class="container-tooltip">
                          <button @click.prevent="addOperatorTexto('OR')"
                                  @mouseover="showModal(10)" @mouseleave="hideModal(10)"
                                  id="tooltip-target-avancado-6"
                                  class="button">
                            OU
                          </button>
                          <div id="modal10" class="modal modal-2">
                            <p>Para buscar um termo ou outro, insira os termos separados pela palavra <span>"OR".</span></p>
                            <p><span>Exemplo:</span> Se você deseja encontrar uma legislação pelos termos "segurança" ou "educação", a busca deve ser feita da seguinte forma:</p>
                            <p><span>segurança OR educação</span></p>
                          </div>
                        </div>
                        <div class="container-tooltip">
                          <button @click.prevent="addOperatorTexto('-')"
                                  @mouseover="showModal(11)" @mouseleave="hideModal(11)"
                                  id="tooltip-target-avancado-7"
                                  class="button">
                            NÃO
                          </button>
                          <div id="modal11" class="modal modal-3">
                            <p>Para excluir um termo da busca, insira o caractere <span>"-"</span> antes do termo.</p>
                            <p><span>Exemplo:</span> Se você deseja encontrar uma legislação que não contenha o termo "segurança", a busca deve ser feita da seguinte forma:</p>
                            <p><span>-segurança</span></p>
                          </div>
                        </div>
                        <div class="container-tooltip">
                          <button @click.prevent="addOperatorTexto('&quot; &quot;')" 
                                  @mouseover="showModal(12)" @mouseleave="hideModal(12)"
                                  id="tooltip-target-avancado-8"
                                  class="button">
                            FRASE ÚNICA
                          </button>
                          <div id="modal12" class="modal modal-4">
                            <p>Para buscar uma frase, insira a frase entre aspas <span>(" ").</span></p>
                            <p><span>Exemplo:</span> Se você deseja encontrar uma legislação pela frase "Declara de utilidade pública a entidade que especifica", a busca deve ser feita da seguinte forma:</p>
                            <p><span>"Declara de utilidade pública a entidade que especifica"</span></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="gridbox-1">
                      <div class="input">
                        <label>Ementa</label>
                        <input 
                          id="inputEmenta" 
                          type="text" 
                          v-model="filtro.ementa" />
                      </div>
                      <div class="text-center my-3 tooltip">
                        <div class="container-tooltip">
                          <button @click.prevent="addOperatorEmenta('&')"
                                  @mouseover="showModal(5)" @mouseleave="hideModal(5)"
                                  id="tooltip-target-avancado-1"
                                  class="button">
                            E
                          </button>
                          <div id="modal5" class="modal modal-1">
                            <p>Para buscar vários termos, insira-os separados por um espaço ou pelo caractere <span>"&".</span></p>
                            <p><span>Exemplo:</span> Se você deseja encontrar uma legislação pela frase "Declara de utilidade pública a entidade que especifica" e que contenha o termo "segurança", a busca deve ser feita da seguinte forma:</p>
                            <p><span>"Declara de utilidade pública a entidade que especifica" & segurança</span></p>
                          </div>
                        </div>
                        <div class="container-tooltip">
                          <button @click.prevent="addOperatorEmenta('OR')"
                                  @mouseover="showModal(6)" @mouseleave="hideModal(6)"
                                  id="tooltip-target-avancado-6"
                                  class="button">
                            OU
                          </button>
                          <div id="modal6" class="modal modal-2">
                            <p>Para buscar um termo ou outro, insira os termos separados pela palavra <span>"OR".</span></p>
                            <p><span>Exemplo:</span> Se você deseja encontrar uma legislação pelos termos "segurança" ou "educação", a busca deve ser feita da seguinte forma:</p>
                            <p><span>segurança OR educação</span></p>
                          </div>
                        </div>
                        <div class="container-tooltip">
                          <button @click.prevent="addOperatorEmenta('-')"
                                  @mouseover="showModal(7)" @mouseleave="hideModal(7)"
                                  id="tooltip-target-avancado-7"
                                  class="button">
                            NÃO
                          </button>
                          <div id="modal7" class="modal modal-3">
                            <p>Para excluir um termo da busca, insira o caractere <span>"-"</span> antes do termo.</p>
                            <p><span>Exemplo:</span> Se você deseja encontrar uma legislação que não contenha o termo "segurança", a busca deve ser feita da seguinte forma:</p>
                            <p><span>-segurança</span></p>                            
                          </div>
                        </div>
                        <div class="container-tooltip">
                          <button @click.prevent="addOperatorEmenta('&quot; &quot;')" 
                                  @mouseover="showModal(8)" @mouseleave="hideModal(8)"
                                  id="tooltip-target-avancado-8"
                                  class="button">
                            FRASE ÚNICA
                          </button>
                          <div id="modal8" class="modal modal-4">
                            <p>Para buscar uma frase, insira a frase entre aspas <span>(" ").</span></p>
                            <p><span>Exemplo:</span> Se você deseja encontrar uma legislação pela frase "Declara de utilidade pública a entidade que especifica", a busca deve ser feita da seguinte forma:</p>
                            <p><span>"Declara de utilidade pública a entidade que especifica"</span></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div></div>
                  <div class="botoes">
                    <button title="Limpar" @click="limparFiltro()" type="button">Limpar</button>
                    <button title="Pesquisar" type="submit">Buscar</button>
                  </div>
                </div>
              </div>
            </form>
          </transition>
        </div>
        <div class="seta">
          <img src="../../assets/images/triangulo-form.png" class="triangulo t-branco" alt=" " />
          <img src="../../assets/images/triangulo-form-black.png" class="triangulo t-preto" alt=" " />
        </div>
      </div>
    </div>
    <div class="centro campo-resultados" id="resultados">
      <div class="nav-resultados">
        <div class="first-flex">
          <h2>Resultados da Pesquisa</h2>
          <div class="dados-abertos" v-show="!carregando && !erro">
            <p>
              <span>Dados Abertos:</span>
              <a @click="abrirDadosAbertos('json')">JSON</a>
              <a @click="abrirDadosAbertos('csv')">CSV</a>
            </p>
          </div>
        </div>
        <div class="second-flex">
          <ParametrosBusca
            :funcaoBuscaSimples="(event) => {
              this.termo = null; 
              this.fazerBuscaSimples(event) 
            }"
            :funcaoBuscaAvancada="fazerBuscaAvancada"
            :tiposLegislacao="tipos_legislacoes"
            :categorias="categorias_legislacao"
            :filtro="filtro"
            v-show="!carregando && !erro"
          />
          <div class="ordenacao" v-show="!carregando && !erro && termo">
            <label for="ordenarPor">Ordenar por: </label>
            <select id="ordenarPor" v-model="ordenarPor" @change="atualizarOrdenacao">
              <option value="data">Data</option>
              <option value="relevancia">Relevância</option>
            </select>
          </div>
        </div>
        <div class="third-flex">
          <div class="nresultados" v-show="!carregando && !erro">
            <p>
              <strong>{{ totalResultadosTexto }}</strong>
              {{
              totalResultadosTexto === 1
              ? ` resultado encontrado`
              : ` resultados encontrados`
              }}
            </p>
          </div>          
          <div class="npagina" v-show="!carregando">
            <label>Resultados por Página</label>
            <select :value="qtd_por_pagina" @input="mudouQtdPagina">
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
      </div>
      <div>
        <TabelaResultadosLegislacao
          :legislacoes="legislacoes"
          v-if="!carregando && !erro && !erroFiltro"
        ></TabelaResultadosLegislacao>

        <Loading v-if="carregando" size="medium"></Loading>
        <ErroResultados v-if="!carregando && erro"></ErroResultados>

        <paginate
          v-if="!erro && !carregando"
          v-model="pagina"
          :page-count="pageCount"
          :click-handler="mudouPagina"
          :prev-text="'Anterior'"
          :next-text="'Próxima'"
          :container-class="'className'"
          active-class="active-page"
        ></paginate>
      </div>
    </div>
  </div>
</template>

<script>
  import { format, parseISO } from "date-fns";
  import Paginate from "vuejs-paginate";
  import DatePicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";
  import "vue2-datepicker/locale/pt-br";

  import Autocomplete from 'v-autocomplete';
  import ItemAutocompleteTemplate from '../../components/AutocompleteTemplate';
  import 'v-autocomplete/dist/v-autocomplete.css';

  import LegislacaoApi from "../../services/LegislacaoApi";
  import TipoLegislacaoApi from "../../services/TipoLegislacaoApi";
  import CategoriaLegislacaoApi from "../../services/CategoriaLegislacaoApi";
  import TabelaResultadosLegislacao from "../../components/pesquisa_legislacao/TabelaResultadosLegislacao";
  import { datepickerFormatter } from "../../helpers/datepickerFormatter";
  import BuscaSimplesCards from "../../components/pesquisa_legislacao/BuscaSimplesCards";
  import Loading from "../../components/Loading";
  import ErroResultados from "../../components/ErroResultados";
  import tryParseInt from "../../helpers/tryParseInt";
  import ParametrosBusca from "../../components/pesquisa_legislacao/ParametrosBusca";
  import metaDados from "../../helpers/metaDados";

  export default {
    name: "PesquisaLegislacao",
    mounted() {
      this.ordenarPor = "data";
    },
    metaInfo() {
      return {
        title: "Pesquisa a Legislação",
        titleTemplate: "%s - Casa Civil do Estado de Goiás",
        meta: [
          {
            vmid: "description",
            name: "description",
            property: "description",
            content:
              "Página de acesso e pesquisa a toda legislação e atos normativos do Governo do Estado de Goiás."
          },
          {
            vmid: "og:description",
            property: "og:description",
            content:
              "Página de acesso e pesquisa a toda legislação e atos normativos do Governo do Estado de Goiás."
          },
          {
            vmid: "og:title",
            property: "og:title",
            content: "Pesquisa a Legislação - Casa Civil do Estado de Goiás"
          },
          {
            vmid: "twitter:description",
            name: "twitter:description",
            property: "twitter:description",
            content:
              "Página de acesso e pesquisa a toda legislação e atos normativos do Governo do Estado de Goiás."
          },
          {
            vmid: "twitter:title",
            name: "twitter:title",
            property: "twitter:title",
            content: "Pesquisa a Legislação - Casa Civil do Estado de Goiás"
          },
          ...metaDados(this.$route),
        ]
      };
    },
    data() {
      return {
        legislacoes: [],
        maisRecentes: [],
        carregandoMaisRecentes: true,
        erroMaisRecentes: false,
        carregando: true,
        erro: false,
        totalResultados: null,
        totalResultadosTexto: null,
        pagina: 1,
        qtd_por_pagina: 10,
        termo: null, //termo usado na busca simples
        ordenarPor: 'data', // Valor padrão
        filtro: {
          numero: null,
          conteudo: null,
          tipo_legislacao: null,
          categoria_legislacao: null,
          ementa: null,
          autor: null,
          ano: null,
          periodo_inicial_legislacao: null,
          periodo_final_legislacao: null,
          periodo_inicial_diario: null,
          periodo_final_diario: null
        },
        carregandoFiltro: true,
        erroFiltro: false,
        tipos_legislacoes: [],
        categorias_legislacao: [],
        categorias_legislacao_filtradas: [],
        exibirPesquisaAvancada: tryParseInt(this.getParam("busca_avancada")) == 1,
        datepickerFormatter,
        ItemAutocompleteTemplate: ItemAutocompleteTemplate
      };
    },
    components: {
      TabelaResultadosLegislacao,
      Paginate,
      DatePicker,
      Loading,
      ErroResultados,
      BuscaSimplesCards,
      ParametrosBusca,
      Autocomplete,
    },
    created() {
      this.legislacaoApi = new LegislacaoApi();

      this.tipoLegislacaoApi = new TipoLegislacaoApi();
      this.categoriaLegislacaoApi = new CategoriaLegislacaoApi();

      this.carregarQtdPorPagina();
      this.carregarValoresFiltro();
      this.carregarLegislacao();
    },
    watch: {
      $route: function(to, from) {
        const mudouQuery =
          to.query.numero != from.query.numero ||
          to.query.conteudo != from.query.conteudo ||
          to.query.tipo_legislacao != from.query.tipo_legislacao ||
          to.query.categoria_legislacao != from.query.categoria_legislacao ||
          to.query.ementa != from.query.ementa ||
          to.query.autor != from.query.autor ||
          to.query.ano != from.query.ano ||
          to.query.periodo_inicial_legislacao !=
            from.query.periodo_inicial_legislacao ||
          to.query.periodo_final_legislacao !=
            from.query.periodo_final_legislacao ||
          to.query.periodo_inicial_diario != from.query.periodo_inicial_diario ||
          to.query.periodo_final_diario != from.query.periodo_final_diario ||
          to.query.pagina != from.query.pagina ||
          to.query.qtd_por_pagina != from.query.qtd_por_pagina ||
          to.query.termo != from.query.termo ||
          to.query.ordenarPor != from.query.ordenarPor;

        if (
          from.name == "PesquisaLegislacao" &&
          to.name == "PesquisaLegislacao" &&
          mudouQuery
        ) {
          this.carregarValoresFiltro();
          this.carregarLegislacao();
        }
      }
    },
    methods: {
      atualizarOrdenacao(event) {
        this.ordenarPor = event.target.value;
        this.fazerBuscaSimples(event);
      },
      showModal(numero) {
        var modalAtivo = document.getElementById("modal" + numero)

        modalAtivo.style.display = "block"
      },
      hideModal(numero) {
        var modalAtivo = document.getElementById("modal" + numero)

        modalAtivo.style.display = "none"
      },
      addOperatorPesquisa(operator) {
        var inputPesquisa = document.getElementById("inputPesquisa");
        inputPesquisa.focus();
        if(this.termo == null) {
          this.termo = '';
        }

        var cursorPos = inputPesquisa.selectionStart;
        this.termo = this.termo.slice(0, cursorPos) + operator + this.termo.slice(cursorPos);
        inputPesquisa.selectionStart = cursorPos + operator.length;
        inputPesquisa.selectionEnd = cursorPos + operator.length;
      },
      addOperatorEmenta(operator) {
        var inputEmenta = document.getElementById("inputEmenta");
        inputEmenta.focus();
        if(this.filtro.ementa == null) {
          this.filtro.ementa = '';
        }

        var cursorPos = inputEmenta.selectionStart;
        this.filtro.ementa = this.filtro.ementa.slice(0, cursorPos) + operator + this.filtro.ementa.slice(cursorPos);
        inputEmenta.selectionStart = cursorPos + operator.length;
        inputEmenta.selectionEnd = cursorPos + operator.length;
      },
      addOperatorTexto(operator) {
        var inputTexto = document.getElementById("inputTexto");
        inputTexto.focus();
        if(this.filtro.conteudo == null) {
          this.filtro.conteudo = '';
        }
        var cursorPos = inputTexto.selectionStart;
        this.filtro.conteudo = this.filtro.conteudo.slice(0, cursorPos) + operator + this.filtro.conteudo.slice(cursorPos);
        inputTexto.selectionStart = cursorPos + operator.length;
        inputTexto.selectionEnd = cursorPos + operator.length;
      },
      clicarBotaoAjuda() {
        var conteudoAjuda = document.getElementsByClassName("info-tooltip")
        if(conteudoAjuda[0].style.display == "block") {
          conteudoAjuda[0].style.display = "none"
        }else {
          conteudoAjuda[0].style.display = "block"
        }
      },
      mudouQtdPagina: function(event) {
        const novaQtdPagina = parseInt(event.target.value);
        if (novaQtdPagina < this.qtd_por_pagina) {
          this.qtd_por_pagina = novaQtdPagina;
          this.mudouPagina(this.pagina);
        } else {
          let novaPagina = Math.ceil(
            (this.qtd_por_pagina * this.pagina) / novaQtdPagina
          );

          this.qtd_por_pagina = novaQtdPagina;
          this.mudouPagina(novaPagina);
        }
      },
      getParam: function(name) {
        if (this.$route && this.$route.query) {
          return this.$route.query[name];
        }
        return null;
      },
      fazerBuscaSimples: function(event) {
        event.preventDefault();
        
        this.$router.push({
          name: "PesquisaLegislacao",
          query: {
            termo: this.termo,
            ordenarPor: this.ordenarPor,
            pagina: 1,
            qtd_por_pagina: this.qtd_por_pagina
          }
        });

        this.$scrollTo("#resultados", 700, {
          offset: -100,
          cancelable: false
        });
      },
      fazerBuscaAvancada: function(event) {
        event.preventDefault();
        this.$router.push({
          name: "PesquisaLegislacao",
          query: {
            ...this.filtro,
            ...this.convertePeriodosDatasParaString(),
            pagina: 1,
            qtd_por_pagina: this.qtd_por_pagina,
            busca_avancada: 1,
          }
        });
        this.$scrollTo("#resultados", 700, {
          offset: -100,
          cancelable: false
        });
      },
      mudouPagina: function(page) {
        this.pagina = page;
        this.ordenarPor = this.getParam("ordenarPor");
        let termo = {};
        if (this.getParam("termo")) {
          termo = { termo: this.getParam("termo") };
        }

        const filtro = {
          numero: this.getParam("numero"),
          conteudo: this.getParam("conteudo"),
          tipo_legislacao: this.getParam("tipo_legislacao"),
          categoria_legislacao: this.getParam("categoria_legislacao"),
          ementa: this.getParam("ementa"),
          autor: this.getParam("autor"),
          ano: this.getParam("ano"),
          periodo_inicial_legislacao: this.getParam("periodo_inicial_legislacao"),
          periodo_final_legislacao: this.getParam("periodo_final_legislacao"),
          periodo_inicial_diario: this.getParam("periodo_inicial_diario"),
          periodo_final_diario: this.getParam("periodo_final_diario")
        };

        this.$router.push({
          name: "PesquisaLegislacao",
          query: {
            ...filtro,
            ...termo,
            pagina: this.pagina,
            qtd_por_pagina: this.qtd_por_pagina,
            ordenarPor: this.ordenarPor,
            busca_avancada: this.getParam("busca_avancada")
          }
        });
        this.$scrollTo("#resultados", 0, {
          offset: -100,
          cancelable: false
        });
      },
      carregarValoresFiltro: async function() {
        try {
          if (
            !this.carregandoFiltro &&
            !this.erroFiltro &&
            this.tipos_legislacoes &&
            this.tipos_legislacoes.length > 0
          )
            return;

          this.carregandoFiltro = true;
          this.erroFiltro = false;

          this.tipos_legislacoes = await this.tipoLegislacaoApi.getTiposLegislacao();
          
          var categorias = await this.categoriaLegislacaoApi.getCategoriasLegislacao();
          this.categorias_legislacao = categorias;
          this.categorias_legislacao_filtradas = this.categorias_legislacao;

          this.carregandoFiltro = false;
        } catch (err) {
          console.log("carregarValoresFiltro", err);
          this.carregandoFiltro = false;
          this.erroFiltro = true;
          this.tipos_legislacoes = [];
          this.categorias_legislacao = [];
          this.categorias_legislacao_filtradas = [];
        }
      },
      carregarLegislacao: async function() {
        try {
          this.carregando = true;
          this.erro = false;

          this.qtd_por_pagina =
            this.getParam("qtd_por_pagina") || this.qtd_por_pagina;

          this.armazenarQtdPorPagina();

          const pagina = this.getParam("pagina") || 1;
          this.pagina = parseInt(pagina);
          this.termo = this.getParam("termo");
          this.ordenarPor = this.getParam("ordenarPor");

          this.filtro = {
            numero: this.getParam("numero"),
            conteudo: this.getParam("conteudo"),
            tipo_legislacao: this.getParam("tipo_legislacao"),
            categoria_legislacao: this.getParam("categoria_legislacao"),
            ementa: this.getParam("ementa"),
            autor: this.getParam("autor"),
            ano: this.getParam("ano"),
            ...this.convertPeriodosStringParaDatas()
          };

          const buscaAvancada = tryParseInt(this.getParam("busca_avancada"));

          if (this.legislacaoApi.cancelTokens.getLegislacoes) {
            this.legislacaoApi.cancelTokens.getLegislacoes.cancel();
          }

          let data;
          if (buscaAvancada && buscaAvancada === 1) {
            data = await this.legislacaoApi.getLegislacoes({
              ...this.filtro,
              periodo_inicial_legislacao: this.getParam(
                "periodo_inicial_legislacao"
              ),
              periodo_final_legislacao: this.getParam("periodo_final_legislacao"),
              periodo_inicial_diario: this.getParam("periodo_inicial_diario"),
              periodo_final_diario: this.getParam("periodo_final_diario"),
              qtd_por_pagina: this.qtd_por_pagina,
              page: this.pagina
            });
          } else {
            data = await this.legislacaoApi.getLegislacoes({
              termo: this.termo,
              qtd_por_pagina: this.qtd_por_pagina,
              ordenarPor: this.ordenarPor,
              page: this.pagina
            });
          }

          this.legislacoes = data.resultados;
          this.totalResultados = data.total_resultados;
          this.totalResultadosTexto = data.total_resultados.toLocaleString();
          this.carregando = false;
        } catch (err) {
          if (this.legislacaoApi.isCancel(err)) {
            return;
          }
          console.log("carregarLegislacao", err);
          this.carregando = false;
          this.erro = true;
        }
      },
      limparFiltro: function() {
        this.filtro = {
          numero: null,
          conteudo: null,
          tipo_legislacao: undefined,
          categoria_legislacao: undefined,
          ementa: null,
          autor: null,
          ano: null,
          periodo_inicial_legislacao: null,
          periodo_final_legislacao: null,
          periodo_inicial_diario: null,
          periodo_final_diario: null
        };
        this.$refs.autocomplete.searchText = null
      },
      convertePeriodosDatasParaString: function() {
        return {
          periodo_inicial_legislacao: this.filtro.periodo_inicial_legislacao
            ? format(this.filtro.periodo_inicial_legislacao, "yyyy-MM-dd")
            : null,
          periodo_final_legislacao: this.filtro.periodo_final_legislacao
            ? format(this.filtro.periodo_final_legislacao, "yyyy-MM-dd")
            : null,
          periodo_inicial_diario: this.filtro.periodo_inicial_diario
            ? format(this.filtro.periodo_inicial_diario, "yyyy-MM-dd")
            : null,
          periodo_final_diario: this.filtro.periodo_final_diario
            ? format(this.filtro.periodo_final_diario, "yyyy-MM-dd")
            : null
        };
      },
      convertPeriodosStringParaDatas: function() {
        const periodo_inicial_legislacao = this.getParam(
          "periodo_inicial_legislacao"
        );
        const periodo_final_legislacao = this.getParam(
          "periodo_final_legislacao"
        );
        const periodo_inicial_diario = this.getParam("periodo_inicial_diario");
        const periodo_final_diario = this.getParam("periodo_final_diario");

        return {
          periodo_inicial_legislacao: periodo_inicial_legislacao
            ? parseISO(periodo_inicial_legislacao)
            : null,
          periodo_final_legislacao: periodo_final_legislacao
            ? parseISO(periodo_final_legislacao)
            : null,
          periodo_inicial_diario: periodo_inicial_diario
            ? parseISO(periodo_inicial_diario)
            : null,
          periodo_final_diario: periodo_final_diario
            ? parseISO(periodo_final_diario)
            : null
        };
      },
      tooglePesquisaAvancada: function() {
        this.exibirPesquisaAvancada = !this.exibirPesquisaAvancada;
      },
      abrirDadosAbertos: function(format) {
        if (
          this.totalResultados == undefined ||
          this.totalResultados == null ||
          this.totalResultados > 1000
        ) {
          alert(
            "O download dos dados é limitado a 1000 registros. Refaça sua consulta utilizando os filtros disponíveis."
          );
        } else {
          const params = new URLSearchParams({
            numero: this.getParam("numero") || "",
            conteudo: this.getParam("conteudo") || "",
            tipo_legislacao: this.getParam("tipo_legislacao") || "",
            categoria_legislacao: this.getParam("categoria_legislacao") || "",
            ementa: this.getParam("ementa") || "",
            autor: this.getParam("autor") || "",
            ano: this.getParam("ano") || "",
            periodo_inicial_legislacao:
              this.getParam("periodo_inicial_legislacao") || "",
            periodo_final_legislacao:
              this.getParam("periodo_final_legislacao") || "",
            periodo_inicial_diario: this.getParam("periodo_inicial_diario") || "",
            periodo_final_diario: this.getParam("periodo_final_diario") || "",
            termo: this.getParam("termo") || "",
          }).toString();
          window.open(
            `${process.env.VUE_APP_BASE_API_URL}/api/v2/pesquisa/legislacoes/dados_abertos.${format}?${params}`,
            "_blank"
          );
        }
      },
      armazenarQtdPorPagina() {
        localStorage.setItem("qtd_por_pagina_legislacao", this.qtd_por_pagina);
      },
      carregarQtdPorPagina() {
        const qtd = localStorage.getItem("qtd_por_pagina_legislacao");
        if (qtd) {
          this.qtd_por_pagina = tryParseInt(qtd);
        }
      },
      getLabelCategoria: function (item) {
        if (item) {
          return item.nome
        }
      },
      filtrarCategoria: function (texto) {
        if (texto) {
          const res = this.categorias_legislacao.filter(
            hash => hash.nome.toLowerCase().normalize('NFD').includes(texto.toLowerCase().normalize('NFD'))
          );
          this.categorias_legislacao_filtradas = res;
        }
        else {
          this.categorias_legislacao_filtradas = this.categorias_legislacao;
        }
      },
      atribuiCategoria: function (objeto) {
        if (objeto) {
          this.filtro.categoria_legislacao = objeto.id;
          this.categorias_legislacao_filtradas = null;
        }
      },    
    },
    computed: {
      pageCount: function() {
        if (
          this.totalResultados != undefined &&
          this.totalResultados != null &&
          this.qtd_por_pagina != null &&
          this.qtd_por_pagina != undefined &&
          this.totalResultados > 0 &&
          this.qtd_por_pagina > 0
        ) {
          return Math.ceil(this.totalResultados / this.qtd_por_pagina);
        }
        return 0;
      }
    }
  };
</script>

<style lang="scss" scoped>

  .busca-info {
    display: block;
    margin: 0 0 1rem 0;
    padding: 0;
    .info-texto {
      margin: .5rem 0;
      color: #ffa900;
      font-size: .93rem;
      span {
        font-weight: bold;
      }
    }
  }

  .tooltip {
    display: flex;
    width: max-content;
    margin: 1rem 0 1rem 0;
    .button {
      margin: .3rem .7rem 0 0;
      font-size: .9rem;
      background-color: #edf2ee;
      border: none;
      border-radius: .3rem;
      padding: .3rem .5rem;
      box-shadow: 1px 2px 1px #ffa900;
      cursor: pointer;
    }
    .button:hover {
      box-shadow: 0px 0px 0px;
      padding: .27rem .47rem;
      font-size: .87rem;
      background-color: #ffa900;
    }
    .container-tooltip {
      position: relative;
    }
    .modal {
      display: block;
      width: 16rem;
      z-index: 1000;
      position: absolute;
      max-width: 20rem;
      font-size: .9rem;
      background-color: #edf2ee;
      box-shadow: 3px 5px 5px #ffa900;
      padding: .3rem 1rem;
      border-radius: .5rem;

      span {
        font-weight: bold;
      }
    }
    .modal-1 {
      display: none;
    }
    .modal-2 {
      display: none;
    }
    .modal-3 {
      display: none;
    }
    .modal-4 {
      display: none;
    }
  }

  @media screen and (min-width: 320px) and (max-width: 425px){
    .modal-1 {
      left: -.5rem;
      top: 5.5vh;

      &::after {
        content: "";

        width: 0;
        height: 0;

        position: absolute;

        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 20px solid #edf2ee;

        top: -4%;
        left: 0%;
      }
    }
    .modal-2 {
      left: -2.5rem;
      top: 5.5vh;

      &::after {
        content: "";

        width: 0;
        height: 0;

        position: absolute;

        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 20px solid #edf2ee;

        top: -4%;
        left: 13%;
      }
    }
    .modal-3 {
      left: -5.5rem;
      top: 5.5vh;

      &::after {
        content: "";

        width: 0;
        height: 0;

        position: absolute;

        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 20px solid #edf2ee;

        top: -4%;
        left: 31%;
      }
    }
    .modal-4 {
      left: -9rem;
      top: 5.5vh;

      &::after {
        content: "";

        width: 0;
        height: 0;

        position: absolute;

        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 20px solid #edf2ee;

        top: -4%;
        left: 60%;
      }
    }
    .info-tooltip {
      width: 60vw;
      height: max-content;
      position: absolute;
      transform: translateX(-50%);
      background-color: #ffa900;
      padding: 10px;
      border-radius: 4px;
      display: none;
      z-index: 1;
      margin-top: .5rem;
    }

    

    .info-content {
      color: black;
      .exemplo {
        color: #375a3a;
        font-weight: bold;
      }
    }

    div.info-content h2 {
      color: #375a3a;
      border-bottom: #375a3a solid 0.15rem;
      margin-bottom: 1rem;
    }

    .info-content p {
      margin: 0;
      padding: 0;
      margin-bottom: 5px;
    }
  }

  @media screen and (min-width: 426px) and (max-width: 1024px){
    .modal-1 {
      left: -.5rem;
      top: 5.5vh;

      &::after {
        content: "";

        width: 0;
        height: 0;

        position: absolute;

        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 20px solid #edf2ee;

        top: -4%;
        left: 0%;
      }
    }
    .modal-2 {
      left: -2.5rem;
      top: 5.5vh;

      &::after {
        content: "";

        width: 0;
        height: 0;

        position: absolute;

        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 20px solid #edf2ee;

        top: -4%;
        left: 13%;
      }
    }
    .modal-3 {
      left: -5.5rem;
      top: 5.5vh;

      &::after {
        content: "";

        width: 0;
        height: 0;

        position: absolute;

        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 20px solid #edf2ee;

        top: -4%;
        left: 31%;
      }
    }
    .modal-4 {
      left: -9rem;
      top: 5.5vh;

      &::after {
        content: "";

        width: 0;
        height: 0;

        position: absolute;

        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 20px solid #edf2ee;

        top: -4%;
        left: 60%;
      }
    }
    .info-tooltip {
      width: 60vw;
      height: max-content;
      position: absolute;
      transform: translateX(-50%);
      background-color: #ffa900;
      padding: 10px;
      border-radius: 4px;
      display: none;
      z-index: 1;
      margin-top: .5rem;
      left: 20vw;
    }

    

    .info-content {
      color: black;
      .exemplo {
        color: #375a3a;
        font-weight: bold;
      }
    }

    div.info-content h2 {
      color: #375a3a;
      border-bottom: #375a3a solid 0.15rem;
      margin-bottom: 1rem;
    }

    .info-content p {
      margin: 0;
      padding: 0;
      margin-bottom: 5px;
    }
  }

  @media screen and (min-width: 1025px) and (max-width: 1440px){
    .modal {
      width: 22rem !important;
    }
    .modal-1 {
      left: -.5rem;
      top: 5.5vh;

      &::after {
        content: "";

        width: 0;
        height: 0;

        position: absolute;

        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 20px solid #edf2ee;

        top: -4%;
        left: 0%;
      }
    }
    .modal-2 {
      left: -2.5rem;
      top: 5.5vh;

      &::after {
        content: "";

        width: 0;
        height: 0;

        position: absolute;

        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 20px solid #edf2ee;

        top: -4%;
        left: 11%;
      }
    }
    .modal-3 {
      left: -5.5rem;
      top: 5.5vh;

      &::after {
        content: "";

        width: 0;
        height: 0;

        position: absolute;

        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 20px solid #edf2ee;

        top: -4%;
        left: 25%;
      }
    }
    .modal-4 {
      left: -9rem;
      top: 5.5vh;

      &::after {
        content: "";

        width: 0;
        height: 0;

        position: absolute;

        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 20px solid #edf2ee;

        top: -4%;
        left: 50%;
      }
    }
    .info-tooltip {
      width: max-content;
      height: max-content;
      position: absolute;
      transform: translateX(-50%);
      background-color: #ffa900;
      padding: 1rem;
      border-radius: 4px;
      display: none;
      z-index: 1;
      margin-top: .5rem;
      left: 25vw;
    }

    

    .info-content {
      color: black;
      .exemplo {
        color: #375a3a;
        font-weight: bold;
      }
    }

    div.info-content h2 {
      color: #375a3a;
      border-bottom: #375a3a solid 0.15rem;
      margin-bottom: 1rem;
    }

    .info-content p {
      margin: 0;
      padding: 0;
      margin-bottom: 5px;
    }
  }

  @media screen and (min-width: 1441px) and (max-width: 2560px){
    .modal {
      width: 22rem !important;
    }
    .modal-1 {
      left: -.5rem;
      top: 5vh;

      &::after {
        content: "";

        width: 0;
        height: 0;

        position: absolute;

        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 20px solid #edf2ee;

        top: -4%;
        left: 0%;
      }
    }
    .modal-2 {
      left: -2.5rem;
      top: 5vh;

      &::after {
        content: "";

        width: 0;
        height: 0;

        position: absolute;

        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 20px solid #edf2ee;

        top: -4%;
        left: 11%;
      }
    }
    .modal-3 {
      left: -5.5rem;
      top: 5vh;

      &::after {
        content: "";

        width: 0;
        height: 0;

        position: absolute;

        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 20px solid #edf2ee;

        top: -4%;
        left: 25%;
      }
    }
    .modal-4 {
      left: -9rem;
      top: 5vh;

      &::after {
        content: "";

        width: 0;
        height: 0;

        position: absolute;

        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 20px solid #edf2ee;

        top: -4%;
        left: 50%;
      }
    }
    .info-tooltip {
      width: max-content;
      height: max-content;
      position: absolute;
      transform: translateX(-50%);
      background-color: #ffa900;
      padding: 1rem;
      border-radius: 4px;
      display: none;
      z-index: 1;
      margin-top: .5rem;
      left: 15vw;
    }

    

    .info-content {
      color: black;
      .exemplo {
        color: #375a3a;
        font-weight: bold;
      }
    }

    div.info-content h2 {
      color: #375a3a;
      border-bottom: #375a3a solid 0.15rem;
      margin-bottom: 1rem;
    }

    .info-content p {
      margin: 0;
      padding: 0;
      margin-bottom: 5px;
    }
  }

  .submit-busca-simples {
    cursor: pointer;
  }

  h1 {
    font-family: "Poppins-Bold";
    color: #2c3f2b;
    padding-left: 0.5rem;
    border-left: #ffa900 solid 0.15rem;
    margin: 2.5rem 0rem 1.5rem 1rem;
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .pesquise {
    background-color: #375a3a;
    padding: 0.5rem 1rem 1.5rem;
    label {
      color: white;
      font-size: 0.9rem;
    }
    input {
      border: none;
      width: calc(100% - 0.8rem);
      height: 2rem;
      border-radius: 0.2rem;
      border: none;
      padding-left: 0.5rem;
      font-family: "Poppins";
      font-size: 1rem;
      letter-spacing: 0.03rem;
      color: black;
    }
    form {
      .campo-preenchido {
        background-color: #fffed4;
        // border: solid .15rem #ffa900;
        // box-shadow: 0rem 0rem 0rem 0.13rem #ffa900;
        // -moz-box-shadow: 0rem 0rem 0rem 0.13rem #ffa900;
        // -webkit-box-shadow: 0rem 0rem 0rem 0.13rem #ffa900;
      }
    }

    .busca-simples {
      width: calc(100%);
      background-color: white;
      position: relative;
      border-radius: 0.3rem;
      input {
        width: calc(100% - 3rem) !important;
      }
      button {
        height: 100%;
        width: 2.3rem;
        background-color: #2c3f2b;
        border: none;
        margin-left: auto;
        position: absolute;
        top: 0rem;
        right: 0rem;
        border-radius: 0rem 0.2rem 0.2rem 0rem;
        img {
          height: 1.3rem;
        }
      }
    }
    .botao {
      button {
        background-color: transparent;
        border: none;
        font-size: 0.9rem;
        font-family: "Poppins";
        color: #ffa900;
        padding: 0rem;
        letter-spacing: 0.03rem;
        span {
          font-size: 0.9rem;
        }
      }
      button:focus {
        box-shadow: none;
        outline: none;
        cursor: pointer;
      }
    }
    .botoes {
      float: right;
      margin: 1rem 0.2rem 1rem auto;
      button {
        margin-left: 1rem;
        padding: 0.1rem 2rem;
        font-family: "Poppins-Medium";
        font-size: 0.9rem;
      }
      button:first-child {
        background: transparent;
        border: white solid 0.15rem;
        color: white;
        padding: 0.1rem 2rem;
        cursor: pointer;
      }
      button:last-child {
        background-color: #ffa900;
        color: #2c3f2b;
        border: solid 0.15rem #ffa900;
        cursor: pointer;
      }
    }

    select {
      width: calc(100% - 0.2rem);
      height: 2rem;
      border: none;
      border-radius: 0.2rem;
      padding-left: 0.5rem;
      background-color: white;
      font-family: "Poppins";
      font-size: 1rem;
      letter-spacing: 0.04rem;
    }

    h2 {
      color: white;
      font-family: "Poppins-ExtraLight";
      font-size: 1.1rem;
      display: inline-table;
      border-bottom: #ffa900 solid 0.15rem;
      margin: 0rem;
      padding-right: 1rem;
      width: fit-content;
    }
    .row2 {
      display: grid;
      .row2mobile {
        display: grid;
        grid-template-columns: auto auto;
        gap: 1rem;
      }
    }
    .grupo {
      border: #ffa900 solid 0.15rem;
      margin: 0.5rem 0rem;
      padding: 0.5rem 0.5rem;
      border-radius: 0.5rem;
      .data {
        display: grid;
        grid-template-columns: auto auto;
        gap: 1rem;
        .mx-datepicker {
          width: 100%;
        }
      }
      p {
        margin: 0rem;
        padding: 0rem;
        color: white;
      }
    }
    .item {
      display: grid;
      margin: 0.5rem 0rem;
      height: fit-content;
    }
    .seta {
      img {
        width: 5rem;
        position: absolute;
        right: 1.5rem;
        margin-top: 0rem;
      }
      .t-preto {
        display: none;
      }
    }
  }
  .campo-resultados {
    margin: 1rem;
    h2 {
      color: #2c3f2b;
      font-family: "Poppins-ExtraLight";
      font-size: 1.1rem;
      border-bottom: #ffa900 0.15rem solid;
      margin: 1.5rem 0rem 0rem 1rem;
      display: table;
      padding-right: 1rem;
    }
    .dados-abertos {
      padding-left: 0.5rem;
      width: 15rem;
      margin: 1rem 1rem 0rem 0rem;
      p,
      span {
        margin: 0rem;
        font-size: 0.9rem;
        font-family: "Poppins-ExtraLight";
        a {
          margin: 0rem 0.35rem 0rem 1rem;
          text-decoration: none;
          color: #2c3f2b;
          font-family: "Poppins-Medium";
          cursor: pointer;
        }
      }
      p {
        background-color: #edf2ee;
        padding-left: 0.5rem;
        height: 1.3rem;
      }
    }
    .nav-resultados {
      margin-bottom: 2rem;
      display: grid;

      .first-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 1rem 0;

        h2 {
          margin: 0;
        }

        .dados-abertos {
          padding-left: 0;
          margin: 0;
        }
      }

      .second-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: .3rem 0;

        .ordenacao {
          margin: 0;
          display: flex;
          align-items: center;
        }
      }

      .third-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 1rem 0;

        .nresultados {
          margin: 0;
          width: fit-content;
          height: fit-content;

          p {
            display: block;
            width: fit-content;
            height: fit-content;
            font-size: 0.9rem;
            margin: 0rem;
            strong {
              font-family: "Poppins-Medium";
            }
          }
        }

        .npagina {
          margin: 0;

          label {
            font-family: "Poppins-Medium";
            font-weight: 900;
            margin-right: 0.5rem;
            color: #2c3f2b;
            font-size: 0.9rem;
          }
          select {
            border: none;
            background-color: #edf2ee;
            font-size: 0.9rem;
            margin-top: 0.5rem;
          }
        }
      }
    }
  }

  @media screen and (min-width: 31rem) {
    .nav-resultados {
      margin: 0 .5rem 2rem .5rem;
      display: grid;

      .first-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 1rem 0;

        h2 {
          margin: 0;
        }

        .dados-abertos {
          padding-left: 0;
          margin: 0;
        }
      }

      .second-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: .3rem 1rem;

        .ordenacao {
          margin: 0;
          display: flex;
          align-items: center;
        }
      }

      .third-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: .3rem 1rem;

        .nresultados {
          margin: 0;

          p {
            font-size: 0.9rem;
            margin: 0rem 0rem -2rem 1rem;
            strong {
              font-family: "Poppins-Medium";
            }
          }
        }

        .npagina {
          margin: 0;

          label {
            font-family: "Poppins-Medium";
            font-weight: 900;
            margin-right: 0.5rem;
            color: #2c3f2b;
            font-size: 0.9rem;
          }
          select {
            border: none;
            background-color: #edf2ee;
            font-size: 0.9rem;
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
  @media screen and (min-width: 64rem) {
    h1 {
      margin-left: 0rem;
      font-size: 1.8rem;
    }
    h2 {
      font-size: 1.3rem !important;
    }

    .pesquise {
      padding-bottom: 2rem !important;

      h2 {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
      .label-busca {
        text-align: left;
        margin-bottom: 0.3rem;
      }
      .row2 {
        grid-template-columns: calc(50% - 1%) calc(50% - 1%);
        gap: 2%;
        .item {
          input {
            padding-right: 0.25rem;
          }
          .gridbox-1 {
            display: grid;
          }
        }
      }
      .grupo {
        padding: 0.5rem 2rem;
      }
      .botoes {
        margin-right: 0rem;
        margin-top: 0.5rem;
        button {
          font-size: 1rem;
          padding: 0.1rem 4rem !important;
          border-radius: 0.2rem;
        }
      }
      .seta {
        img {
          right: 4.7rem;
          margin-top: 0.5rem;
        }
      }
    }
    .campo-resultados {
      h2 {
        margin-left: 0rem;
      }
      .dados-abertos {
        padding-left: 0.5rem;
        width: 13rem;
        margin: -2rem 1rem 0rem auto;
        text-align: right;
        p,
        span {
          font-size: 0.8rem;
        }
      }
      .npagina {
        text-align: right;
        label {
          font-size: 0.8rem;
        }
      }
      .nresultados {
        max-width: 17rem;
        text-align: left;
        margin: 0rem 0rem 2rem;
        p {
          font-size: 0.8rem;
          margin: -1.5rem 0rem 0rem 0rem;
          text-align: left;
          strong {
            font-family: "Poppins-Medium";
          }
        }
      }
    }
  }
  .alto-contraste {
    .pesquise {
      background-color: #213b27;
      .seta {
        .t-branco {
          display: none;
        }
        .t-preto {
          display: block;
        }
      }

      .botoes {
        button:last-child {
          background-color: #ffa900;
          color: #0d2b10;
          font-family: "Poppins-Medium";
        }
      }
    }
    .nav-resultados {
      h2 {
        color: white;
      }
    }
    .npagina {
      label {
        color: white !important;
      }
    }
    .nresultados {
      color: white;
    }
  }

  /* Estilos para o campo de ordenação */
  .ordenacao {
    margin: 1rem 0;
    display: flex;
    align-items: center;
  }

  .ordenacao label {
    color: #2c3f2b;
    font-size: 0.9rem;
    font-family: "Poppins-Medium";
    margin-right: 0.5rem;
  }

  .ordenacao select {
    height: 1.5rem;
    border: none;
    border-radius: 0.2rem;
    padding: 0 0.5rem;
    background-color: #ffffff;
    font-family: "Poppins";
    font-size: 1rem;
    color: #2c3f2b;
    cursor: pointer;
    box-shadow: 0 0 0 0.15rem #ffa900;
    transition: background-color 0.3s, box-shadow 0.3s;
    appearance: none;
    background-image: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMmMzZjJiIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI2Ij48cGF0aCBkPSJNNSA2IDAgMGgxMGwtNSA2eiIvPjwvc3ZnPg==');
    background-repeat: no-repeat;
    background-position: right 0.5rem center;
    background-size: 0.65rem;
    padding-right: 1.5rem;
  }

  .ordenacao select:hover {
    background-color: #edf2ee;
  }

  .ordenacao select:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem #ffa900;
  }

  @media screen and (max-width: 425px) {
    .ordenacao {
      flex-direction: column;
      align-items: flex-start;
    }
    .ordenacao label {
      margin-bottom: 0.8rem;
    }
    .ordenacao select {
      width: 100%;
    }
  }

  /* Estilos para o modo alto contraste */
  .alto-contraste .ordenacao label,
  .alto-contraste .ordenacao select {
    color: #ffffff;
  }

  .alto-contraste .ordenacao select {
    background-color: #2c3f2b;
    box-shadow: 0 0 0 0.15rem #ffffff;
  }

  .alto-contraste .ordenacao select:hover {
    background-color: #375a3a;
  }
</style>