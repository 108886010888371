var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.atoInfralegal.ementa)?_c('div',[_c('p',[_c('strong',[_vm._v("Ementa:")]),_vm._v(" "+_vm._s(_vm.atoInfralegal.ementa)+" ")])]):_vm._e(),(_vm.atoInfralegal.numero_processo_sei)?_c('div',[_c('p',[_c('strong',[_vm._v("Número processo SEI:")]),_vm._v(" "+_vm._s(_vm.atoInfralegal.numero_processo_sei)+" ")])]):_vm._e(),(
      _vm.atoInfralegal.categorias_ato_infralegal &&
      _vm.atoInfralegal.categorias_ato_infralegal.length > 0
    )?_c('div',[_c('p',[_c('strong',[_vm._v("Categoria(s):")]),_vm._v(" "+_vm._s(_vm.categorias)+" ")])]):_vm._e(),(_vm.atoInfralegal.orgaos && _vm.atoInfralegal.orgaos.length > 0)?_c('div',[_c('p',[_c('strong',[_vm._v("Órgão(s):")]),_vm._v(" "+_vm._s(_vm.orgaos)+" ")])]):_vm._e(),(_vm.atoInfralegal.legislacoes && _vm.atoInfralegal.legislacoes.length > 0)?_c('div',[_vm._m(0),_c('ul',_vm._l((_vm.atoInfralegal.legislacoes),function(leiRelacionada,index){return _c('li',{key:`${leiRelacionada.id}${leiRelacionada.numero}${index}`},[_c('router-link',{attrs:{"to":{
            name: 'PesquisaLegislacaoShow',
            params: { id: leiRelacionada.id },
          },"target":"_blank"}},[_vm._v(_vm._s(_vm.detalhesLei(leiRelacionada)))])],1)}),0)]):_vm._e(),(
      _vm.atoInfralegal.atos_infralegais_relacionados &&
      _vm.atoInfralegal.atos_infralegais_relacionados.length > 0
    )?_c('div',[_vm._m(1),_c('ul',_vm._l((_vm.atoInfralegal.atos_infralegais_relacionados),function(atoInfralegalRelacionado,index){return _c('li',{key:`${atoInfralegalRelacionado.id}${atoInfralegalRelacionado.numero}${index}`},[_c('router-link',{attrs:{"to":{
            name: 'PesquisaAtoInfralegalShow',
            params: { id: atoInfralegalRelacionado.id },
          },"target":"_blank"}},[_vm._v(_vm._s(_vm.detalhesAtoInfralegal(atoInfralegalRelacionado)))])],1)}),0)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('strong',[_vm._v("Legislação(ões) Relacionada(s):")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('strong',[_vm._v("Ato(s) Infralegal(is) relacionado(s):")])])
}]

export { render, staticRenderFns }