<template>
  <div class="resultados">
    <table>
      <thead>
        <tr>
          <th>Ato Infralegal</th>
          <th>Data do Ato Infralegal</th>
          <th>Situação</th>
          <th>Texto</th>
          <th>Órgão de Origem</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(atoInfralegal, index) in atosInfralegais">
          <tr :key="atoInfralegal.id">
            <td class="topo-card">
              <router-link
                :to="{
                  name: 'PesquisaAtoInfralegalShow',
                  params: { id: atoInfralegal.id },
                }"
                target="_blank"
              >
                <span>{{ tipoAtoInfralegal(atoInfralegal) }}</span>
                <br />
                <span>{{ nomeAtoInfralegal(atoInfralegal) }}</span>
              </router-link>
            </td>
            <td data-label="Data do Ato Infralegal: ">
              {{ atoInfralegal.data }}
            </td>
            <td data-label="Situação">
              {{
                atoInfralegal.situacao_ato_infralegal
                  ? atoInfralegal.situacao_ato_infralegal
                  : ""
              }}
            </td>
            <td data-label="Texto:">
              <router-link
                :to="{
                  name: 'PesquisaAtoInfralegalShow',
                  params: { id: atoInfralegal.id },
                }"
                target="_blank"
              >
                <img
                  src="../../assets/images/icon_text.svg"
                  class="icon-txt t-preto"
                  alt="Acessar Texto"
                  title="Acessar texto"
                />
                <img
                  src="../../assets/images/icon_text_bco.svg"
                  class="icon-txt t-branco"
                  alt="Acessar Texto"
                  title="Acessar texto"
                />
              </router-link>
              <a @click="registra_GA4(atoInfralegal)" :href="urlPdf(atoInfralegal)" target="_blank">
                <img
                  src="../../assets/images/icon_pdf.svg"
                  class="icon-pdf"
                  alt="Abrir texto em pdf"
                  title="Abrir texto em PDF"
                />
              </a>
            </td>
            <td data-label="Órgão Relacionado:">
              {{ atoInfralegal.orgao_relacionado_sigla }}
            </td>
            <td class="botao">
              <button v-if="exibirBotao(index)" @click="exibir(index)">
                {{ exibirMais[index] ? "ocultar" : "ver mais" }}
              </button>
            </td>
          </tr>
          <tr
            :key="`${atoInfralegal.id}mais`"
            v-show="exibirMais[index]"
            class="exibir-mais"
          >
            <td colspan="7">
              <DetalhesAtoInfralegal
                :atoInfralegal="atoInfralegal"
              ></DetalhesAtoInfralegal>
            </td>
          </tr>
        </template>
        <tr
          v-if="!atosInfralegais || atosInfralegais.length == 0"
          class="sem-resultados"
        >
          <td colspan="7">Nenhum resultado encontrado</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import DetalhesAtoInfralegal from "./DetalhesAtoInfralegal";
import registraEventoGoogleAnalytics from "../../helpers/registraEventoGoogleAnalytics";

export default {
  name: "TabelaResultadosAtoInfralegal",
  props: {
    atosInfralegais: Array,
  },
  data() {
    return {
      exibirMais: {},
    };
  },
  components: {
    DetalhesAtoInfralegal,
  },
  watch: {
    atosInfralegais: function () {
      this.exibirMais = {};
    },
  },
  methods: {
    exibir: function (index) {
      this.$set(this.exibirMais, index, !this.exibirMais[index]);
    },
    exibirBotao: function (index) {
      const atoInfralegal = this.atosInfralegais[index];
      return (
        atoInfralegal.numero_processo_sei ||
        (atoInfralegal.categorias_ato_infralegal &&
          atoInfralegal.categorias_ato_infralegal.length) ||
        (atoInfralegal.orgaos && atoInfralegal.orgaos.length > 0) ||
        (atoInfralegal.legislacoes && atoInfralegal.legislacoes.length > 0) ||
        (atoInfralegal.atos_infralegais_relacionados &&
          atoInfralegal.atos_infralegais_relacionados.length > 0)
      );
    },
    tipoAtoInfralegal: function (atoInfralegal) {
      if (atoInfralegal && atoInfralegal.tipo_ato_infralegal) {
        return atoInfralegal.tipo_ato_infralegal;
      }
      return "";
    },
    nomeAtoInfralegal: function (atoInfralegal) {
      if (atoInfralegal) {
        return `Nº ${atoInfralegal.numero} / ${atoInfralegal.ano}`;
      }
      return "";
    },
    descricaoDiario: function (diario) {
      if (diario) {
        let result = "";
        if (diario.numero) {
          result += `Nº ${diario.numero}`;
        }
        if (diario.data_diario) {
          if (result.length > 0) {
            result += " - ";
          }
          result += `${diario.data_diario}`;
        }
        return result;
      }
      return "";
    },
    urlPdf: function (atoInfralegal) {
      return `${process.env.VUE_APP_BASE_API_URL}/api/v1/pesquisa/atos_infralegais/${atoInfralegal.id}/pdf`;
    },
    registra_GA4(atoInfralegal) {
      registraEventoGoogleAnalytics(
        this.$gtag,
        'visualizacao_pdf_infralegal',
        'download_pdf_infralegal',
        'PDF - ' + this.tipoAtoInfralegal(atoInfralegal) + ' ' + this.nomeAtoInfralegal(atoInfralegal)
      )
    }
  },
};
</script>

<style lang="scss" scoped>
.resultados {
  table {
    border-collapse: collapse;
    width: 100%;
    a {
      cursor: pointer;
      color: #375a3a;
    }
    thead {
      border-bottom: solid #ffa900 0.15rem;
      color: #0a4a22;
      th {
        padding: 0rem 2rem;
      }
    }
    tbody {
      tr {
        margin: 1rem;
        td {
          padding: 0.5rem;
        }
        td:nth-child(1) {
          min-width: 12rem;
        }
        td:nth-child(2) {
          text-align: center;
        }
        td:nth-child(3) {
          text-align: center;
        }
        td:nth-child(4) {
          text-align: center;
        }
        td:nth-child(5) {
          text-align: center;
          max-width: 50rem;
        }
      }
      tr:nth-child(4n + 1),
      tr:nth-child(4n + 2) {
        background-color: #edf2ee;
      }
      .sem-resultados {
        background-color: transparent !important;
        text-align: center;
        height: 5rem;
        color: rgb(73, 73, 73);
        font-style: oblique;
      }
    }
    .botao {
      width: 7rem;
      padding-bottom: 1rem;
      text-align: center;
      margin-top: 1rem;
      button {
        background-color: transparent;
        font-family: "Poppins";
        color: #0a4a22;
        border: #0a4a22 solid 0.15rem;
        border-radius: 0.2rem;
        padding: 0rem 1rem;
        font-size: 1rem;
        cursor: pointer;
      }
    }
  }
  td {
    img {
      height: 1.5rem;
      margin: 0rem 0.5rem 0rem 0.5rem;
    }
    .t-branco {
      display: none;
    }
  }
}
.alto-contraste {
  table {
    thead {
      th {
        color: #ffa900;
      }
    }
    tbody {
      tr {
        margin: 1rem;
        td {
          color: white;
        }
      }
      tr:nth-child(4n + 1),
      tr:nth-child(4n + 2) {
        background-color: #202020;
      }
      .sem-resultados {
        color: white !important;
      }
      a {
        color: rgb(0, 169, 211);
      }
    }
    .botao {
      button {
        color: #ffa900;
        border: #ffa900 solid 0.15rem;
      }
    }
  }
  td {
    .t-preto {
      display: none;
    }
    .t-branco {
      display: inline;
    }
  }
}
@media screen and (max-width: 64rem) {
  .resultados {
    table {
      width: calc(100% - 2rem);
      tbody {
        background-color: transparent;
      }
      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      tr {
        margin: 1rem;
        display: block;
        margin-bottom: 1em;
        background-color: #edf2ee;
        padding-bottom: 1rem;
        width: 100% !important;
        td {
          padding: 0rem !important;
          min-height: 1.94rem;
        }
        td:nth-child(2) {
          text-align: left !important;
        }
        td:nth-child(3) {
          text-align: left !important;
        }
        td:nth-child(4) {
          text-align: left !important;
        }
        td:nth-child(5) {
          text-align: left !important;
          max-width: 50rem;
        }
        .acao {
          display: none;
        }
      }
      tr:nth-child(even) td {
        display: block;
        margin-bottom: 0.5em;
        background-color: transparent !important;
      }
      .sem-resultados {
        padding-top: 5rem;
      }
      td {
        display: block;
        min-height: 1.3rem;
        margin: 0.3rem 0.5rem;
        font-size: 0.9rem;
        img {
          height: 1.5rem;
          margin: 0rem 1rem;
        }
      }

      td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        color: #0a4a22;
        font-family: "Poppins-Medium";
        margin-right: 0.5rem;
      }
      .topo-card {
        background-color: #375a3a;
        font-family: "Poppins";
        text-align: center;
        margin: 0rem 0rem 1rem 0rem;
        font-weight: 600 !important;
        padding: 0.5rem 0rem !important;
        font-size: 0.9rem;
        a {
          color: white;
          text-decoration: none;
        }
      }
      .botao {
        padding-bottom: 1rem;
        text-align: center;
        margin-top: 1rem;
        margin-left: -0.25rem;
        width: 100%;
        button {
          background-color: transparent;
          font-family: "Poppins";
          color: #0a4a22;
          border: #0a4a22 solid 0.1rem;
          border-radius: 0.2rem;
          padding: 0.1rem 1.5rem;
          font-size: 0.9rem;
        }
      }
      .exibir-mais {
        margin-top: -1rem;
        color: black;
        text-align: left;
      }
    }
  }
  .alto-contraste {
    .resultados {
      table {
        tr {
          background-color: #202020;
        }
        td::before {
          color: #c2c2c2;
        }
        .botao {
          button {
            color: #ffa900;
            border: #ffa900 solid 0.15rem;
          }
        }
      }
    }
  }
}
</style>
