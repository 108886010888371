<template>
  <div class="tamanho">
    <a class="menor" title="Diminuir fonte" @click="diminuirFonte">A-</a>
    <a class="medio" title="Fonte Média" @click="restaurarFonte">A</a>
    <a class="maior" title="Aumentar Fonte" @click="aumentarFonte">A+</a>
    <div class="contraste" @click="aplicarContraste">
      <img
        src="../assets/images/contraste1.png"
        alt="Ativar modo noturno"
        title="Ativar modo noturno"
        v-show="!altoContraste"
      />
      <img
        src="../assets/images/contraste2.png"
        alt="Desativar modo noturno"
        title="Desativar modo noturno"
        v-show="altoContraste"
      />
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "Acessibilidade",
  data() {
    return {
      alteracaoFonte: 0,
      altoContraste: false,
    };
  },
  mounted() {
    // aciona e retira autocontraste
    if (localStorage.getItem("autocontraste")) {
      $("body").addClass("alto-contraste");
      this.altoContraste = true;
    } else {
      $("body").removeClass("alto-contraste");
      this.altoContraste = false;
    }

    let alteracaoFonte = localStorage.getItem("tamanhoFonte");
    if (alteracaoFonte) {
      alteracaoFonte = parseInt(alteracaoFonte);
    } else {
      alteracaoFonte = 0;
    }

    this.alteracaoFonte = alteracaoFonte;

    this.aplicarFormatacao(
      parseInt($("body").css("font-size")) + this.alteracaoFonte
    );
  },
  methods: {
    aplicarContraste() {
      $("body").toggleClass("alto-contraste");
      this.armazenarContraste();
    },
    aumentarFonte() {
      if (this.alteracaoFonte < 4) {
        this.alteracaoFonte = this.alteracaoFonte + 1;

        this.armazenarAlteracaoFonte(this.alteracaoFonte);
        this.aplicarFormatacao(
          parseInt($("body").css("font-size")) + 1
        );
      }
    },
    diminuirFonte() {
      if (this.alteracaoFonte > -4) {
        this.alteracaoFonte = this.alteracaoFonte - 1;

        this.armazenarAlteracaoFonte(this.alteracaoFonte);
        this.aplicarFormatacao(
          parseInt($("body").css("font-size")) - 1
        );
      }
    },
    restaurarFonte() {
      this.alteracaoFonte = 0;
      $("html").css("font-size", "100%");
      const xsize = parseInt($("body").css("font-size"));
      this.armazenarAlteracaoFonte(this.alteracaoFonte);
      this.aplicarFormatacao(xsize);
    },
    aplicarFormatacao(tamanhoFonte) {
      $("html").css("font-size", tamanhoFonte + "px");
    },
    armazenarAlteracaoFonte(tamanho) {
      localStorage.setItem("tamanhoFonte", tamanho.toString());
    },
    armazenarContraste() {
      if (localStorage.getItem("autocontraste")) {
        localStorage.removeItem("autocontraste");
        this.altoContraste = false;
      } else {
        this.altoContraste = true;
        localStorage.setItem("autocontraste", "true");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tamanho {
  display: flex;
  margin-top: 4rem !important;
  position: absolute;
  right: 0.5rem;
  z-index: 10;
  a {
    margin: 0rem 0.3rem;
    font-family: "Poppins-Medium";
    color: #375a3a;
    cursor: pointer;
  }
  .contraste {
    img {
      height: 1.3rem;
      cursor: pointer;
    }
  }
}

@media screen and (min-width: 64rem) {
  .tamanho {
    margin-top: 1.5rem !important;
    right: 3rem;
  }
}
.alto-contraste{
  a{
    color: white !important;
  }
}
</style>
