<template>
  <div>
    <BotaoEditar
      v-if="usuarioEstaLogado()"
      :url="
        `https://admin.legisla.casacivil.go.gov.br/mensagens_veto/${mensagemVeto.id}/edit`
      "
    ></BotaoEditar>
    <div v-html="mensagemVeto.conteudo" v-if="mensagemVeto.layout_antigo"></div>
    <div class="pagina-exibir-veto" id="exibir_mensagem_veto_layout_novo" v-else>
      <div class="folha">
        <header>
          <img src="../../assets/images/brasao-de-goias.svg" class="Brasao" />
          <h1>
            Estado de Goiás
            <br />Secretaria de Estado da Casa Civil
          </h1>
        </header>
        <div v-html="mensagemVeto.conteudo"></div>

        <div class="links">
          <router-link
            :to="{
              name: 'PesquisaLegislacaoShow',
              params: { id: mensagemVeto.legislacao.id },
            }"
            v-if="mensagemVeto.legislacao"
            target="_blank"
            class="botao-conteudo"
          >
            Acessar conteudo da lei
            <span>{{ titleLegislacao(mensagemVeto.legislacao) }}</span>
          </router-link>
        </div>
      </div>

      <div class="botoes" id="botoes">
        <div class="botao">
          <a href="https://legisla.casacivil.go.gov.br/pesquisa_mensagem_veto" target="_blank">
            <img
              src="../../assets/images/lupa-bco.svg"
              class="lupa"
              title="Pesquisar Mensagens de Veto"
            />
            Pesquisar mensagens de veto
          </a>
        </div>
        <share-network
          network="whatsapp"
          :url="url"
          :title="titulo"
          :description="mensagemVeto.ementa"
        >
          <img
            src="../../assets/images/icon-whatsapp.png"
            class="icone-whatsapp"
            title="Compartilhar no Whatsapp"
          />
        </share-network>
        <share-network
          network="facebook"
          :url="url"
          :title="titulo"
          :description="mensagemVeto.ementa"
          hashtags="legisla,governo-de-goias"
        >
          <img
            src="../../assets/images/icon-facebook.png"
            class="icone-facebook"
            title="Compartilhar no Facebook"
          />
        </share-network>
        <share-network
          network="twitter"
          :url="url"
          :title="titulo"
          hashtags="legisla,governo-de-goias"
        >
          <img
            src="../../assets/images/icon-twitter.png"
            class="icone-twitter"
            title="Compartilhar no Twitter"
          />
        </share-network>
      </div>
    </div>
  </div>
</template>

<script>
import titleLegislacao from "../../helpers/legislacao/titleLegislacao";
import usuarioEstaLogado from "../../helpers/usuarioEstaLogado";
import BotaoEditar from "../BotaoEditar";

export default {
  name: "ExibirMensagemVeto",
  props: {
    mensagemVeto: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      titulo: `Ofício n° ${this.mensagemVeto.numero}/${this.mensagemVeto.ano}`,
      url: window.location.href
    };
  },
  components: {
    BotaoEditar
  },
  methods: {
    titleLegislacao,
    usuarioEstaLogado,
    alteraTargetLinks: function () {
      var links = document.getElementsByTagName("a");
      for (var i = 0, l = links.length; i < l; i++) {
        links[i].target = "_blank";
      }    
    }
  },
  mounted(){
    this.alteraTargetLinks();
  },
};
</script>

<style lang="scss">
.pagina-exibir-veto {
  font-size: 16px !important;
  font-family: "Calibri";
  background-color: silver;
  min-height: 100vh;
  overflow-x: hidden;

  * {
    font-size: 16px !important;
    font-family: "Calibri";
  }

  .folha {
    padding: 4rem 0.5rem 4rem;
    background-color: white;
    max-width: 50rem;
    margin: 1rem auto;
    min-height: 100vh;
    position: relative;

    p {
      text-align: justify;
      margin-right: 3.5em;
      font-family: "Calibri";
    }

    header {
      text-transform: uppercase;
      text-align: center;

      img {
        height: 7rem;
      }

      h1 {
        font-size: 0.67rem !important;
        font-weight: normal;
        text-transform: uppercase;
      }
    }

    .botao-conteudo {
      background-color: #4caf50;
      text-decoration: none;
      color: white;
      font-family: "Calibri-Bold";
      padding: 0.5rem 1rem;
      border-radius: 0.2rem;
      font-size: 1rem;
      position: absolute;
      left: 1rem;
      top: 1rem;
      text-align: center;

      span {
        display: block;
        font-size: 0.8rem;
      }
    }

    .acessibilidade {
      position: absolute;
      display: flex;
      align-items: center;
      right: 1rem;
      top: 1rem;

      p {
        margin: 0.5rem;
        cursor: pointer;
      }

      .contraste-preto {
        margin-left: 0.5rem;
        height: 1.3rem;
        cursor: pointer;
      }

      .contraste-branco {
        display: none;
        height: 1.3rem;
        cursor: pointer;
        width: 1.7rem !important;
      }
    }

    .botao-conteudo:hover {
      background-color: #357937;
    }

    .expediente {
      text-align: left;
      margin: 2rem 3.6rem 2rem 7.1em;
      text-transform: uppercase;
    }

    .data {
      text-align: right;
      margin: 2rem 3.6rem 2rem 7.1em;
    }

    .enderecamento {
      text-align: left;
      margin: 2rem 3.6rem 2rem 7.1em;

      p {
        margin: 0rem;
      }
    }

    .assunto {
      font-family: "Calibri-Bold";
      margin: 2rem 3.6rem 2rem 7.1em;
    }

    .vocativo {
      text-indent: 4.74rem;
      margin: 2rem 3.6rem 2rem 7.1em;
    }

    .referencia {
      margin: 0.5rem 3.6rem 0.5rem 7.1em;
      text-indent: 4.74rem;
    }

    .paragrafo {
      margin: 0.5rem 3.6rem 0.5rem 7.1em;
      text-indent: 4.74rem;

      span {
        margin-left: -4.74rem;
        margin-right: 4.74rem;
      }
    }

    .recuo-simples {
      text-align: justify;
      margin: 0.5rem 3.6rem 0.5rem 16.7em;
      font-size: 0.916rem;
      text-indent: 2.37rem;
    }

    .saudacao {
      margin: 2rem 3.6rem 2rem 7.1em;
      text-indent: 4.74rem;
    }

    .recuo-duplo {
      text-align: justify;
      margin: 0.5rem 3.6rem 0.5rem 19.2em;
      font-size: 0.916rem;
      text-indent: 2.37rem;
    }

    img {
      max-width: calc(100% - 6rem);
    }

    .baixar-anexo {
      text-align: center;
      margin-bottom: 1rem;

      a {
        width: 15rem;
        text-decoration: none;
        color: #49a054;
      }

      img {
        width: 15rem;
        position: relative;
      }

      p {
        font-size: 0.5rem;
        text-indent: 0rem;
        left: 50%;
        transform: translateX(-75%);
        position: absolute;
        margin-top: -3rem;
      }
    }

    table {
      //table-layout: fixed;
      border-collapse: collapse;

      .texto-centralizado {
        text-align: center;
      }

      p {
        text-indent: 0rem;
        margin: 0rem;
      }

      table,
      th,
      td {
        border: solid 0.03rem black;
      }

      th,
      td {
        padding: 0.25rem;
      }

      td:first-child {
        text-transform: uppercase;
        padding: 0.25rem 1rem;
      }

      th {
        background-color: #efefef;
      }
    }

    .nota-rodape {
      font-size: 0.8rem !important;
      text-indent: 0rem;
      margin-left: 11.5rem;
      margin-right: 4rem;
    }
  }

  .botoes {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 3.5rem;
    right: 1rem;
    margin: 1rem auto;
    align-items: flex-end;

    .botao {
      a {
        background-color: #45b053;
        align-content: center;
        padding: 0.25rem 1rem;
        border-radius: 1rem;
        text-decoration: none;
        color: white;
        font-family: "Calibri-Bold";

        img {
          margin-bottom: -0.2rem;
          height: 1rem;
        }
      }
    }

    img {
      height: 2rem;
      transition: 0.3s ease-in-out;
      margin-left: 0.5rem;
      margin-top: 0.5rem;
    }
  }

  footer {
    .assinatura {
      text-transform: uppercase;
      text-align: center;
      margin: 0rem 3.6rem 0rem 7.1em;
    }

    .cargo {
      text-align: center;
      margin: 0rem 3.6rem 2rem 7.1em;
    }
  }

  table {
    width: 80%;
    margin: 0 auto;
    max-width: 48rem;
  }
}

@media screen and (max-width: "50em") {
  .pagina-exibir-veto {
    .folha {
      margin: 0rem auto;
      padding: 8rem 0.5rem;

      p {
        margin-right: 1rem;
      }

      .expediente {
        margin: 2rem 1rem 2rem 1rem;
      }

      .data {
        margin: 2rem 1rem 2rem 1rem;
      }

      .enderecamento {
        margin: 2rem 1rem 2rem 1rem;
      }

      .assunto {
        margin: 2rem 1rem 2rem 1rem;
      }

      .vocativo {
        margin: 2rem 1rem 2rem 1rem;
        text-indent: 2rem;
      }

      .referencia {
        margin: 0.5rem 1rem 0.5rem 1rem;
        text-indent: 2rem;
      }

      .paragrafo {
        margin: 0.5rem 1rem 0.5rem 1rem;
        text-indent: 2rem;

        span {
          margin-left: -2rem;
          margin-right: 2rem;
        }
      }

      .recuo-simples {
        margin: 0.5rem 1rem 0.5rem 4rem;
        text-indent: 2rem;
      }

      .recuo-duplo {
        margin: 0.5rem 1rem 0.5rem 6rem;
        text-indent: 2rem;
      }

      .saudacao {
        margin: 2rem 1rem 2rem 1em;
        text-indent: 2rem;
      }

      footer {
        .assinatura {
          margin: 0rem 1em 0rem 1em;
        }

        .cargo {
          margin: 0rem 1em 2rem 1em;
        }
      }
      .nota-rodape {
        font-size: 0.8rem !important;
        text-indent: 0rem;
        margin-left: 3rem;
        margin-right: 1rem;
      }
    }

    table {
      width: 80%;
      margin: 0 auto;
      max-width: 48rem;
    }
  }
}
</style>
