<template>
  <div class="grupo">
    <h2>Consultas Rápidas</h2>
    <div class="cards">
      <router-link :to="{ name: 'PorTipoAtoInfralegal' }">
        <img
          src="../../assets/images/icon-tipo-legislacao.png"
          class="legisla-icon"
          alt=" "
        />
        <div class="texto">
          <h3>Tipo de <br />Ato Infralegal</h3>
          <p>Consulte o Ato Infralegal por tipo</p>
        </div>
      </router-link>
      <router-link :to="{ name: 'PorOrgaoAtoInfralegal' }">
        <img
          src="../../assets/images/orgao-atos-icon-min.png"
          class="orgao-icon"
          alt=" "
        />
        <div class="texto">
          <h3>Órgão</h3>
          <p>Consulte o Ato Infralegal por órgão</p>
        </div>
      </router-link>
      <router-link :to="{ name: 'PorAnoAtoInfralegal' }">
        <img
          src="../../assets/images/icon-indice-cronologico.png"
          class="indice-icon"
          alt=" "
        />
        <div class="texto">
          <h3>
            Índice <br />
            Cronológico
          </h3>
          <p>Consulte o Ato Infralegal por ano</p>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "BuscaSimplesCards",
};
</script>

<style lang="scss" scoped>
h2 {
  color: #2c3f2b;
  font-family: "Poppins-ExtraLight";
  font-size: 1.1rem;
  display: table;
  padding-right: 1rem;
  border-bottom: #ffa900 solid 0.15rem;
  margin: 0rem 0rem 1rem 0.5rem;
}
.cards {
  display: grid;
  grid-template-columns: 1fr;
  padding: 0.5rem;
  overflow: auto;
  gap: 0.5rem;
  margin-bottom: 1rem;
}
a {
  display: flex;
  overflow: auto;
  transition: 0.3s linear;
  align-items: center;
  text-decoration: none;
  background-color: #edf2ee;
  padding: 1rem 0.5rem;
  max-width: calc(100%);
  //min-height: 5.25rem;
  cursor: pointer;
  img {
    width: 2.5rem;
    transition: 0.3s ease-in-out;
  }
  .texto {
    margin-left: 1rem;
  }
  br {
    display: none;
  }
  h3 {
    text-transform: uppercase;
    font-size: 1.1rem;
    font-family: "Poppins-ExtraLight";
    margin: 0rem;
    font-weight: 800;
    color: #2c3f2b;
  }
  p {
    font-size: 0.9rem;
    margin: 0rem;
    color: #5f6d5e;
  }
}
a:hover {
  background-color: #dde6df;
  img {
    //width: 3rem;
  }
}
.alto-contraste {
  .cards {
    a {
      background-color: #202020;
      h3 {
        color: #edf2ee;
      }
    }
  }
}

@media screen and (min-width: "25em") {
  .cards {
    grid-template-columns: 1fr 1fr;
    a {
      padding-left: 1rem;
      img {
        width: 2.5rem;
      }
      h3 {
        font-size: 0.9rem;
      }
      p {
        font-size: 0.7rem;
      }
    }
  }
}
@media screen and (min-width: "64em") {
  .grupo {
    h2 {
      margin-left: 0rem;
      font-size: 1.3rem;
    }
  }
  .cards {
    a {
      img {
        width: 4rem;
      }
      h3 {
        font-size: 1.1rem;
      }
      p {
        font-size: 1rem;
      }
    }
    a:hover {
      img {
        //width: 5rem;
      }
    }
  }
}
@media screen and (min-width: "80em") {
  .grupo {
    h2 {
      margin-left: 0rem;
      font-size: 1.3rem;
    }
  }
  .cards {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0rem;
    margin-bottom: 2rem;
    a {
      p {
        font-size: 0.9rem;
      }
    }
  }
}
</style>