import axios from "axios";
import axiosInstance from "./axiosInstance";

class CategoriaAtoInfralegalApi {
  cancelTokens = {
    getCategoriasAtoInfralegal: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getCategoriasAtoInfralegal() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getCategoriasAtoInfralegal = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/categorias_ato_infralegal`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getCategoriasAtoInfralegal = null;
    return data;
  }
}

export default CategoriaAtoInfralegalApi;
