<template>
  <div class="tipo">
    <div class="centro">
      <h1>Consulta por Ordem Cronológica</h1>
      <BuscaSimplesCards />
      <Loading v-if="carregando" />
      <ErroResultados v-if="!carregando && erro" />
      <PainelAnos
        v-if="!carregando && !erro"
        :anos="anos"
        :generateUrl="generateUrl"
      />
    </div>
  </div>
</template>

<script>
import ProjetoGovernadorApi from "../../services/ProjetoGovernadorApi";
import Loading from "../../components/Loading";
import ErroResultados from "../../components/ErroResultados";
import PainelAnos from "../../components/PainelAnos";
import BuscaSimplesCards from "../../components/pesquisa_projeto_governador/BuscaSimplesCards";
import metaDados from "../../helpers/metaDados";

export default {
  name: "PorAnoProjetoGovernador",
  metaInfo() {
    return {
      title: "Pesquisa de Projeto de Autoria do Governador por Ano",
      titleTemplate: "%s - Casa Civil do Estado de Goiás",
      meta: [
        {
          vmid: "description",
          name: "description",
          property: "description",
          content:
            "Página de acesso e pesquisa aos projetos de lei propostos pelo Governo do Estado de Goiás.",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content:
            "Página de acesso e pesquisa aos projetos de lei propostos pelo Governo do Estado de Goiás.",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content:
            "Pesquisa de Projetos de Autoria do Governador por Ano - Casa Civil do Estado de Goiás",
        },
        {
          vmid: "og:type",
          property: "og:type",
          content: "article",
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          property: "twitter:description",
          content:
            "Página de acesso e pesquisa aos projetos de lei propostos pelo Governo do Estado de Goiás.",
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          property: "twitter:title",
          content:
            "Pesquisa de Projetos de Autoria do Governador por Ano - Casa Civil do Estado de Goiás",
        },
        ...metaDados(this.$route),
      ],
    };
  },
  data() {
    return {
      carregando: true,
      erro: false,
      anos: [],
    };
  },
  components: {
    Loading,
    ErroResultados,
    PainelAnos,
    BuscaSimplesCards,
  },
  created() {
    this.projetoGovernadorApi = new ProjetoGovernadorApi();

    this.carregarAnos();
  },
  methods: {
    carregarAnos: async function() {
      try {
        if (this.projetoGovernadorApi.cancelTokens.getAnos) {
          this.projetoGovernadorApi.cancelTokens.getAnos.cancel();
        }

        this.carregando = true;
        this.erro = false;
        this.anos = await this.projetoGovernadorApi.getAnos();
        this.carregando = false;
      } catch (err) {
        if (this.projetoGovernadorApi.isCancel(err)) {
          return;
        }
        console.log(err);
        this.erro = true;
        this.carregando = false;
      }
    },
    generateUrl: function(ano) {
      return {
        name: "ProjetosGovernador",
        query: { ano },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-family: "Poppins-Bold";
  color: #2c3f2b;
  padding-left: 0.5rem;
  border-left: #ffa900 solid 0.15rem;
  margin: 2rem 0rem 1.5rem 0.5rem;
  font-size: 1.5rem;
}
.tipo {
  min-height: calc(100vh - 10rem);
  margin-bottom: 5rem;
}
</style>
