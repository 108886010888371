import axios from "axios";
import axiosInstance from "./axiosInstance";

const queryString = require("query-string");

class ProjetoGovernadorApi {
  cancelTokens = {
    getProjetosGovernador: null,
    getProjetoGovernador: null,
    getMaisRecentes: null,
    getTiposVeto: null,
    getAnos: null
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getProjetosGovernador({
    page = 1,
    qtd_por_pagina,
    numero_processo_projeto_lei,
    assunto,
    numero_oficio_msg,
    numero_autografo_lei,
    numero_lei,
    em_tramitacao,
    data_oficio_inicio,
    data_oficio_fim,
    ano
  }) {
    // Definir valores padrão para os parâmetros que podem ser nulos
    numero_processo_projeto_lei = numero_processo_projeto_lei !== undefined ? numero_processo_projeto_lei : null;
    assunto = assunto !== undefined ? assunto : null;
    numero_oficio_msg = numero_oficio_msg !== undefined ? numero_oficio_msg : null;
    numero_autografo_lei = numero_autografo_lei !== undefined ? numero_autografo_lei : null;
    numero_lei = numero_lei !== undefined ? numero_lei : null;
    em_tramitacao = em_tramitacao !== undefined ? em_tramitacao : null;
    ano = ano !== undefined ? ano : null;
    data_oficio_inicio = data_oficio_inicio !== undefined ? data_oficio_inicio : null;
    data_oficio_fim = data_oficio_fim !== undefined ? data_oficio_fim : null;

    // Construir o objeto de parâmetros
    const queryParams = {
      page,
      qtd_por_pagina,
      numero_processo_projeto_lei,
      assunto,
      numero_oficio_msg,
      numero_autografo_lei,
      numero_lei,
      em_tramitacao,
      data_oficio_inicio,
      data_oficio_fim,
      ano
    };

    // Construir a query string
    const query = queryString.stringify(queryParams);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getProjetosGovernador = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/pesquisa/projetos_governador?${query}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getProjetosGovernador = null;

    return data;
  }

  async getProjetoGovernador(projetoId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getProjetoGovernador = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/pesquisa/projetos_governador/${projetoId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getProjetoGovernador = null;

    return data;
  }

  async getMaisRecentes() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getMaisRecentes = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/pesquisa/projetos_governador/mais_recentes`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getMaisRecentes = null;

    return data;
  }

  async getTiposVeto() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getTiposVeto = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/pesquisa/projetos_governador/tipos_de_veto`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getTiposVeto = null;

    return data;
  }

  async getAnos() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getAnos = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/pesquisa/projetos_governador/anos`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getAnos = null;

    return data;
  }
}

export default ProjetoGovernadorApi;
