<template>
  <div class="centro n-encontrado">
    <div class="imagens">
      <img
        src="../assets/images/nao-encontrado-mobile.png"
        class="mobile"
        alt=" "
      />
      <img
        src="../assets/images/nao-encontrado-desk.png"
        class="desk"
        alt=" "
      />
    </div>
    <h1>=( Oops!</h1>
    <p>Página não encontrada!</p>
  </div>
</template>

<script>
import metaDados from "../helpers/metaDados";

export default {
  name: "NaoEncontrado",
  metaInfo() {
    return {
      title: "Página não encontrada",
      titleTemplate: "%s - Casa Civil do Estado de Goiás",
      meta: [
        {
          vmid: "description",
          name: "description",
          property: "description",
          content: "Página não encontrada",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: "Página não encontrada",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: "Página não encontrada - Casa Civil do Estado de Goiás",
        },
        {
          vmid: "og:type",
          property: "og:type",
          content: "article",
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          property: "twitter:description",
          content: "Página não encontrada",
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          property: "twitter:title",
          content: "Página não encontrada - Casa Civil do Estado de Goiás",
        },
        ...metaDados(this.$route),
      ],
    };
  },
};
</script>
<style lang="scss">
.n-encontrado {
  min-height: calc(100vh - 6.8rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .mobile {
    height: 8rem;
  }
  .desk {
    display: none;
  }
  h1 {
    font-family: "Poppins-Bold";
    color: #375a3a;
    font-size: 3rem;
    margin: 0rem;
  }
  p {
    margin: 0rem;
  }
}
@media screen and (min-width: 64rem) {
  .n-encontrado {
    min-height: calc(100vh - 30rem);
    padding-bottom: 2rem;
    .mobile {
      display: none;
    }
    .desk {
      display: block;
      height: 20rem;
    }
    h1 {
      font-size: 5rem;
      margin-bottom: 1rem;
    }
    p {
      font-size: 1.5rem;
    }
  }
}
.alto-contraste {
  .n-encontrado {
    p {
      color: white;
    }
  }
}
</style>
