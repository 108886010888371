<template>
  <div>
    <div v-if="atoInfralegal.ementa">
      <p>
        <strong>Ementa:</strong>
        {{ atoInfralegal.ementa }}
      </p>
    </div>
    <div v-if="atoInfralegal.numero_processo_sei">
      <p>
        <strong>Número processo SEI:</strong>
        {{ atoInfralegal.numero_processo_sei }}
      </p>
    </div>
    <div
      v-if="
        atoInfralegal.categorias_ato_infralegal &&
        atoInfralegal.categorias_ato_infralegal.length > 0
      "
    >
      <p>
        <strong>Categoria(s):</strong>
        {{ categorias }}
      </p>
    </div>
    <div v-if="atoInfralegal.orgaos && atoInfralegal.orgaos.length > 0">
      <p>
        <strong>Órgão(s):</strong>
        {{ orgaos }}
      </p>
    </div>
    <div
      v-if="atoInfralegal.legislacoes && atoInfralegal.legislacoes.length > 0"
    >
      <span>
        <strong>Legislação(ões) Relacionada(s):</strong>
      </span>
      <ul>
        <li
          v-for="(leiRelacionada, index) in atoInfralegal.legislacoes"
          :key="`${leiRelacionada.id}${leiRelacionada.numero}${index}`"
        >
          <router-link
            :to="{
              name: 'PesquisaLegislacaoShow',
              params: { id: leiRelacionada.id },
            }"
            target="_blank"
            >{{ detalhesLei(leiRelacionada) }}</router-link
          >
        </li>
      </ul>
    </div>
    <div
      v-if="
        atoInfralegal.atos_infralegais_relacionados &&
        atoInfralegal.atos_infralegais_relacionados.length > 0
      "
    >
      <span>
        <strong>Ato(s) Infralegal(is) relacionado(s):</strong>
      </span>
      <ul>
        <li
          v-for="(
            atoInfralegalRelacionado, index
          ) in atoInfralegal.atos_infralegais_relacionados"
          :key="`${atoInfralegalRelacionado.id}${atoInfralegalRelacionado.numero}${index}`"
        >
          <router-link
            :to="{
              name: 'PesquisaAtoInfralegalShow',
              params: { id: atoInfralegalRelacionado.id },
            }"
            target="_blank"
            >{{ detalhesAtoInfralegal(atoInfralegalRelacionado) }}</router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "DetalhesAtoInfralegal",
  props: {
    atoInfralegal: Object,
  },
  methods: {
    detalhesLei: function (leiRelacionada) {
      if (leiRelacionada) {
        let dados = "";
        if (leiRelacionada.tipo_legislacao) {
          dados += `${leiRelacionada.tipo_legislacao.nome} `;
        }
        if (leiRelacionada.numero) {
          dados += `Nº ${leiRelacionada.numero}`;
        }
        if (leiRelacionada.ano) {
          dados += `/${leiRelacionada.ano}`;
        }
        return dados;
      }
      return "";
    },
    detalhesAtoInfralegal: function (atoInfralegalRelacionada) {
      if (atoInfralegalRelacionada) {
        let dados = "";
        if (atoInfralegalRelacionada.tipo_ato_infralegal) {
          dados += `${atoInfralegalRelacionada.tipo_ato_infralegal.nome} `;
        }
        if (atoInfralegalRelacionada.numero) {
          dados += `Nº ${atoInfralegalRelacionada.numero}`;
        }
        if (atoInfralegalRelacionada.ano) {
          dados += `/${atoInfralegalRelacionada.ano}`;
        }
        return dados;
      }
      return "";
    },
  },
  computed: {
    categorias: function () {
      return this.atoInfralegal.categorias_ato_infralegal
        .map((cat) => cat.nome)
        .join(", ");
    },
    orgaos: function () {
      return this.atoInfralegal.orgaos.map((orgao) => orgao.nome).join(", ");
    },
  },
};
</script>

<style lang="scss" scoped>
strong {
  font-family: "Poppins-Medium";
  color: #0a4a22;
  font-size: 0.9rem;
}
.alto-contraste {
  strong {
    color: #ffa900;
  }
}
ul {
  a {
    color: #375a3a;
  }
}
</style>