<template>
  <!-- ALTERAR AQUI, MAS LEMBRAR DE DEIXAR O COMPONENTE INTEIRO CLICAVEL -->
  <!-- <a :href="url" target="_blank" title="Baixar PDF" class='botao-pdf'> -->
  <a @click="registra_GA4()" :href="url" target="_blank" title="Baixar PDF" class='botao-pdf'>
    <img src="../assets/images/pdf-icon.png" alt="Baixar arquivo em formato PDF" />
  </a>
</template>

<script>
import registraEventoGoogleAnalytics from "../helpers/registraEventoGoogleAnalytics";

export default {
  name: "DownloadPdf",
  props: {
    url: {
      type: String,
      required: true
    },
    evento: {
      type: String,
      required: true
    },
    categoria: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  },
  methods: {
    registra_GA4() {
      registraEventoGoogleAnalytics(
        this.$gtag,
        this.evento,
        this.categoria,
        'PDF - ' + this.label
      );
    }
  }
};
</script>

<style lang="scss" scoped>
a {
  background-color: white;
  box-shadow: 0rem 0rem 1em rgb(107, 107, 107);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  bottom: 1.7rem;
  right: 1.7rem !important;
  font-size: 0.7rem;
  text-align: center;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  text-decoration: none;
  color: black;
  font-weight: 800;
  transition: 0.3s ease-in-out;
  img {
    height: 2rem;
    margin: 0rem auto;
  }
}
a:hover {
  padding: 0.2rem;
  background-color: rgb(243, 243, 243);
}
@media screen and (min-width: "64rem") {
  a {
    right: 3rem;
  }
}

@media print {
    .botao-pdf {
      display: none;
    }
}
</style>