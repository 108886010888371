import axios from "axios";
import axiosInstance from "./axiosInstance";


class PaisesApi {
    cancelTokens = {
        getPaises: null,
    };

    isCancel(exceptionThrown) {
        return axios.isCancel(exceptionThrown);
    }

    async getPaises() {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        this.cancelTokens.getPaises = source;

        const { data } = await axiosInstance.get(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/paises`,
            {
                cancelToken: source.token,
            }
        );

        this.cancelTokens.getPaises = null;

        return data;
    }
}

export default PaisesApi;