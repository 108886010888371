<template>
  <div>
    <div>
      <h3>Ano</h3>
      <div class="container">
        <div class="anos">
          <router-link :to="generateUrl(ano)" v-for="ano in anos" :key="ano">{{ano}}</router-link>
          <router-link :to="generateUrl(null)" class="todos">TODOS OS ANOS</router-link>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  name: "PainelAnos",
  props: {
    anos: {
      type: Array,
      required: true
    },
    generateUrl: {
      type: Function,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
h3{
  font-size: 1rem;
  color: white;
  padding: .2rem .5rem;
  background-color: #368d49;
}
.container{
  display: flex;
  justify-content: center;
  margin: 0rem;
}
.anos{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5rem;
}
a{
  margin: .3rem .3rem;
  padding: 0.1rem .7rem;
  border: solid .09rem #375a3a;
  text-decoration: none;
  color: #375a3a;
  border-radius: .5rem;
  font-size: .9rem;
  font-weight: 500;
  letter-spacing: .05rem;
  min-width: 2.5rem;
  text-align: center;
 font-family: 'Poppins-Regular';
}
a:hover{
  background-color: #375a3a;
  color: white;
}
.todos{
  width: 9rem;
}
.alto-contraste{
  h3{
    color: #19381c;
  }
  a{
    color: #ffa900;
    border: #ffa900 .09rem solid;
  }
  a:hover{
    background-color: #ffa900;
    color: white;
  }
}
@media screen and (min-width: '60rem'){
  .anos{
    padding-left: .3rem;
  }
  
}
</style>