function titleLegislacao(legislacao) {
    let title = "";
    if (
        legislacao.tipo_legislacao &&
        legislacao.tipo_legislacao.nome
    ) {
        title += `${legislacao.tipo_legislacao.nome} `;
    }
    if (legislacao.numero) {
        title += `Nº ${legislacao.numero}`;
    }
    if (legislacao.ano) {
        title += `/${legislacao.ano}`;
    }
    return title;
}

export default titleLegislacao;