<template>
  <div>
    <div class="centro">
      <h1>Índice de Atos Administrativos</h1>
      <BuscaSimplesCards />
    </div>
    <div class="pesquise">
      <div class="centro">
        <form @submit="buscar">
          <h2>Pesquise aqui:</h2>
          <div class="row5">
            <div class="item">
              <label>Tipo</label>
              <select v-model="filtro.tipo_ato">
                <option :value="null">Todos</option>
                <option v-for="tipo in tipos" :key="tipo[1]" :value="tipo[1]">
                  {{
                  tipo[0]
                  }}
                </option>
              </select>
            </div>
            <div class="item">
              <label>Ano</label>
              <input type="number" v-model="filtro.ano" />
            </div>
            <div class="item">
              <label>Nome do(a) Servidor(a)</label>
              <input type="text" v-model="filtro.nome" />
            </div>

            <div class="item">
              <label>Número do Ato</label>
              <input type="text" v-model="filtro.numero" />
            </div>

            <div class="item">
              <label>Assunto</label>
              <input type="text" v-model="filtro.assunto" />
            </div>
          </div>

          <div class="row2">
            <div class="grupo">
              <p>Data do Ato</p>
              <div class="data">
                <div class="item">
                  <label>De</label>
                  <date-picker
                    v-model="filtro.periodo_inicial_ato"
                    :formatter="datepickerFormatter"
                  ></date-picker>
                </div>
                <div class="item">
                  <label>Até</label>
                  <date-picker v-model="filtro.periodo_final_ato" :formatter="datepickerFormatter"></date-picker>
                </div>
              </div>
            </div>
            <div class="grupo">
              <p>Data do Diário</p>
              <div class="data">
                <div class="item">
                  <label>De</label>
                  <date-picker
                    v-model="filtro.periodo_inicial_diario"
                    :formatter="datepickerFormatter"
                  ></date-picker>
                </div>
                <div class="item">
                  <label>Até</label>
                  <date-picker
                    v-model="filtro.periodo_final_diario"
                    :formatter="datepickerFormatter"
                  ></date-picker>
                </div>
              </div>
            </div>
          </div>
          <div class="botoes">
            <button type="button" @click="limparFiltro">Limpar</button>
            <button type="submit">Buscar</button>
          </div>
        </form>
        <div class="seta">
          <img src="../../assets/images/triangulo-form.png" class="triangulo t-branco" alt=" " />
          <img src="../../assets/images/triangulo-form-black.png" class="triangulo t-preto" alt=" " />
        </div>
      </div>
    </div>
    <div class="centro campo-resultados" id="resultados">
      <div class="nav-resultados">
        <h2>Resultados da Pesquisa</h2>
        <div class="dados-abertos" v-show="!carregando && !erro">
          <p>
            <span>Dados Abertos:</span>
            <a @click="abrirDadosAbertos('json')">JSON</a>
            <a @click="abrirDadosAbertos('csv')">CSV</a>
          </p>
        </div>

        <ParametrosBusca
          v-show="!carregando && !erro"
          :filtro="filtro"
          :tipos="tipos"
          :funcaoBuscaAvancada="buscar"
        />

        <div class="npagina" v-show="!carregando">
          <label>Resultados por Página</label>
          <select :value="qtd_por_pagina" @input="mudouQtdPagina">
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>

        <div class="nresultados" v-show="!carregando && !erro">
          <p>
            <strong>{{ totalResultadosTexto }}</strong>
            {{
            totalResultadosTexto === 1
            ? ` resultado encontrado`
            : ` resultados encontrados`
            }}
          </p>
        </div>
      </div>
      <div>
        <TabelaResultadosAtoAdmin :atos="atosAdministrativos" v-if="!carregando && !erro"></TabelaResultadosAtoAdmin>

        <Loading v-if="carregando" size="medium"></Loading>
        <ErroResultados v-if="!carregando && erro"></ErroResultados>

        <paginate
          v-if="!erro && !carregando"
          v-model="pagina"
          :page-count="pageCount"
          :click-handler="mudouPagina"
          :prev-text="'Anterior'"
          :next-text="'Próxima'"
          :container-class="'className'"
          active-class="active-page"
        ></paginate>
      </div>
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
import { format, parseISO } from "date-fns";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";

import IndexAtoAdministrativoApi from "../../services/IndexAtoAdministrativoApi";
import TabelaResultadosAtoAdmin from "../../components/pesquisa_index_ato_administrativo/TabelaResultadosAtoAdmin";
import { datepickerFormatter } from "../../helpers/datepickerFormatter";
import Loading from "../../components/Loading";
import ErroResultados from "../../components/ErroResultados";
import tryParseInt from "../../helpers/tryParseInt";
import ParametrosBusca from "../../components/pesquisa_index_ato_administrativo/ParametrosBusca";
import BuscaSimplesCards from "../../components/pesquisa_index_ato_administrativo/BuscaSimplesCards";
import metaDados from "../../helpers/metaDados";


export default {
  name: "PesquisaIndexAtoAdministrativo",
  metaInfo() {
    return {
      title: "Pesquisa de Índice de Ato Administrativo",
      titleTemplate: "%s - Casa Civil do Estado de Goiás",
      meta: [
        {
          vmid: "description",
          name: "description",
          property: "description",
          content:
            "Pesquisa de Índice de Atos Administrativos - Decretos e Portarias de nomeação"
        },
        {
          vmid: "og:description",
          property: "og:description",
          content:
            "Pesquisa de Índice de Atos Administrativos - Decretos e Portarias de nomeação"
        },
        {
          vmid: "og:title",
          property: "og:title",
          content:
            "Pesquisa de Índice de Ato Administrativo - Casa Civil do Estado de Goiás"
        },
        {
          vmid: "og:type",
          property: "og:type",
          content: "article"
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          property: "twitter:description",
          content:
            "Pesquisa de Índice de Atos Administrativos - Decretos e Portarias de nomeação"
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          property: "twitter:title",
          content:
            "Pesquisa de Índice de Ato Administrativo - Casa Civil do Estado de Goiás"
        },
        ...metaDados(this.$route)
      ]
    };
  },
  data() {
    return {
      atosAdministrativos: [],
      carregando: true,
      erro: false,
      totalResultados: null,
      totalResultadosTexto: null,
      pagina: 1,
      qtd_por_pagina: 10,
      filtro: {
        tipo_ato: null,
        numero: null,
        nome: null,
        periodo_inicial_diario: null,
        periodo_final_diario: null,
        periodo_inicial_ato: null,
        periodo_final_ato: null,
        assunto: null,
        ano: null
      },
      tipos: [],
      carregandoValoresFiltro: true,
      erroCarregandoValoresFiltro: false,
      datepickerFormatter
    };
  },
  components: {
    DatePicker,
    Paginate,
    TabelaResultadosAtoAdmin,
    Loading,
    ErroResultados,
    ParametrosBusca,
    BuscaSimplesCards
  },
  created() {
    this.indexAtoAdministrativoApi = new IndexAtoAdministrativoApi();

    this.carregarQtdPorPagina();
    this.carregarAtosAdministrativos();
    this.carregarFiltro();
  },
  watch: {
    $route: function(to, from) {
      const mudouQuery =
        to.query.tipo_ato != from.query.tipo_ato ||
        to.query.numero != from.query.numero ||
        to.query.nome != from.query.nome ||
        to.query.periodo_inicial_diario != from.query.periodo_inicial_diario ||
        to.query.periodo_final_diario != from.query.periodo_final_diario ||
        to.query.periodo_inicial_ato != from.query.periodo_inicial_ato ||
        to.query.periodo_final_ato != from.query.periodo_final_ato ||
        to.query.assunto != from.query.assunto ||
        to.query.pagina != from.query.pagina ||
        to.query.ano != from.query.ano ||
        to.query.qtd_por_pagina != from.query.qtd_por_pagina;

      if (
        from.name == "PesquisaIndexAtoAdministrativo" &&
        to.name == "PesquisaIndexAtoAdministrativo" &&
        mudouQuery
      ) {
        this.carregarFiltro();
        this.carregarAtosAdministrativos();
      }
    }
  },
  methods: {
    getParam: function(name) {
      if (this.$route && this.$route.query) {
        return this.$route.query[name] || null;
      }
      return null;
    },
    buscar: function(event) {
      event.preventDefault();
      this.$router.push({
        name: "PesquisaIndexAtoAdministrativo",
        query: {
          ...this.filtro,
          ...this.convertePeriodosDatasParaString(),
          pagina: 1,
          qtd_por_pagina: this.qtd_por_pagina
        }
      });
      this.$scrollTo("#resultados", 700, {
        offset: -100,
        cancelable: false
      });
    },
    mudouQtdPagina: function(event) {
      const novaQtdPagina = parseInt(event.target.value);
      if (novaQtdPagina < this.qtd_por_pagina) {
        this.qtd_por_pagina = novaQtdPagina;
        this.mudouPagina(this.pagina);
      } else {
        let novaPagina = Math.ceil(
          (this.qtd_por_pagina * this.pagina) / novaQtdPagina
        );

        this.qtd_por_pagina = novaQtdPagina;
        this.mudouPagina(novaPagina);
      }
    },
    mudouPagina: function(page) {
      this.pagina = page;

      const filtro = {
        tipo_ato: this.getParam("tipo_ato"),
        numero: this.getParam("numero"),
        nome: this.getParam("nome"),
        periodo_inicial_diario: this.getParam("periodo_inicial_diario"),
        periodo_final_diario: this.getParam("periodo_final_diario"),
        periodo_inicial_ato: this.getParam("periodo_inicial_ato"),
        periodo_final_ato: this.getParam("periodo_final_ato"),
        assunto: this.getParam("assunto"),
        ano: this.getParam("ano")
      };

      this.$router.push({
        name: "PesquisaIndexAtoAdministrativo",
        query: {
          ...filtro,
          pagina: this.pagina,
          qtd_por_pagina: this.qtd_por_pagina
        }
      });
      this.$scrollTo("#resultados", 0, {
        offset: -100,
        cancelable: false
      });
    },
    carregarAtosAdministrativos: async function() {
      try {
        this.erro = false;
        this.carregando = true;

        const pagina = this.getParam("pagina") || 1;
        this.pagina = parseInt(pagina);

        this.qtd_por_pagina =
          this.getParam("qtd_por_pagina") || this.qtd_por_pagina;

        this.armazenarQtdPorPagina();

        this.filtro = {
          tipo_ato: this.getParam("tipo_ato"),
          numero: this.getParam("numero"),
          nome: this.getParam("nome"),
          ...this.convertPeriodosStringParaDatas(),
          assunto: this.getParam("assunto"),
          ano: this.getParam("ano")
        };

        if (
          this.indexAtoAdministrativoApi.cancelTokens.getAtosAdministrativos
        ) {
          this.indexAtoAdministrativoApi.cancelTokens.getAtosAdministrativos.cancel();
        }

        const data = await this.indexAtoAdministrativoApi.getAtosAdministrativos(
          {
            ...this.filtro,
            page: this.pagina,
            qtd_por_pagina: this.qtd_por_pagina,
            periodo_inicial_ato: this.getParam("periodo_inicial_ato"),
            periodo_final_ato: this.getParam("periodo_final_ato"),
            periodo_inicial_diario: this.getParam("periodo_inicial_diario"),
            periodo_final_diario: this.getParam("periodo_final_diario")
          }
        );
        this.atosAdministrativos = data.resultados;
        this.totalResultados = data.total_resultados;
        this.totalResultadosTexto = data.total_resultados.toLocaleString();

        this.carregando = false;
      } catch (err) {
        if (this.indexAtoAdministrativoApi.isCancel(err)) {
          return;
        }
        console.log(err);
        this.erro = true;
        this.carregando = false;
      }
    },
    carregarFiltro: async function() {
      if (
        this.tipos &&
        this.tipos.length > 0 &&
        !this.erroCarregandoValoresFiltro
      ) {
        this.erroCarregandoValoresFiltro = false;
        this.carregandoValoresFiltro = false;
        return;
      }

      try {
        this.erroCarregandoValoresFiltro = false;
        this.carregandoValoresFiltro = true;
        this.tipos = await this.indexAtoAdministrativoApi.getTipos();
        this.carregandoValoresFiltro = false;
      } catch (err) {
        console.log(err);
        this.carregandoValoresFiltro = false;
        this.erroCarregandoValoresFiltro = true;
      }
    },
    limparFiltro: function() {
      this.filtro = {
        tipo_ato: null,
        numero: null,
        nome: null,
        periodo_inicial_diario: null,
        periodo_final_diario: null,
        periodo_inicial_ato: null,
        periodo_final_ato: null,
        assunto: null,
        ano: null,
        datepickerFormatter
      };
    },
    convertePeriodosDatasParaString: function() {
      return {
        periodo_inicial_ato: this.filtro.periodo_inicial_ato
          ? format(this.filtro.periodo_inicial_ato, "yyyy-MM-dd")
          : null,
        periodo_final_ato: this.filtro.periodo_final_ato
          ? format(this.filtro.periodo_final_ato, "yyyy-MM-dd")
          : null,
        periodo_inicial_diario: this.filtro.periodo_inicial_diario
          ? format(this.filtro.periodo_inicial_diario, "yyyy-MM-dd")
          : null,
        periodo_final_diario: this.filtro.periodo_final_diario
          ? format(this.filtro.periodo_final_diario, "yyyy-MM-dd")
          : null
      };
    },
    convertPeriodosStringParaDatas: function() {
      const periodo_inicial_ato = this.getParam("periodo_inicial_ato");
      const periodo_final_ato = this.getParam("periodo_final_ato");
      const periodo_inicial_diario = this.getParam("periodo_inicial_diario");
      const periodo_final_diario = this.getParam("periodo_final_diario");

      return {
        periodo_inicial_ato: periodo_inicial_ato
          ? parseISO(periodo_inicial_ato)
          : null,
        periodo_final_ato: periodo_final_ato
          ? parseISO(periodo_final_ato)
          : null,
        periodo_inicial_diario: periodo_inicial_diario
          ? parseISO(periodo_inicial_diario)
          : null,
        periodo_final_diario: periodo_final_diario
          ? parseISO(periodo_final_diario)
          : null
      };
    },
    abrirDadosAbertos: function(format) {
      if (
        this.totalResultados == undefined ||
        this.totalResultados == null ||
        this.totalResultados > 1000
      ) {
        alert(
          "O download dos dados é limitado a 1000 registros. Refaça sua consulta utilizando os filtros disponíveis."
        );
      } else {
        const params = new URLSearchParams({
          tipo_ato: this.getParam("tipo_ato") || "",
          numero: this.getParam("numero") || "",
          nome: this.getParam("nome") || "",
          assunto: this.getParam("assunto") || "",
          periodo_inicial_ato: this.getParam("periodo_inicial_ato") || "",
          periodo_final_ato: this.getParam("periodo_final_ato") || "",
          periodo_inicial_diario: this.getParam("periodo_inicial_diario") || "",
          periodo_final_diario: this.getParam("periodo_final_diario") || "",
          ano: this.getParam("ano") || ""
        }).toString();
        window.open(
          `${process.env.VUE_APP_BASE_API_URL}/api/v1/pesquisa/atos_administrativo/dados_abertos.${format}?${params}`,
          "_blank"
        );
      }
    },
    armazenarQtdPorPagina() {
      localStorage.setItem(
        "qtd_por_pagina_index_ato_administrativo",
        this.qtd_por_pagina
      );
    },
    carregarQtdPorPagina() {
      const qtd = localStorage.getItem(
        "qtd_por_pagina_index_ato_administrativo"
      );
      if (qtd) {
        this.qtd_por_pagina = tryParseInt(qtd);
      }
    }
  },
  computed: {
    pageCount: function() {
      if (
        this.totalResultados != undefined &&
        this.totalResultados != null &&
        this.qtd_por_pagina != null &&
        this.qtd_por_pagina != undefined &&
        this.totalResultados > 0 &&
        this.qtd_por_pagina > 0
      ) {
        return Math.ceil(this.totalResultados / this.qtd_por_pagina);
      }
      return 0;
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  font-family: "Poppins-Bold";
  color: #2c3f2b;
  padding-left: 0.5rem;
  border-left: #ffa900 solid 0.15rem;
  margin: 2.5rem 0rem 1.5rem 1rem;
  font-size: 1.5rem;
  line-height: 2rem;
}
.pesquise {
  background-color: #375a3a;
  padding: 0.5rem 1rem 5rem;
  label {
    color: white;
    font-size: 0.9rem;
  }
  input {
    width: calc(100% - 0.8rem);
    height: 2rem;
    border-radius: 0.2rem;
    border: none;
    padding-left: 0.5rem;
    font-family: "Poppins";
    font-size: 1rem;
    letter-spacing: 0.03rem;
  }
  .campo-preenchido {
    background-color: #fffed4;
    // border: solid .15rem #ffa900;
    // box-shadow: 0rem 0rem 0rem 0.13rem #ffa900;
    // -moz-box-shadow: 0rem 0rem 0rem 0.13rem #ffa900;
    // -webkit-box-shadow: 0rem 0rem 0rem 0.13rem #ffa900;
  }
  p {
    font-size: 0.9rem;
  }
  .botoes {
    float: right;
    margin: 1rem 0rem;
    button {
      margin-left: 1rem;
      padding: 0.1rem 2rem;
      font-size: 0.9rem;
      font-family: "Poppins-Medium";
    }
    button:first-child {
      background: transparent;
      border: white solid 0.15rem;
      color: white;
      padding: 0.1rem 2rem;
      cursor: pointer;
    }
    button:last-child {
      background-color: #ffa900;
      color: #2c3f2b;
      border: solid 0.15rem #ffa900;
      cursor: pointer;
    }
  }

  select {
    width: calc(100% - 0.2rem);
    height: 2rem;
    border: none;
    border-radius: 0.2rem;
    padding-left: 0.5rem;
    background-color: white;
    font-family: "Poppins";
    font-size: 1rem;
    letter-spacing: .03rem;
  }

  h2 {
    color: white;
    font-family: "Poppins-ExtraLight";
    font-size: 1.1rem;
    display: table;
    padding-right: 1rem;
    border-bottom: #ffa900 solid 0.15rem;
    margin: 0rem;
  }
  .row2 {
    display: grid;
    .row2mobile {
      display: grid;
      grid-template-columns: auto auto;
      gap: 1rem;
    }
  }
  .row5 {
    display: grid;
    margin-bottom: 0.5rem;
  }
  .grupo {
    border: #ffa900 solid 0.15rem;
    margin: 0.5rem 0rem;
    padding: 0.5rem 0.5rem;
    border-radius: 0.5rem;
    .data {
      display: grid;
      grid-template-columns: auto auto;
      gap: 1rem;
      .mx-datepicker {
        width: 100%;
      }
      .mx-input {
        height: 1rem !important;
      }
    }
    p {
      margin: 0rem;
      padding: 0rem;
      color: white;
    }
  }
  .item {
    display: grid;
    margin: 0.5rem 0rem;
  }
  .seta {
    img {
      width: 5rem;
      position: absolute;
      right: 1.2rem;
      margin-top: 3.5rem;
    }
    .t-preto {
      display: none;
    }
  }
}
.campo-resultados {
  margin: 1rem;
  h2 {
    color: #2c3f2b;
    font-family: "Poppins-ExtraLight";
    font-size: 1.1rem;
    display: table;
    padding-right: 1rem;
    border-bottom: #ffa900 0.15rem solid;
    margin: 1.5rem 0rem 0rem 1rem;
  }
  .dados-abertos {
    padding-left: 0.5rem;
    width: 15rem;
    margin: 1rem 1rem 0rem 0rem;
    p,
    span {
      margin: 0rem;
      font-size: 0.8rem;
      font-family: "Poppins-ExtraLight";
      a {
        margin: 0rem 0.35rem 0rem 1rem;
        text-decoration: none;
        color: #2c3f2b;
        font-family: "Poppins-Medium";
        cursor: pointer;
      }
    }
    p {
      background-color: #edf2ee;
      padding-left: 0.5rem;
      height: 1.3rem;
    }
  }
  .nav-resultados {
    margin-bottom: 2rem;
  }
  .nresultados {
    p {
      font-size: 0.8rem;
      margin: 0.5rem 0rem -2.5rem 1rem;
      strong {
        font-family: "Poppins-Medium";
      }
    }
  }
  .npagina {
    margin: 0.5rem 1rem 0.5rem 1rem;
    label {
      font-family: "Poppins-Medium";
      font-weight: 900;
      margin-right: 0.5rem;
      color: #2c3f2b;
      font-size: 0.8rem;
    }
    select {
      border: none;
      background-color: #edf2ee;
      font-size: 0.9rem;
      margin-top: 0.5rem;
    }
  }
}
@media screen and (min-width: 31rem) {
  .nav-resultados {
    margin-bottom: 0rem !important;
  }
  .nresultados {
    p {
      margin-bottom: 0rem !important;
    }
  }
}
@media screen and (min-width: 50rem) {
  .row5 {
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
  }
}
@media screen and (min-width: 64rem) {
  h1 {
    margin-left: 0rem;
    font-size: 1.8rem;
  }
  h2 {
    font-size: 1.3rem !important;
  }
  .pesquise {
    padding-bottom: 5rem;
    h2 {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .row2 {
      grid-template-columns: calc(50% - 1%) calc(50% - 1%);
      gap: 2%;
    }

    .grupo {
      padding: 0.5rem 2rem;
    }
    .botoes {
      margin-right: 0.2rem;
      margin-top: 1rem;
      button {
        font-size: 1rem;
        padding: 0.1rem 4rem !important;
        border-radius: 0.2rem;
      }
    }
    .seta {
      img {
        right: 5rem;
        margin-top: 3.5rem;
      }
    }
  }
  .campo-resultados {
    h2 {
      margin-left: 0rem;
    }
    .dados-abertos {
      padding-left: 0.5rem;
      width: 13rem;
      margin: -2rem 1rem 0rem auto;
    }
    .npagina {
      text-align: right;
    }
    .nresultados {
      transform: translateY(-1.5rem);
      max-width: 17rem;
      p {
        font-size: 0.8rem;
        margin: 0.3rem 0rem 2rem 0rem;
        text-align: left;
        strong {
          font-family: "Poppins-Medium";
        }
      }
    }
  }
}
.alto-contraste {
  .pesquise {
    background-color: #213b27;
    .seta {
      .t-branco {
        display: none;
      }
      .t-preto {
        display: block;
      }
    }

    .botoes {
      button:last-child {
        background-color: #ffa900;
        color: #0d2b10;
        font-family: "Poppins-Medium";
      }
    }
  }
  .nav-resultados {
    h2 {
      color: white;
    }
  }
  .npagina {
    label {
      color: white !important;
    }
  }
  .nresultados {
    color: white;
  }
}
</style>
