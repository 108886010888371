function titleAtoInfralegal(atoInfralegal) {
    let title = "";
    if (
        atoInfralegal.tipo_ato_infralegal &&
        atoInfralegal.tipo_ato_infralegal.nome
    ) {
        title += `${atoInfralegal.tipo_ato_infralegal.nome} `;
    }
    if (atoInfralegal.numero) {
        title += `Nº ${atoInfralegal.numero}`;
    }
    if (atoInfralegal.ano) {
        title += `/${atoInfralegal.ano}`;
    }
    return title;
}

export default titleAtoInfralegal;