<template>
  <div class="footer">
    <div class="caixabranca">
      <div class="centro">
        <div class="row1">
          <h2>Conheça Outros Projetos</h2>
          <div class="logos">
            <!-- <a href="#" target="blank">
                            <img
                            src="../assets/images/logo_participa.png"
                            class="logo-participa"
                            alt="Logo do Participa Goias"
                            title="Acesse o Participe Goiás"
                            />
            </a>-->
            <a href="https://revisa.go.gov.br/" target="blank">
              <img
                src="../assets/images/logo_revisa.png"
                class="Logo-revisa"
                alt="Logo do Revisa Goiás"
                title="Acesse o Revisa Goiás"
              />
            </a>
            <a href="https://tanalei.casacivil.go.gov.br" target="blank">
              <img
                src="../assets/images/logo_tanalei.png"
                class="logo-tanalei"
                alt="logo do Ta Na Lei"
                title="Acesse o Tá Na Lei e fique por dentro dos seus direitos"
              />
            </a>
          </div>
        </div>
        <div class="row3">
          <div class="grupo">
            <h2>Serviços</h2>
            <router-link
              :to="{name: 'PesquisaLegislacao'}"
              target="blank"
              class="link-footer"
            >Pesquise a legislação</router-link>
            <a
              href="https://www.casacivil.go.gov.br/files/institucional/cadastro-de-autoridades-2022.pdf"
              target="blank"
              class="link-footer"
            >Cadastro de autoridades</a>
            <a
              href="https://diariooficial.abc.go.gov.br/"
              target="blank"
              class="link-footer"
            >Pesquise atos publicados no Diário</a>
          </div>
          <div class="grupo social">
            <h2>Redes Sociais</h2>
            <a
              href="https://www.facebook.com/casacivilgoias/"
              target="blank"
              alt="Link para a página do facebook da Casa Civíl"
              title="Facebook da Casa Civíl"
              class="face"
            >
              <img src="../assets/images/logo_face_pb.svg" class="logo-face-pb" />
              <img src="../assets/images/logo_face_color.svg" class="logo-face-color" />
            </a>
            <a
              href="https://www.instagram.com/casacivilgoias/?hl=pt-br"
              target="blank"
              title="Instagram da Casa Civíl"
              alt="Link para a página do Instagram da Casa Civíl"
              class="insta"
            >
              <img src="../assets/images/logo_insta_pb.svg" class="logo-insta-pb" />
              <img src="../assets/images/logo_insta_color.svg" class="logo-insta-color" />
            </a>
            <a
              href="https://twitter.com/casacivilgoias"
              target="blank"
              title="Twitter da Casa Civil"
              alt="Link para a página do Twitter da Casa Civíl"
              class="twitter"
            >
              <img src="../assets/images/logo_twitter_pb.svg" class="logo-twitter-pb" />
              <img src="../assets/images/logo_twitter_color.svg" class="logo-twitter-color" />
            </a>
          </div>
          <div class="grupo">
            <h2>Ouvidoria e Transparência</h2>
            <a
              href="http://www.transparencia.go.gov.br/portaldatransparencia/"
              target="blank"
              class="link-footer"
            >Goiás Transparência</a>
            <a
              href="http://www.cge.go.gov.br/ouvidoria/"
              target="blank"
              class="link-footer"
            >Ouvidoria Geral do Estado</a>
            <a
              href="https://vaptvupt.go.gov.br/servicos"
              target="blank"
              class="link-footer"
            >SIC - Serviço de Informação ao Cidadão</a>
            <a
              href="http://www.cge.go.gov.br/ouvidoria/Register_1.php"
              target="blank"
              class="link-footer"
            >e-SIC - Serviço Eletrônico de Informação ao cidadão</a>
          </div>
        </div>
      </div>
    </div>
    <div class="rodape">
      <div class="centro">
        <img
          src="../assets/images/logo_transparencia.png"
          class="logo-transparencia"
          alt="logo do Goias Transparente"
        />
        <span>v{{ version }}</span>
        <a href="https://casacivil.go.gov.br">
          <!-- <img
            src="../assets/images/logo-casa-novo-bco.svg"
            class="logo-casa-goias"
            alt="logo da Casa Civíl e Governo de Goias"
          /> -->
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      version: process.env.VUE_APP_VERSION
    };
  }
};
</script>

<style lang="scss" scoped>
.footer {
  .caixabranca {
    display: none;
  }
  .rodape {
    background-color: #375a3a;
    padding-bottom: 0.5rem;
    img {
      height: 3rem;
      padding: 0.5rem 0.5rem 0rem 0.5rem;
    }
    .logo-casa-goias {
      position: absolute;
      right: 0.5rem;
    }
    span {
      position: absolute;
      left: 0.5rem;
      bottom: 0rem;
      font-size: 0.6rem;
      color: white;
      letter-spacing: 0.1rem;
    }

    .logo-casa-goias {
      position: absolute;
      right: 0.5rem;
      height: 3.5rem;
    }
    .logo-transparencia {
      height: 1.8rem;
      padding: 1rem 0.5rem;
    }
  }
}
@media screen and (min-width: 64rem) {
  .footer {
    h2 {
      color: #2c3f2b;
      font-family: "Poppins-ExtraLight";
      font-size: 1.3rem;
      border-bottom: #ffa900 solid 0.15rem;
    }
    .row1 {
      h2 {
        width: 20rem;
      }
      .logos {
        display: flex;
        align-items: center;
        margin: 2rem 0rem 2rem 0rem;
        .logo-revisa {
          margin-top: -1rem;
        }
        img {
          width: 11rem;
          margin: 0rem 1.5rem;
        }
      }
    }
    .row3 {
      display: grid;
      grid-template-columns: 31% 31% 33%;
      gap: 2.5%;
      padding-bottom: 1rem;
      .grupo {
        .link-footer {
          text-decoration: none;
          color: black;
          font-size: 1rem;
          display: grid;
          margin-left: 1rem;
          transition: 0.3s ease-in-out;
        }
        .link-footer:hover {
          padding-left: 1rem;
        }
      }
      .grupo:nth-child(3) {
        a {
          width: calc(100% - 3rem);
        }
      }
      .social {
        position: relative;
        a {
          display: inline-block;
          margin-left: 3.5rem;
          img {
            height: 2.5rem;
          }
          .logo-face-color,
          .logo-insta-color,
          .logo-twitter-color {
            display: none;
          }
        }
        .face:hover {
          .logo-face-color {
            display: grid;
          }
          .logo-face-pb {
            display: none;
          }
        }
        .insta:hover {
          .logo-insta-color {
            display: grid;
          }
          .logo-insta-pb {
            display: none;
          }
        }
        .twitter:hover {
          .logo-twitter-color {
            display: grid;
          }
          .logo-twitter-pb {
            display: none;
          }
        }
      }
    }
    .caixabranca {
      display: block;
      background-color: white;
      padding: 1rem 0rem 1rem;
      box-shadow: 0 0.25em 0.75em rgb(134, 134, 134);
      -webkit-box-shadow: 0 0.5em 2rem rgb(134, 134, 134);
    }
    .rodape {
      background-color: #375a3a;
      padding-bottom: 0.1rem;
      img {
        height: 3rem;
        padding: 0.5rem;
      }
      .logo-casa-goias {
        position: absolute;
        right: 0.5rem;
        height: 4rem;
      }
      .logo-transparencia {
        height: 2.5rem;
        padding: 1rem 0rem;
      }
    }
  }
}
.alto-contraste {
  .footer {
    .caixabranca {
      background-color: #202020;
      box-shadow: none;
      -webkit-box-shadow: none;
      a {
        color: white !important;
      }
      a:hover {
        color: rgb(131, 131, 131);
      }
      h2 {
        color: #ffa900;
      }
    }
  }
  .rodape {
    background-color: #0d2b10;
  }
}
</style>
