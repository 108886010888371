<template>
  <div>
    <div class="carregando-show" v-if="carregando">
      <Loading size="medium"></Loading>
    </div>
    <div class="erro-carregando" v-if="!carregando && erro">
      <ErroResultados></ErroResultados>
    </div>
    <template v-if="!carregando && !erro">
      <ExibirLei :legislacao="legislacao" />
      <DownloadPdf 
        :url="urlPdf" 
        :evento="'visualizacao_pdf_legislacao'" 
        :categoria="'download_pdf_legislacao'" 
        :label="labelLegislacao" 
      />
    </template>
  </div>
</template>

<script>
import slugify from "slugify";

import LegislacaoApi from "../../services/LegislacaoApi";
import Loading from "../../components/Loading";
import ErroResultados from "../../components/ErroResultados";
import DownloadPdf from "../../components/DownloadPdf";
import ExibirLei from "../../components/pesquisa_legislacao/ExibirLei";
import titleLegislacao from "../../helpers/legislacao/titleLegislacao";
import metaDados from "../../helpers/metaDados";

const truncate = require("lodash.truncate");

export default {
  name: "PesquisaLegislacaoShow",
  metaInfo() {
    if (!this.legislacao) {
      return {};
    }

    let title = titleLegislacao(this.legislacao);
    const description = truncate(this.legislacao.ementa, {length: 160});

    return {
      title,
      titleTemplate: "%s - Casa Civil do Estado de Goiás",
      meta: [
        {
          vmid: "description",
          name: "description",
          property: "description",
          content: description,
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: description,
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: `${title} - Casa Civil do Estado de Goiás`,
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          property: "twitter:description",
          content: description,
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          property: "twitter:title",
          content: `${title} - Casa Civil do Estado de Goiás`,
        },
        ...metaDados(this.$route),
      ],
    };
  },
  data() {
    return {
      carregando: true,
      erro: false,
      legislacao: null,
      urlPdf: null,
      labelLegislacao: '',
    };
  },
  components: {
    Loading,
    ErroResultados,
    DownloadPdf,
    ExibirLei,
  },
  created() {
    this.legislacaoApi = new LegislacaoApi();

    this.carregarLegislacao();
  },
  watch: {
    $route: function(to, from) {
      if (to.params.id != from.params.id) {
        this.carregarLegislacao();
      }
    },
  },
  methods: {
    carregarLegislacao: async function() {
      try {
        this.carregando = true;
        this.erro = false;

        if (this.legislacaoApi.cancelTokens.getLegislacao) {
          this.legislacaoApi.cancelTokens.getLegislacao.cancel();
        }

        this.legislacao = await this.legislacaoApi.getLegislacao(
          this.$route.params.id
        );

        this.urlPdf = `${process.env.VUE_APP_BASE_API_URL}/api/v2/pesquisa/legislacoes/${this.legislacao.id}/pdf`;

        const slug = this.createSlug();
        if (this.$route.params && this.$route.params.slug != slug) {
          this.$router.replace({
            name: "PesquisaLegislacaoShow",
            params: { ...this.$route.params, slug },
            query: this.$route.query,
          });
        }

        if (this.legislacao && this.legislacao.tipo_legislacao) {
          this.labelLegislacao = `${this.legislacao.tipo_legislacao.nome} Nº ${this.legislacao.numero} / ${this.legislacao.ano}`;
        }

        this.carregando = false;
      } catch (err) {
        if (this.legislacaoApi.isCancel(err)) {
          return;
        }
        console.log(err);

        this.erro = true;
        this.carregando = false;
        this.legislacao = null;
      }
    },
    createSlug: function() {
      let tipoLegislacao = "";
      if (
        this.legislacao &&
        this.legislacao.tipo_legislacao &&
        this.legislacao.tipo_legislacao.nome
      ) {
        tipoLegislacao = this.legislacao.tipo_legislacao.nome
          .toLowerCase()
          .replace(/ordinária|numerado/, "")
          .trim();
      }
      if (this.legislacao && tipoLegislacao) {
        const slug = `${tipoLegislacao} ${this.legislacao.numero.replace(
          /\./g,
          ""
        )}`;
        return slugify(slug, {
          lower: true,
          strict: true,
          locale: "pt",
        });
      }
      return undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.carregando-show {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.erro-carregando {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
</style>
