<template>
  <div class="grupo-parametros">
    <div v-for="param in parametrosDeBusca" :key="param.key.toString()">
      <BotaoParametroBusca
        :label="param.label"
        :valor="param.valor"
        :onClick="(event) => onClick(event, param)"
      />
    </div>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";

import BotaoParametroBusca from "../BotaoParametroBusca";

export default {
  name: "ParametrosBusca",
  props: {
    funcaoBuscaSimples: {
      type: Function,
      required: true
    },
    funcaoBuscaAvancada: {
      type: Function,
      required: true
    },
    tiposLegislacao: {
      type: Array,
      required: true
    },
    categorias: {
      type: Array,
      required: true
    },
    filtro: {
      type: Object,
      required: true
    }
  },
  components: {
    BotaoParametroBusca
  },
  methods: {
    onClick: function(event, parametro) {
      const keys = Array.isArray(parametro.key)
        ? parametro.key
        : [parametro.key];
      for (let key of keys) {
        this.filtro[key] = undefined;
      }

      if (parametro.key === "termo") {
        this.funcaoBuscaSimples(event);
      } else {
        this.funcaoBuscaAvancada(event);
      }
    }
  },
  computed: {
    parametrosDeBusca() {
      //parametros de busca avancada
      const labelsPermitidas = [
        "numero",
        "conteudo",
        "tipo_legislacao",
        "categoria_legislacao",
        "ementa",
        "autor",
        "ano"
      ];

      const keys = Object.keys(this.$route.query).filter(key =>
        labelsPermitidas.includes(key)
      );

      let parametros = [];
      const labels = {
        numero: {
          label: "Número",
          valor: valor => valor
        },
        conteudo: { label: "Texto", valor: valor => valor },
        tipo_legislacao: {
          label: "Tipo",
          valor: valor => {
            const t = this.tiposLegislacao.find(
              tipo => parseInt(tipo.id) === parseInt(valor)
            );
            if (t) {
              return t.nome;
            }
            return "";
          }
        },
        categoria_legislacao: {
          label: "Categoria",
          valor: valor => {
            const c = this.categorias.find(
              cat => parseInt(cat.id) === parseInt(valor)
            );
            if (c) {
              return c.nome;
            }
            return "";
          }
        },
        ementa: {
          label: "Ementa",
          valor: valor => valor
        },
        autor: {
          label: "Autor",
          valor: valor => valor
        },
        ano: {
          label: "Ano",
          valor: valor => valor
        }
      };

      for (let key of keys) {
        if (
          this.$route.query[key] != null &&
          this.$route.query[key] != undefined &&
          this.$route.query[key] !== ""
        ) {
          parametros.push({
            label: labels[key].label,
            key,
            valor: labels[key].valor(this.$route.query[key])
          });
        }
      }

      const formatData = data => format(parseISO(data), "dd/MM/yyyy");

      if (
        this.$route.query.periodo_inicial_legislacao &&
        this.$route.query.periodo_final_legislacao
      ) {
        parametros.push({
          label: "Data da Legislação",
          key: ["periodo_inicial_legislacao", "periodo_final_legislacao"],
          valor: `${formatData(
            this.$route.query.periodo_inicial_legislacao
          )} a ${formatData(this.$route.query.periodo_final_legislacao)}`
        });
      } else if (this.$route.query.periodo_inicial_legislacao) {
        parametros.push({
          label: "Data da Legislação",
          key: ["periodo_inicial_legislacao", "periodo_final_legislacao"],
          valor: `A partir de ${formatData(
            this.$route.query.periodo_inicial_legislacao
          )}`
        });
      } else if (this.$route.query.periodo_final_legislacao) {
        parametros.push({
          label: "Data da Legislação",
          key: ["periodo_inicial_legislacao", "periodo_final_legislacao"],
          valor: `Até ${formatData(this.$route.query.periodo_final_legislacao)}`
        });
      }

      if (
        this.$route.query.periodo_inicial_diario &&
        this.$route.query.periodo_final_diario
      ) {
        parametros.push({
          label: "Data do Diário",
          key: ["periodo_inicial_diario", "periodo_final_diario"],
          valor: `${formatData(
            this.$route.query.periodo_inicial_diario
          )} a ${formatData(this.$route.query.periodo_final_diario)}`
        });
      } else if (this.$route.query.periodo_inicial_diario) {
        parametros.push({
          label: "Data do Diário",
          key: ["periodo_inicial_diario", "periodo_final_diario"],
          valor: `A partir de ${formatData(
            this.$route.query.periodo_inicial_diario
          )}`
        });
      } else if (this.$route.query.periodo_final_diario) {
        parametros.push({
          label: "Data do Diário",
          key: ["periodo_inicial_diario", "periodo_final_diario"],
          valor: `Até ${formatData(this.$route.query.periodo_final_diario)}`
        });
      }

      //parametro de busca simples
      if (this.$route.query.termo) {
        parametros.push({
          label: "Termo",
          key: "termo",
          valor: this.$route.query.termo
        });
      }

      return parametros;
    }
  }
};
</script>

<style lang="scss" scoped>
.grupo-parametros {
  margin: 0;
}
div {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}
@media screen and (min-width: 64rem) {
  .grupo-parametros {
    margin: 0;
  }
}
</style>