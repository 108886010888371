import axios from 'axios';

// import router from '../router';

const instance = axios.create({});

instance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    // if(error && error.response && error.response.status === 404) {
    //     const currentPath = router.currentRoute && router.currentRoute.path;
    //     router.replace({ name: "NaoEncontrado", query: { url: currentPath } });
    // }
    return Promise.reject(error);
});

export default instance;