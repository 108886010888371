<template>
  <div>
    <div class="centro">
      <h1>Ato Infralegal</h1>
      <BuscaSimplesCards />
    </div>
    <div class="pesquise">
      <div class="centro">
        <div>
          <h2>Pesquise aqui:</h2>
          <!--<div class="busca-info">
            <p class="info-texto">Experimente os novos recursos de busca!</p>
            <p class="info-texto">A nova busca permite a combinação de vários critérios de pesquisa. Além do texto, você pode utilizar operadores especiais. Clique na opção deseada <span>(E, OU, NÃO, FRASE ÚNICA)</span> para aplicar o critério.</p>
          </div>-->
          <div class="item row1">
            <form @submit="fazerBuscaSimples">
              <div class="busca-simples">
                <input
                  type="text"
                  placeholder="Digite o número ou texto do Ato Infralegal"
                  v-model="termo"
                />
                <button
                  title="Pesquisar"
                  type="submit"
                  class="submit-busca-simples"
                >
                  <img
                    src="../../assets/images/pesquisa.png"
                    class="pesquisa"
                    alt=" "
                  />
                </button>
              </div>
            </form>
          </div>
          <div class="text-center my-3 tooltip">
            <div class="container-tooltip">
              <button @click="addOperator('&')"
                      @mouseover="showModal(1)" @mouseleave="hideModal(1)"
                      id="tooltip-target-1"
                      class="button">
                E
              </button>
              <div id="modal1" class="modal modal-1">
                <p>Para buscar vários termos, insira-os separados por um espaço ou pelo caractere <span>"&".</span></p>
                <p><span>Exemplo:</span> Se você deseja encontrar um ato pela frase "Conceder à empresa um crédito outorgado" e que contenha o termo "ICMS", a busca deve ser feita da seguinte forma:</p>
                <p><span>"Conceder à empresa um crédito outorgado" & ICMS</span></p>
              </div>
            </div>
            <div class="container-tooltip">
              <button @click="addOperator('OR')"
                      @mouseover="showModal(2)" @mouseleave="hideModal(2)"
                      id="tooltip-target-2"
                      class="button">
                OU
              </button>
              <div id="modal2" class="modal modal-2">
                <p>Para buscar um termo ou outro, insira os termos separados pela palavra <span>"OR".</span></p>
                <p><span>Exemplo:</span> Se você deseja encontrar um ato pelos termos "IPVA" ou "ICMS", a busca deve ser feita da seguinte forma:</p>
                <p><span>ICMS OR IPVA</span></p>
              </div>
            </div>
            <div class="container-tooltip">
              <button @click="addOperator('-')"
                      @mouseover="showModal(3)" @mouseleave="hideModal(3)"
                      id="tooltip-target-3"
                      class="button">
                NÃO
              </button>
              <div id="modal3" class="modal modal-3">
                <p>Para excluir um termo da busca, insira o caractere <span>"-"</span> antes do termo.</p>
                <p><span>Exemplo:</span> Se você deseja encontrar um ato que não contenha o termo "ICMS", a busca deve ser feita da seguinte forma:</p>
                <p><span>-ICMS</span></p>                
              </div>
            </div>
            <div class="container-tooltip">
              <button @click="addOperator('&quot; &quot;')" 
                      @mouseover="showModal(4)" @mouseleave="hideModal(4)"
                      id="tooltip-target-4"
                      class="button">
                FRASE ÚNICA
              </button>
              <div id="modal4" class="modal modal-4">
                <p>Para buscar uma frase, insira a frase entre aspas <span>(" ").</span></p>
                <p><span>Exemplo:</span> Se você deseja encontrar um ato pela frase "Conceder à empresa um crédito outorgado", a busca deve ser feita da seguinte forma:</p>
                <p><span>"Conceder à empresa um crédito outorgado"</span></p>
              </div>
            </div>
          </div>
          <div class="botao">
            <button type="button" @click="tooglePesquisaAvancada">
              Pesquisa avançada
              <span>
                <template v-if="!exibirPesquisaAvancada">&#9660;</template>
                <template v-else>&#9650;</template>
              </span>
            </button>
          </div>
          <transition name="slide">
            <form v-if="exibirPesquisaAvancada" @submit="fazerBuscaAvancada">
              <div class="avancado">
                <div class="row2">
                  <div class="item">
                    <label>Tipo</label>
                    <select v-model="filtro.tipo_ato_infralegal">
                      <option :value="undefined">Todos</option>
                      <option
                        v-for="tipo in tiposAtoInfralegal"
                        :key="tipo.id"
                        :value="tipo.id"
                      >
                        {{ tipo.nome }}
                      </option>
                    </select>
                  </div>
                  <div class="row2mobile">
                    <div class="item">
                      <label>Nº do Ato Infralegal</label>
                      <input type="text" v-model="filtro.numero" />
                    </div>
                    <div class="item">
                      <label>Ano do Ato Infralegal</label>
                      <input type="number" v-model="filtro.ano" />
                    </div>
                  </div>
                </div>
                <div class="row2">
                  <div class="grupo">
                    <p>Data do Ato Infralegal</p>
                    <div class="data">
                      <div class="item">
                        <label>De</label>
                        <date-picker
                          v-model="filtro.periodo_inicial_data"
                          :formatter="datepickerFormatter"
                        ></date-picker>
                      </div>
                      <div class="item">
                        <label>Até</label>
                        <date-picker
                          v-model="filtro.periodo_final_data"
                          :formatter="datepickerFormatter"
                        ></date-picker>
                      </div>
                    </div>
                  </div>
                  <div class="grupo">
                    <p>Data de início da vigência</p>
                    <div class="data">
                      <div class="item">
                        <label>De</label>
                        <date-picker
                          v-model="filtro.periodo_inicial_vigencia_inicio"
                          :formatter="datepickerFormatter"
                        ></date-picker>
                      </div>
                      <div class="item">
                        <label>Até</label>
                        <date-picker
                          v-model="filtro.periodo_inicial_vigencia_fim"
                          :formatter="datepickerFormatter"
                        ></date-picker>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row2">
                  <div class="grupo">
                    <p>Data do fim da vigência</p>
                    <div class="data">
                      <div class="item">
                        <label>De</label>
                        <date-picker
                          v-model="filtro.periodo_final_vigencia_inicio"
                          :formatter="datepickerFormatter"
                        ></date-picker>
                      </div>
                      <div class="item">
                        <label>Até</label>
                        <date-picker
                          v-model="filtro.periodo_final_vigencia_fim"
                          :formatter="datepickerFormatter"
                        ></date-picker>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <label>Órgão Emissor</label>
                    <Autocomplete
                        @update-items="filtrarOrgao"
                        @item-selected="atribuiOrgao"
                        @focus="filtrarOrgao('')"
                        :items="orgaosAtoInfralegalFiltrados"
                        :component-item='ItemAutocompleteTemplate'
                        :get-label="getLabelOrgao" 
                        :auto-select-one-item=false
                        :min-len="0"
                        :wait="100"
                        clearable
                        placeholder="Digite o nome do órgão para filtrar">
                    </Autocomplete>                    
                  </div>
                </div>


                <div class="row2">
                  <div class="item">
                    <label>Texto</label>
                    <input type="text" v-model="filtro.conteudo" />
                  </div>
                  <div class="item">
                    <label>Categoria</label>
                    <Autocomplete
                        @update-items="filtrarCategoria"
                        @item-selected="atribuiCategoria"
                        @focus="filtrarCategoria('')"
                        :items="nomesAtoInfralegalFiltrados"
                        :component-item='ItemAutocompleteTemplate'
                        :get-label="getLabelCategoria" 
                        :auto-select-one-item=false
                        :min-len="0"
                        :wait="100"
                        clearable
                        placeholder="Digite o nome da categoria de ato infralegal para filtrar">
                    </Autocomplete>
                  </div>
                </div>
                <div class="row2">
                  <div class="item">
                    <label>Ementa</label>
                    <input type="text" v-model="filtro.ementa" />
                  </div>
                  <div class="item"></div>
                  <div></div>
                  <div class="botoes">
                    <button type="button" @click="limparFiltro">Limpar</button>
                    <button type="submit">Buscar</button>
                  </div>
                </div>


              </div>
            </form>
          </transition>
        </div>
        <div class="seta">
          <img
            src="../../assets/images/triangulo-form.png"
            class="triangulo t-branco"
            alt=" "
          />
          <img
            src="../../assets/images/triangulo-form-black.png"
            class="triangulo t-preto"
            alt=" "
          />
        </div>
      </div>
    </div>
    <div class="centro campo-resultados" id="resultados">
      <div class="nav-resultados">
        <h2>Resultados da Pesquisa</h2>
        <div class="dados-abertos" v-show="!carregando && !erro">
          <p>
            <span>Dados Abertos:</span>
            <a @click="abrirDadosAbertos('json')">JSON</a>
            <a @click="abrirDadosAbertos('csv')">CSV</a>
          </p>
        </div>

        <ParametrosBusca
          :funcaoBuscaSimples="
            (event) => {
              this.termo = null;
              this.fazerBuscaSimples(event);
            }
          "
          :funcaoBuscaAvancada="fazerBuscaAvancada"
          :tiposAtoInfralegal="tiposAtoInfralegal"
          :categorias="categoriasAtoInfralegal"
          :orgaos="orgaosAtoInfralegal"
          :filtro="filtro"
          v-show="!carregando && !erro"
        />

        <div class="npagina" v-show="!carregando">
          <label>Resultados por Página</label>
          <select :value="qtd_por_pagina" @input="mudouQtdPagina">
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <div class="nresultados" v-show="!carregando && !erro">
          <p>
            <strong>{{ totalResultadosTexto }}</strong>
            {{
              totalResultadosTexto === 1
                ? ` resultado encontrado`
                : ` resultados encontrados`
            }}
          </p>
        </div>
      </div>
      <div>
        <TabelaResultadosAtoInfralegal
          :atosInfralegais="atosInfralegais"
          v-if="!carregando && !erro && !erroFiltro"
        ></TabelaResultadosAtoInfralegal>

        <Loading v-if="carregando" size="medium"></Loading>
        <ErroResultados v-if="!carregando && erro"></ErroResultados>

        <paginate
          v-if="!erro && !carregando"
          v-model="pagina"
          :page-count="pageCount"
          :click-handler="mudouPagina"
          :prev-text="'Anterior'"
          :next-text="'Próxima'"
          :container-class="'className'"
          active-class="active-page"
        ></paginate>
      </div>
    </div>
  </div>
</template>

<script>
  import { format, parseISO } from "date-fns";
  import Paginate from "vuejs-paginate";
  import DatePicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";
  import "vue2-datepicker/locale/pt-br";

  import Autocomplete from 'v-autocomplete';
  import ItemAutocompleteTemplate from '../../components/AutocompleteTemplate';
  import 'v-autocomplete/dist/v-autocomplete.css';

  import AtoInfralegalApi from "../../services/AtoInfralegalApi";
  import TipoAtoInfralegalApi from "../../services/TipoAtoInfralegalApi";
  import CategoriaAtoInfralegalApi from "../../services/CategoriaAtoInfralegalApi";
  import OrgaoAtoInfralegalApi from "../../services/OrgaoAtoInfralegalApi";
  import TabelaResultadosAtoInfralegal from "../../components/pesquisa_ato_infralegal/TabelaResultadosAtoInfralegal";
  import { datepickerFormatter } from "../../helpers/datepickerFormatter";
  import BuscaSimplesCards from "../../components/pesquisa_ato_infralegal/BuscaSimplesCards";
  import Loading from "../../components/Loading";
  import ErroResultados from "../../components/ErroResultados";
  import tryParseInt from "../../helpers/tryParseInt";
  import ParametrosBusca from "../../components/pesquisa_ato_infralegal/ParametrosBusca";
  import metaDados from "../../helpers/metaDados";

  export default {
    name: "PesquisaAtoInfralegal",
    metaInfo() {
      return {
        title: "Pesquisa de Ato Infralegal",
        titleTemplate: "%s - Casa Civil do Estado de Goiás",
        meta: [
          {
            vmid: "description",
            name: "description",
            property: "description",
            content:
              "Página de acesso e pesquisa a toda legislação e atos normativos do Governo do Estado de Goiás.",
          },
          {
            vmid: "og:description",
            property: "og:description",
            content:
              "Página de acesso e pesquisa a toda legislação e atos normativos do Governo do Estado de Goiás.",
          },
          {
            vmid: "og:title",
            property: "og:title",
            content: "Pesquisa a Legislação - Casa Civil do Estado de Goiás",
          },
          {
            vmid: "twitter:description",
            name: "twitter:description",
            property: "twitter:description",
            content:
              "Página de acesso e pesquisa a toda legislação e atos normativos do Governo do Estado de Goiás.",
          },
          {
            vmid: "twitter:title",
            name: "twitter:title",
            property: "twitter:title",
            content: "Pesquisa a Legislação - Casa Civil do Estado de Goiás",
          },
          ...metaDados(this.$route),
        ],
      };
    },
    data() {
      return {
        atosInfralegais: [],
        maisRecentes: [],
        carregandoMaisRecentes: true,
        erroMaisRecentes: false,
        carregando: true,
        erro: false,
        totalResultados: null,
        totalResultadosTexto: null,
        pagina: 1,
        qtd_por_pagina: 10,
        termo: null, //termo usado na busca simples
        filtro: {
          numero: null,
          conteudo: null,
          tipo_ato_infralegal: null,
          categoria_ato_infralegal: null,
          orgao_emissor: null,
          ano: null,
          periodo_inicial_data: null,
          periodo_final_data: null,
          periodo_inicial_vigencia_inicio: null,
          periodo_final_vigencia_inicio: null,
          periodo_inicial_vigencia_fim: null,
          periodo_final_vigencia_fim: null,
          ementa: null,
        },
        carregandoFiltro: true,
        erroFiltro: false,
        tiposAtoInfralegal: [],
        categoriasAtoInfralegal: [],
        orgaosAtoInfralegal: [],
        orgaosAtoInfralegalFiltrados: [],
        nomesAtoInfralegalFiltrados: [],
        exibirPesquisaAvancada: tryParseInt(this.getParam("busca_avancada")) == 1,
        datepickerFormatter,
        ItemAutocompleteTemplate: ItemAutocompleteTemplate
      };
    },
    components: {
      TabelaResultadosAtoInfralegal,
      Paginate,
      DatePicker,
      Loading,
      ErroResultados,
      BuscaSimplesCards,
      ParametrosBusca,
      Autocomplete
    },
    created() {
      this.atoInfralegalApi = new AtoInfralegalApi();

      this.tipoAtoInfralegalApi = new TipoAtoInfralegalApi();
      this.categoriaAtoInfralegalApi = new CategoriaAtoInfralegalApi();
      this.orgaoAtoInfralegalApi = new OrgaoAtoInfralegalApi();

      this.carregarQtdPorPagina();
      this.carregarValoresFiltro();
      this.carregarAtoInfralegal();
    },
    watch: {
      $route: function (to, from) {
        const mudouQuery =
          to.query.numero != from.query.numero ||
          to.query.conteudo != from.query.conteudo ||
          to.query.tipo_ato_infralegal != from.query.tipo_ato_infralegal ||
          to.query.categoria_ato_infralegal !=
            from.query.categoria_ato_infralegal ||
          to.query.ano != from.query.ano ||
          to.query.orgao_emissor != from.query.orgao_emissor ||
          to.query.periodo_inicial_data != from.query.periodo_inicial_data ||
          to.query.periodo_final_data != from.query.periodo_final_data ||
          to.query.periodo_inicial_vigencia_inicio !=
            from.query.periodo_inicial_vigencia_inicio ||
          to.query.periodo_inicial_vigencia_fim !=
            from.query.periodo_inicial_vigencia_fim ||
          to.query.periodo_final_vigencia_inicio !=
            from.query.periodo_final_vigencia_inicio ||
          to.query.periodo_final_vigencia_fim !=
            from.query.periodo_final_vigencia_fim ||
          to.query.pagina != from.query.pagina ||
          to.query.qtd_por_pagina != from.query.qtd_por_pagina ||
          to.query.termo != from.query.termo ||
          to.query.ementa != from.query.ementa;

        if (
          from.name == "PesquisaAtoInfralegal" &&
          to.name == "PesquisaAtoInfralegal" &&
          mudouQuery
        ) {
          this.carregarValoresFiltro();
          this.carregarAtoInfralegal();
        }
      },
    },
    methods: {
      showModal(numero) {
        var modalAtivo = document.getElementById("modal" + numero)

        if(modalAtivo.style.display == "none") {
          modalAtivo.style.display = "block"
        } else {
          modalAtivo.style.display = "none"
        }
      },
      hideModal() {
        var modal1 = document.getElementById("modal1")
        var modal2 = document.getElementById("modal2")
        var modal3 = document.getElementById("modal3")
        var modal4 = document.getElementById("modal4")

        modal1.style.display = "none"
        modal2.style.display = "none"
        modal3.style.display = "none"
        modal4.style.display = "none"
      },
      addOperator(operator) {
        if(this.termo == null) {
          this.termo = '';
        }
        this.termo += ` ${operator} `;
      },
      mudouQtdPagina: function (event) {
        const novaQtdPagina = parseInt(event.target.value);
        if (novaQtdPagina < this.qtd_por_pagina) {
          this.qtd_por_pagina = novaQtdPagina;
          this.mudouPagina(this.pagina);
        } else {
          let novaPagina = Math.ceil(
            (this.qtd_por_pagina * this.pagina) / novaQtdPagina
          );

          this.qtd_por_pagina = novaQtdPagina;
          this.mudouPagina(novaPagina);
        }
      },
      getParam: function (name) {
        if (this.$route && this.$route.query) {
          return this.$route.query[name];
        }
        return null;
      },
      fazerBuscaSimples: function (event) {
        event.preventDefault();
        this.$router.push({
          name: "PesquisaAtoInfralegal",
          query: {
            termo: this.termo,
            pagina: 1,
            qtd_por_pagina: this.qtd_por_pagina,
          },
        });

        this.$scrollTo("#resultados", 700, {
          offset: -100,
          cancelable: false,
        });
      },
      fazerBuscaAvancada: function (event) {
        event.preventDefault();
        this.$router.push({
          name: "PesquisaAtoInfralegal",
          query: {
            ...this.filtro,
            ...this.convertePeriodosDatasParaString(),
            pagina: 1,
            qtd_por_pagina: this.qtd_por_pagina,
            busca_avancada: 1,
          },
        });
        this.$scrollTo("#resultados", 700, {
          offset: -100,
          cancelable: false,
        });
      },
      mudouPagina: function (page) {
        this.pagina = page;

        let termo = {};
        if (this.getParam("termo")) {
          termo = { termo: this.getParam("termo") };
        }

        const filtro = {
          numero: this.getParam("numero"),
          conteudo: this.getParam("conteudo"),
          tipo_ato_infralegal: this.getParam("tipo_ato_infralegal"),
          categoria_ato_infralegal: this.getParam("categoria_ato_infralegal"),
          orgao_emissor: this.getParam("orgao_emissor"),
          ano: this.getParam("ano"),
          periodo_inicial_data: this.getParam("periodo_inicial_data"),
          periodo_final_data: this.getParam("periodo_final_data"),
          periodo_inicial_vigencia_inicio: this.getParam(
            "periodo_inicial_vigencia_inicio"
          ),
          periodo_inicial_vigencia_fim: this.getParam(
            "periodo_inicial_vigencia_fim"
          ),
          periodo_final_vigencia_inicio: this.getParam(
            "periodo_final_vigencia_inicio"
          ),
          periodo_final_vigencia_fim: this.getParam("periodo_final_vigencia_fim"),
          ementa: this.getParam("ementa"),
        };

        this.$router.push({
          name: "PesquisaAtoInfralegal",
          query: {
            ...filtro,
            ...termo,
            pagina: this.pagina,
            qtd_por_pagina: this.qtd_por_pagina,
            busca_avancada: this.getParam("busca_avancada"),
          },
        });
        this.$scrollTo("#resultados", 0, {
          offset: -100,
          cancelable: false,
        });
      },
      carregarValoresFiltro: async function () {
        try {
          if (
            !this.carregandoFiltro &&
            !this.erroFiltro &&
            this.tiposAtoInfralegal &&
            this.tiposAtoInfralegal.length > 0
          )
            return;

          this.carregandoFiltro = true;
          this.erroFiltro = false;

          this.tiposAtoInfralegal = await this.tipoAtoInfralegalApi.getTiposAtoInfralegal();
          this.categoriasAtoInfralegal = await this.categoriaAtoInfralegalApi.getCategoriasAtoInfralegal();
          
          var orgaos = await this.orgaoAtoInfralegalApi.getOrgaosAtoInfralegal();
          orgaos.sort(this.compareNames);
          orgaos.unshift({id: null, nome: 'Todos'});
          this.orgaosAtoInfralegal = orgaos;
          this.orgaosAtoInfralegalFiltrados = this.orgaosAtoInfralegal;

          this.carregandoFiltro = false;
        } catch (err) {
          console.log("carregarValoresFiltro", err);
          this.carregandoFiltro = false;
          this.erroFiltro = true;
          this.tiposAtoInfralegal = [];
          this.categoriasAtoInfralegal = [];
          this.orgaosAtoInfralegal = [];
          this.orgaosAtoInfralegalFiltrados = [];
        }
      },
      carregarAtoInfralegal: async function () {
        try {
          this.carregando = true;
          this.erro = false;

          this.qtd_por_pagina =
            this.getParam("qtd_por_pagina") || this.qtd_por_pagina;

          this.armazenarQtdPorPagina();

          const pagina = this.getParam("pagina") || 1;
          this.pagina = parseInt(pagina);

          this.termo = this.getParam("termo");

          this.filtro = {
            numero: this.getParam("numero"),
            conteudo: this.getParam("conteudo"),
            tipo_ato_infralegal: this.getParam("tipo_ato_infralegal"),
            categoria_ato_infralegal: this.getParam("categoria_ato_infralegal"),
            orgao_emissor: this.getParam("orgao_emissor"),
            ano: this.getParam("ano"),
            ementa: this.getParam("ementa"),
            ...this.convertPeriodosStringParaDatas(),
          };

          const buscaAvancada = tryParseInt(this.getParam("busca_avancada"));

          if (this.atoInfralegalApi.cancelTokens.getAtosInfralegais) {
            this.atoInfralegalApi.cancelTokens.getAtosInfralegais.cancel();
          }

          let data;
          if (buscaAvancada && buscaAvancada === 1) {
            data = await this.atoInfralegalApi.getAtosInfralegais({
              ...this.filtro,
              periodo_inicial_data: this.getParam("periodo_inicial_data"),
              periodo_final_data: this.getParam("periodo_final_data"),
              periodo_inicial_vigencia_inicio: this.getParam(
                "periodo_inicial_vigencia_inicio"
              ),
              periodo_inicial_vigencia_fim: this.getParam(
                "periodo_inicial_vigencia_fim"
              ),
              periodo_final_vigencia_inicio: this.getParam(
                "periodo_final_vigencia_inicio"
              ),
              periodo_final_vigencia_fim: this.getParam(
                "periodo_final_vigencia_fim"
              ),
              qtd_por_pagina: this.qtd_por_pagina,
              page: this.pagina,
            });
          } else {
            data = await this.atoInfralegalApi.getAtosInfralegais({
              termo: this.termo,
              qtd_por_pagina: this.qtd_por_pagina,
              page: this.pagina,
            });
          }

          this.atosInfralegais = data.resultados;
          this.totalResultados = data.total_resultados;
          this.totalResultadosTexto = data.total_resultados.toLocaleString();
          this.carregando = false;
        } catch (err) {
          if (this.atoInfralegalApi.isCancel(err)) {
            return;
          }
          console.log("carregarAtoInfralegal", err);
          this.carregando = false;
          this.erro = true;
        }
      },
      limparFiltro: function () {
        this.filtro = {
          numero: null,
          conteudo: null,
          tipo_ato_infralegal: undefined,
          categoria_ato_infralegal: undefined,
          orgao_emissor: undefined,
          ementa: null,
          autor: null,
          ano: null,
          periodo_inicial_data: null,
          periodo_final_data: null,
          periodo_inicial_vigencia_inicio: null,
          periodo_final_vigencia_inicio: null,
          periodo_inicial_vigencia_fim: null,
          periodo_final_vigencia_fim: null,
        };
      },
      convertePeriodosDatasParaString: function () {
        return {
          periodo_inicial_data: this.filtro.periodo_inicial_data
            ? format(this.filtro.periodo_inicial_data, "yyyy-MM-dd")
            : null,
          periodo_final_data: this.filtro.periodo_final_data
            ? format(this.filtro.periodo_final_data, "yyyy-MM-dd")
            : null,
          periodo_inicial_vigencia_inicio: this.filtro
            .periodo_inicial_vigencia_inicio
            ? format(this.filtro.periodo_inicial_vigencia_inicio, "yyyy-MM-dd")
            : null,
          periodo_final_vigencia_inicio: this.filtro.periodo_final_vigencia_inicio
            ? format(this.filtro.periodo_final_vigencia_inicio, "yyyy-MM-dd")
            : null,
          periodo_inicial_vigencia_fim: this.filtro.periodo_inicial_vigencia_fim
            ? format(this.filtro.periodo_inicial_vigencia_fim, "yyyy-MM-dd")
            : null,
          periodo_final_vigencia_fim: this.filtro.periodo_final_vigencia_fim
            ? format(this.filtro.periodo_final_vigencia_fim, "yyyy-MM-dd")
            : null,
        };
      },
      convertPeriodosStringParaDatas: function () {
        const periodo_inicial_data = this.getParam("periodo_inicial_data");
        const periodo_final_data = this.getParam("periodo_final_data");
        const periodo_inicial_vigencia_inicio = this.getParam(
          "periodo_inicial_vigencia_inicio"
        );
        const periodo_final_vigencia_inicio = this.getParam(
          "periodo_final_vigencia_inicio"
        );
        const periodo_inicial_vigencia_fim = this.getParam(
          "periodo_inicial_vigencia_fim"
        );
        const periodo_final_vigencia_fim = this.getParam(
          "periodo_final_vigencia_fim"
        );

        return {
          periodo_inicial_data: periodo_inicial_data
            ? parseISO(periodo_inicial_data)
            : null,
          periodo_final_data: periodo_final_data
            ? parseISO(periodo_final_data)
            : null,
          periodo_inicial_vigencia_inicio: periodo_inicial_vigencia_inicio
            ? parseISO(periodo_inicial_vigencia_inicio)
            : null,
          periodo_final_vigencia_inicio: periodo_final_vigencia_inicio
            ? parseISO(periodo_final_vigencia_inicio)
            : null,
          periodo_inicial_vigencia_fim: periodo_inicial_vigencia_fim
            ? parseISO(periodo_inicial_vigencia_fim)
            : null,
          periodo_final_vigencia_fim: periodo_final_vigencia_fim
            ? parseISO(periodo_final_vigencia_fim)
            : null,
        };
      },
      tooglePesquisaAvancada: function () {
        this.exibirPesquisaAvancada = !this.exibirPesquisaAvancada;
      },
      abrirDadosAbertos: function (format) {
        if (
          this.totalResultados == undefined ||
          this.totalResultados == null ||
          this.totalResultados > 1000
        ) {
          alert(
            "O download dos dados é limitado a 1000 registros. Refaça sua consulta utilizando os filtros disponíveis."
          );
        } else {
          const params = new URLSearchParams({
            numero: this.getParam("numero") || "",
            conteudo: this.getParam("conteudo") || "",
            tipo_ato_infralegal: this.getParam("tipo_ato_infralegal") || "",
            categoria_ato_infralegal:
              this.getParam("categoria_ato_infralegal") || "",
            orgao_emissor: this.getParam("orgao_emissor") || "",
            ano: this.getParam("ano") || "",
            periodo_inicial_data: this.getParam("periodo_inicial_data") || "",
            periodo_final_data: this.getParam("periodo_final_data") || "",
            periodo_inicial_vigencia_inicio:
              this.getParam("periodo_inicial_vigencia_inicio") || "",
            periodo_final_vigencia_inicio:
              this.getParam("periodo_final_vigencia_inicio") || "",
            periodo_inicial_vigencia_fim:
              this.getParam("periodo_inicial_vigencia_fim") || "",
            periodo_final_vigencia_fim:
              this.getParam("periodo_final_vigencia_fim") || "",
            termo: this.getParam("termo") || "",  
            ementa: this.getParam("ementa") || "",  
          }).toString();
          window.open(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/pesquisa/atos_infralegais/dados_abertos.${format}?${params}`,
            "_blank"
          );
        }
      },
      armazenarQtdPorPagina() {
        localStorage.setItem(
          "qtd_por_pagina_ato_infralegal",
          this.qtd_por_pagina
        );
      },
      carregarQtdPorPagina() {
        const qtd = localStorage.getItem("qtd_por_pagina_ato_infralegal");
        if (qtd) {
          this.qtd_por_pagina = tryParseInt(qtd);
        }
      },
      getLabelOrgao: function (item) {
        if (item) {
          return item.nome
        }
      },
      getLabelCategoria: function (item) {
        if (item) {
          return item.nome
        }
      },
      filtrarOrgao: function (texto) {
        if (texto) {
          const res = this.orgaosAtoInfralegal.filter(
            hash => hash.nome.toLowerCase().normalize('NFD').includes(texto.toLowerCase().normalize('NFD'))
          );
          this.orgaosAtoInfralegalFiltrados = res.sort((a, b) => a.nome.localeCompare(b.nome));
        }
        else {
          this.orgaosAtoInfralegalFiltrados = this.orgaosAtoInfralegal.slice().sort((a, b) => a.nome.localeCompare(b.nome));
        }
      },
      filtrarCategoria: function (texto) {
        if (texto) {
          const res = this.categoriasAtoInfralegal.filter(
            hash => hash.nome.toLowerCase().normalize('NFD').includes(texto.toLowerCase().normalize('NFD'))
          );
          this.nomesAtoInfralegalFiltrados = res.sort((a, b) => a.nome.localeCompare(b.nome));
        } else {
          this.nomesAtoInfralegalFiltrados = this.categoriasAtoInfralegal.slice().sort((a, b) => a.nome.localeCompare(b.nome));
        }
    },
      atribuiOrgao: function (objeto) {
        if (objeto) {
          this.filtro.orgao_emissor = objeto.id;
          this.orgaosAtoInfralegalFiltrados = null;
        }
      },
      atribuiCategoria: function (objeto) {
        if (objeto) {
          this.filtro.categoria_ato_infralegal = objeto.id;
          this.nomesAtoInfralegalFiltrados = null;
        }
      },    
      compareNames: function (a, b) {
        return a.nome.localeCompare(b.nome);
      } 
    },
    computed: {
      pageCount: function () {
        if (
          this.totalResultados != undefined &&
          this.totalResultados != null &&
          this.qtd_por_pagina != null &&
          this.qtd_por_pagina != undefined &&
          this.totalResultados > 0 &&
          this.qtd_por_pagina > 0
        ) {
          return Math.ceil(this.totalResultados / this.qtd_por_pagina);
        }
        return 0;
      },
    },
  };
</script>

<style lang="scss" scoped>

.busca-info {
  display: block;
  margin: 0 0 1rem 0;
  padding: 0;
  .info-texto {
    margin: .5rem 0;
    color: #ffa900;
    font-size: .93rem;
    span {
      font-weight: bold;
    }
  }
}
.tooltip {
  display: flex;
  width: max-content;
  margin: 1rem 0 1rem 0;
  .button {
    margin: .3rem .7rem 0 0;
    font-size: .9rem;
    background-color: #edf2ee;
    border: none;
    border-radius: .3rem;
    padding: .3rem .5rem;
    box-shadow: 1px 2px 1px #ffa900;
    cursor: pointer;
  }
  .button:hover {
    box-shadow: 0px 0px 0px;
    padding: .27rem .47rem;
    font-size: .87rem;
    background-color: #ffa900;
  }
  .container-tooltip {
    position: relative;
  }
  .modal {
    display: block;
    width: 16rem;
    z-index: 1000;
    position: absolute;
    max-width: 20rem;
    font-size: .9rem;
    background-color: #edf2ee;
    box-shadow: 3px 5px 5px #ffa900;
    padding: .3rem 1rem;
    border-radius: .5rem;

    span {
      font-weight: bold;
    }
  }
  .modal-1 {
    display: none;
  }
  .modal-2 {
    display: none;
  }
  .modal-3 {
    display: none;
  }
  .modal-4 {
    display: none;
  }
}

@media screen and (min-width: 320px) and (max-width: 425px){
  .modal-1 {
    left: -.5rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 0%;
    }
  }
  .modal-2 {
    left: -2.5rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 13%;
    }
  }
  .modal-3 {
    left: -5.5rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 31%;
    }
  }
  .modal-4 {
    left: -9rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 60%;
    }
  }
  .info-tooltip {
    width: 60vw;
    height: max-content;
    position: absolute;
    transform: translateX(-50%);
    background-color: #ffa900;
    padding: 10px;
    border-radius: 4px;
    display: none;
    z-index: 1;
    margin-top: .5rem;
  }

  .info-field:hover .info-tooltip {
    display: block;
  }

  .info-content {
    color: black;
    .exemplo {
      color: #375a3a;
      font-weight: bold;
    }
  }

  div.info-content h2 {
    color: #375a3a;
    border-bottom: #375a3a solid 0.15rem;
    margin-bottom: 1rem;
  }

  .info-content p {
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 426px) and (max-width: 1024px){
  .modal-1 {
    left: -.5rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 0%;
    }
  }
  .modal-2 {
    left: -2.5rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 13%;
    }
  }
  .modal-3 {
    left: -5.5rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 31%;
    }
  }
  .modal-4 {
    left: -9rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 60%;
    }
  }
  .info-tooltip {
    width: 60vw;
    height: max-content;
    position: absolute;
    transform: translateX(-50%);
    background-color: #ffa900;
    padding: 10px;
    border-radius: 4px;
    display: none;
    z-index: 1;
    margin-top: .5rem;
    left: 20vw;
  }

  .info-field:hover .info-tooltip {
    display: block;
  }

  .info-content {
    color: black;
    .exemplo {
      color: #375a3a;
      font-weight: bold;
    }
  }

  div.info-content h2 {
    color: #375a3a;
    border-bottom: #375a3a solid 0.15rem;
    margin-bottom: 1rem;
  }

  .info-content p {
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px){
  .modal {
    width: 22rem !important;
  }
  .modal-1 {
    left: -.5rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 0%;
    }
  }
  .modal-2 {
    left: -2.5rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 11%;
    }
  }
  .modal-3 {
    left: -5.5rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 25%;
    }
  }
  .modal-4 {
    left: -9rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 50%;
    }
  }
  .info-tooltip {
    width: max-content;
    height: max-content;
    position: absolute;
    transform: translateX(-50%);
    background-color: #ffa900;
    padding: 1rem;
    border-radius: 4px;
    display: none;
    z-index: 1;
    margin-top: .5rem;
    left: 25vw;
  }

  .info-field:hover .info-tooltip {
    display: block;
  }

  .info-content {
    color: black;
    .exemplo {
      color: #375a3a;
      font-weight: bold;
    }
  }

  div.info-content h2 {
    color: #375a3a;
    border-bottom: #375a3a solid 0.15rem;
    margin-bottom: 1rem;
  }

  .info-content p {
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 1441px) and (max-width: 2560px){
  .modal {
    width: 22rem !important;
  }
  .modal-1 {
    left: -.5rem;
    top: 5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 0%;
    }
  }
  .modal-2 {
    left: -2.5rem;
    top: 5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 11%;
    }
  }
  .modal-3 {
    left: -5.5rem;
    top: 5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 25%;
    }
  }
  .modal-4 {
    left: -9rem;
    top: 5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 50%;
    }
  }
  .info-tooltip {
    width: max-content;
    height: max-content;
    position: absolute;
    transform: translateX(-50%);
    background-color: #ffa900;
    padding: 1rem;
    border-radius: 4px;
    display: none;
    z-index: 1;
    margin-top: .5rem;
    left: 15vw;
  }

  .info-field:hover .info-tooltip {
    display: block;
  }

  .info-content {
    color: black;
    .exemplo {
      color: #375a3a;
      font-weight: bold;
    }
  }

  div.info-content h2 {
    color: #375a3a;
    border-bottom: #375a3a solid 0.15rem;
    margin-bottom: 1rem;
  }

  .info-content p {
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
  }
}
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.8s;
  -webkit-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.submit-busca-simples {
  cursor: pointer;
}

h1 {
  font-family: "Poppins-Bold";
  color: #2c3f2b;
  padding-left: 0.5rem;
  border-left: #ffa900 solid 0.15rem;
  margin: 2.5rem 0rem 1.5rem 1rem;
  font-size: 1.5rem;
  line-height: 2rem;
}
.pesquise {
  background-color: #375a3a;
  padding: 0.5rem 1rem 1.5rem;
  label {
    color: white;
    font-size: 0.9rem;
  }
  input {
    border: none;
    width: calc(100% - 0.8rem);
    height: 2rem;
    border-radius: 0.2rem;
    border: none;
    padding-left: 0.5rem;
    font-family: "Poppins";
    font-size: 1rem;
    letter-spacing: 0.03rem;
    color: black;
  }
  form {
    .campo-preenchido {
      background-color: #fffed4;
      // border: solid .15rem #ffa900;
      // box-shadow: 0rem 0rem 0rem 0.13rem #ffa900;
      // -moz-box-shadow: 0rem 0rem 0rem 0.13rem #ffa900;
      // -webkit-box-shadow: 0rem 0rem 0rem 0.13rem #ffa900;
    }
  }

  .busca-simples {
    width: calc(100%);
    background-color: white;
    position: relative;
    border-radius: 0.3rem;
    input {
      width: calc(100% - 3rem) !important;
    }
    button {
      height: 100%;
      width: 2.3rem;
      background-color: #2c3f2b;
      border: none;
      margin-left: auto;
      position: absolute;
      top: 0rem;
      right: 0rem;
      border-radius: 0rem 0.2rem 0.2rem 0rem;
      img {
        height: 1.3rem;
      }
    }
  }
  .botao {
    button {
      background-color: transparent;
      border: none;
      font-size: 0.9rem;
      font-family: "Poppins";
      color: #ffa900;
      padding: 0rem;
      letter-spacing: 0.03rem;
      span {
        font-size: 0.9rem;
      }
    }
    button:focus {
      box-shadow: none;
      outline: none;
      cursor: pointer;
    }
  }
  .botoes {
    float: right;
    margin: 1rem 0.2rem 1rem auto;
    button {
      margin-left: 1rem;
      padding: 0.1rem 2rem;
      font-family: "Poppins-Medium";
      font-size: 0.9rem;
    }
    button:first-child {
      background: transparent;
      border: white solid 0.15rem;
      color: white;
      padding: 0.1rem 2rem;
      cursor: pointer;
    }
    button:last-child {
      background-color: #ffa900;
      color: #2c3f2b;
      border: solid 0.15rem #ffa900;
      cursor: pointer;
    }
  }

  select {
    width: calc(100% - 0.2rem);
    height: 2rem;
    border: none;
    border-radius: 0.2rem;
    padding-left: 0.5rem;
    background-color: white;
    font-family: "Poppins";
    font-size: 1rem;
    letter-spacing: 0.04rem;
  }

  h2 {
    color: white;
    font-family: "Poppins-ExtraLight";
    font-size: 1.1rem;
    display: inline-table;
    border-bottom: #ffa900 solid 0.15rem;
    margin: 0rem;
    padding-right: 1rem;
  }
  .row2 {
    display: grid;
    .row2mobile {
      display: grid;
      grid-template-columns: auto auto;
      gap: 1rem;
    }
  }
  .grupo {
    border: #ffa900 solid 0.15rem;
    margin: 0.5rem 0rem;
    padding: 0.5rem 0.5rem;
    border-radius: 0.5rem;
    .data {
      display: grid;
      grid-template-columns: auto auto;
      gap: 1rem;
      .mx-datepicker {
        width: 100%;
      }
    }
    p {
      margin: 0rem;
      padding: 0rem;
      color: white;
    }
  }
  .item {
    display: grid;
    margin: 0.5rem 0rem;
  }
  .seta {
    img {
      width: 5rem;
      position: absolute;
      right: 1.5rem;
      margin-top: 0rem;
    }
    .t-preto {
      display: none;
    }
  }
}
.campo-resultados {
  margin: 1rem;
  h2 {
    color: #2c3f2b;
    font-family: "Poppins-ExtraLight";
    font-size: 1.1rem;
    border-bottom: #ffa900 0.15rem solid;
    margin: 1.5rem 0rem 0rem 1rem;
    display: table;
    padding-right: 1rem;
  }
  .dados-abertos {
    padding-left: 0.5rem;
    width: 15rem;
    margin: 1rem 1rem 0rem 0rem;
    p,
    span {
      margin: 0rem;
      font-size: 0.9rem;
      font-family: "Poppins-ExtraLight";
      a {
        margin: 0rem 0.35rem 0rem 1rem;
        text-decoration: none;
        color: #2c3f2b;
        font-family: "Poppins-Medium";
        cursor: pointer;
      }
    }
    p {
      background-color: #edf2ee;
      padding-left: 0.5rem;
      height: 1.3rem;
    }
  }
  .nav-resultados {
    margin-bottom: 2rem;
  }
  .nresultados {
    margin: 0rem auto 2rem;
    p {
      font-size: 0.9rem;
      margin: 0rem 0rem -2rem 1rem;
      strong {
        font-family: "Poppins-Medium";
      }
    }
  }
  .npagina {
    margin: 0.5rem 1rem 0.5rem 1rem;
    label {
      font-family: "Poppins-Medium";
      font-weight: 900;
      margin-right: 0.5rem;
      color: #2c3f2b;
      font-size: 0.9rem;
    }
    select {
      border: none;
      background-color: #edf2ee;
      font-size: 0.9rem;
      margin-top: 0.5rem;
    }
  }
}

@media screen and (min-width: 31rem) {
  .nav-resultados {
    margin-bottom: 0rem !important;
  }
}
@media screen and (min-width: 64rem) {
  h1 {
    margin-left: 0rem;
    font-size: 1.8rem;
  }
  h2 {
    font-size: 1.3rem !important;
  }

  .pesquise {
    padding-bottom: 2rem !important;

    h2 {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .label-busca {
      text-align: left;
      margin-bottom: 0.3rem;
    }
    .row2 {
      grid-template-columns: calc(50% - 1%) calc(50% - 1%);
      gap: 2%;
      .item {
        input {
          padding-right: 0.25rem;
        }
      }
    }
    .grupo {
      padding: 0.5rem 2rem;
    }
    .botoes {
      margin-right: 0rem;
      margin-top: 0.5rem;
      button {
        font-size: 1rem;
        padding: 0.1rem 4rem !important;
        border-radius: 0.2rem;
      }
    }
    .seta {
      img {
        right: 4.7rem;
        margin-top: 0.5rem;
      }
    }
  }
  .campo-resultados {
    h2 {
      margin-left: 0rem;
    }
    .dados-abertos {
      padding-left: 0.5rem;
      width: 13rem;
      margin: -2rem 1rem 0rem auto;
      text-align: right;
      p,
      span {
        font-size: 0.8rem;
      }
    }
    .npagina {
      text-align: right;
      label {
        font-size: 0.8rem;
      }
    }
    .nresultados {
      max-width: 17rem;
      text-align: left;
      margin: 0rem 0rem 2rem;
      p {
        font-size: 0.8rem;
        margin: -1.5rem 0rem 0rem 0rem;
        text-align: left;
        strong {
          font-family: "Poppins-Medium";
        }
      }
    }
  }
}
.alto-contraste {
  .pesquise {
    background-color: #213b27;
    .seta {
      .t-branco {
        display: none;
      }
      .t-preto {
        display: block;
      }
    }

    .botoes {
      button:last-child {
        background-color: #ffa900;
        color: #0d2b10;
        font-family: "Poppins-Medium";
      }
    }
  }
  .nav-resultados {
    h2 {
      color: white;
    }
  }
  .npagina {
    label {
      color: white !important;
    }
  }
  .nresultados {
    color: white;
  }
}
</style>
