<template>
  <div class="editar-publico">
    <a :href="url" target='_blank' title='Editar Conteúdo'><img src="../assets/images/icon-editar-exibir.svg" class="editar-pagina" /></a>
  </div>
</template>

<script>
export default {
  name: "BotaoEditar",
  props: {
    url: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.editar-publico {
  position: fixed;
  z-index: 9;
  bottom: 1rem;
  bottom: 6rem;
  right: 1.7rem;
  a{
    background-color: white;
    box-shadow: 0rem 0rem 1rem #6b6b6b;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    .editar-pagina{
      height: 1.5rem;
      max-width: none;
      padding: 0rem;
    }
  }
}

@media print {
    .editar-publico {
      display: none;
    }
}
</style>
