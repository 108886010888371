<template>
    <div class="carregar" :class='classeTamanho'>
        <div class="c-loader"></div>
        <p>Carregando...</p>
    </div>
</template>

<script>

// Props: size pode ser 'small', 'medium' ou 'large'
export default {
    name: 'Loading',
    props: {
        size: {
            type: String
        }
    },
    computed: {
        classeTamanho: function() {
            return {
                pequeno: this.size === 'small',
                grande: this.size === 'large',
                medio: this.size === 'medium' || !this.size
            }
        }
    }
}
</script>


<style lang="scss" scoped>
  /* Pedro, cria classes para 3 tamanhos de loading, porque vou permitir que a gente 
    informe qual o tamanho (grande, medio, pequeno) vai ser o loading. */
    .pequeno{
        text-align: center;
        .c-loader {
            animation: is-rotating 1s infinite;
            border: .3rem solid #edf2ee;
            border-radius: 50%;
            border-top-color: #26a42c;
            height: 1.5rem;
            width: 1.5rem;
            margin: auto;
        }

        @keyframes is-rotating {
            to {
                transform: rotate(1turn);
            }   
        }

    }
    .medio{
        text-align: center;
        .c-loader {
            animation: is-rotating 1s infinite;
            border: .6rem solid #edf2ee;
            border-radius: 50%;
            border-top-color: #26a42c;
            height: 3rem;
            width: 3rem;
            margin: 1rem auto;
        }

        @keyframes is-rotating {
            to {
                transform: rotate(1turn);
            }   
        }

    }
    .grande{
        text-align: center;
        .c-loader {
            animation: is-rotating 1s infinite;
            border: .8rem solid #edf2ee;
            border-radius: 50%;
            border-top-color: #26a42c;
            height: 5rem;
            width: 5rem;
            margin: auto;
        }

        @keyframes is-rotating {
            to {
                transform: rotate(1turn);
            }   
        }

    }
</style>

