<template>
  <div>
    <div class="centro">
      <h1>Entenda a Legislação Estadual</h1>
    </div>
    <div class="grupo1">
      <div class="centro">
        <h2>Ciclo de vida da legislação</h2>
        <div class="parte1">
          <div class="numero">
            <img src="../assets/images/um.png" class="um" alt="um" />
          </div>
          <div class="texto">
            <h3>Apresentação de um projeto de lei</h3>

            <p>
              O local para a apresentação de um projeto de lei é o Poder Legislativo e,
              no caso das leis do nosso Estado, é a Assembleia Legislativa de Goiás.
              Para que isso aconteça, o processo de inclusão de uma matéria, chamado de
              poder de iniciativa, precisa ser realizado por alguns dos autores especificados
              no art. 20 da Constituição do Estado de Goiás. São eles:
            </p>
            <div class="row5">
              <div class="item">
                <img src="../assets/images/deputados-estaduais.png" class="deputados" alt=" " />
                <p>
                  Deputados
                  <br />Estaduais
                </p>
              </div>
              <div class="item">
                <img src="../assets/images/governador.png" class="governador" alt=" " />
                <p>
                  Governador
                  <br />do Estado
                </p>
              </div>
              <div class="item">
                <img src="../assets/images/presidente-tjgo.png" class="presidente" alt=" " />
                <p>
                  Presidente
                  <br />do TJGO
                </p>
              </div>
              <div class="item">
                <img src="../assets/images/procurador.png" class="procurador" alt=" " />
                <p>
                  Procurador-Geral
                  <br />de Justiça do MPGO
                </p>
              </div>
              <div class="item">
                <img src="../assets/images/iniciativa-popular.png" class="iniciativa" alt=" " />
                <p>
                  Iniciativa
                  <br />Popular
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="parte2">
          <div class="numero">
            <img src="../assets/images/dois.png" class="dois" alt="dois" />
          </div>
          <div class="texto">
            <h3>Votação do projeto de lei</h3>

            <p>
              Após ter o seu trâmite iniciado na Assembleia Legislativa de Goiás,
              o projeto de lei é avaliado por comissões e pelo Plenário. A essa fase
              dá-se o nome de deliberação legislativa, na qual os Deputados Estaduais
              definem a continuidade ou não desse projeto por meio de:
            </p>
            <div class="row2">
              <div class="item">
                <img src="../assets/images/aprovado.png" class="aprovado" alt=" " />
                <p>Aprovado</p>
              </div>
              <div class="item">
                <img src="../assets/images/rejeitado.png" class="rejeitado" alt=" " />
                <p>Rejeitado</p>
              </div>
            </div>
          </div>
        </div>
        <div class="parte3">
          <div class="numero">
            <img src="../assets/images/tres.png" class="tres" alt="tres" />
          </div>
          <div class="texto">
            <h3>Votação do projeto de lei</h3>

            <p>
              Caso seja aprovado, o projeto se torna um autógrafo de lei e é
              enviado ao Poder Executivo para que o Governador do Estado faça
              sua análise, iniciando a fase de deliberação executiva. Nesse
              momento, com fundamento nos arts. 10 e 23 da Constituição do
              Estado de Goiás, ele pode:
            </p>
            <div class="row3">
              <div class="item">
                <img src="../assets/images/sancionar.png" class="sancionar" alt=" " />
                <p>Sancionar</p>
              </div>
              <div class="item">
                <img
                  src="../assets/images/vetar-parcialmente.png"
                  class="vetar-parcialmente"
                  alt=" "
                />
                <p>
                  Vetar
                  <br />Parcialmente
                </p>
              </div>
              <div class="item">
                <img src="../assets/images/vetar.png" class="vetar" alt=" " />
                <p>Vetar</p>
              </div>
            </div>
            <div class="texto-veto">
              <p>
                Com o veto, a matéria retorna ao Legislativo, que fará nova votação
                para decidir sobre a sua manutenção ou derrubada.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="grupo2 centro">
      <h2>Tipos de legislações estaduais</h2>
      <p class="topo">
        A Constituição do Estado de Goiás estabelece em seu art. 18 que o processo legislativo
        abrange a elaboração de emendas à Constituição, leis complementares, leis ordinárias,
        leis delegadas, decretos legislativos e resoluções. Cada um desses tipos de legislação
        será exposto a seguir.
      </p>
      <div class="card esquerdo emenda-constitucional">
        <label for="emenda">
          <h3>
            Emenda Constitucional
            <span>{{ abertos[0] ? '&#9650;' : '&#9660;'}}</span>
          </h3>
        </label>
        <input type="checkbox" id="emenda" v-model="abertos[0]" />
        <p>
          A emenda constitucional está prevista no art. 19, da Constituição do Estado de Goiás.
          Ela tem por finalidade modificar o próprio texto constitucional e pode ser apresentada
          pelo Governador do Estado, por um terço dos deputados estaduais, por mais da metade das
          Câmaras Municipais do Estado, desde que cada uma delas se manifeste pela maioria relativa
          de seus membros, e por cidadãos, desde que subscrita por, no mínimo, um por cento do eleitorado
          do Estado em vinte Municípios. Sua proposta, também denominada PEC, possui um rito diferenciado
          de tramitação, com a necessidade de aprovação por três quintos dos votos dos deputados estaduais,
          em dois turnos de votação. A promulgação desse tipo de ato, por sua vez, é realizada pela Mesa
          da Assembleia Legislativa de nosso Estado.
        </p>
      </div>
      <div class="card esquerdo lei-complementar">
        <label for="complementar">
          <h3>
            Lei Complementar
            <span>{{ abertos[1] ? '&#9650;' : '&#9660;'}}</span>
          </h3>
        </label>
        <input type="checkbox" id="complementar" v-model="abertos[1]" />
        <p>
          A lei complementar é voltada, exclusivamente, para temas especificados na Constituição 
          do Estado de Goiás, regulamentando alguns assuntos dela constantes. Por isso, para a sua 
          aprovação, o quórum é de maioria absoluta dos membros da Assembleia Legislativa (3º do art. 18, da CE/89).
        </p>
      </div>
      <div class="card esquerdo lei-ordinaria">
        <label for="ordinaria">
          <h3>
            Lei Ordinária
            <span>{{ abertos[2] ? '&#9650;' : '&#9660;'}}</span>
          </h3>
        </label>
        <input type="checkbox" id="ordinaria" v-model="abertos[2]" />
        <p>
          A lei ordinária pode tratar de diversos assuntos, regulamentando quase tudo que é de competência
          do Estado. Afinal, ela dispõe de uma infinidade de matérias que se inserem dentro das atribuições
          estaduais, seja em concorrência com a União, seja residualmente (art. 24 e § 1º do art. 25 da
          Constituição Federal). Sua aprovação se dá pela maioria simples dos membros do Legislativo; já
          sua apresentação pode ocorrer por proposição de iniciativa do Governador, dos deputados, de alguma
          das comissões da Assembleia Legislativa, do Presidente do Tribunal de Justiça, do Procurador-Geral
          de Justiça do Ministério Público do Estado de Goiás ou dos cidadãos
        </p>
      </div>
      <div class="card esquerdo lei-delegada">
        <label for="delegada">
          <h3>
            Lei Delegada
            <span>{{ abertos[3] ? '&#9650;' : '&#9660;'}}</span>
          </h3>
        </label>
        <input type="checkbox" id="delegada" v-model="abertos[3]" />
        <p>
          A lei delegada é um ato normativo proposto, exclusivamente, pelo Chefe do Poder Executivo,
          que solicita concessão especial ao Poder Legislativo, ou seja, uma delegação da Assembleia
          Legislativa do Estado de Goiás para se poder elaborar uma lei. Por meio de uma resolução
          legislativa, são fixados o conteúdo e os termos da matéria, cujo texto não admite emendas.
          Entretanto, o art. 24 da Constituição do Estado de Goiás proíbe expressamente que alguns
          assuntos se tornem objetos de lei delegada, como atos de competência privativa da Assembleia,
          matéria reservada à lei complementar e temas relacionados com a organização do Judiciário, dos
          Tribunais de Contas do Estado de Goiás, Tribunal de Contas dos Municípios e do Ministério Público.
        </p>
      </div>
      <div class="card esquerdo decreto-legislativo">
        <label for="legislativo">
          <h3>
            Decreto Legislativo
            <span>{{ abertos[4] ? '&#9650;' : '&#9660;'}}</span>
          </h3>
        </label>
        <input type="checkbox" id="legislativo" v-model="abertos[4]" />
        <p>
          O decreto legislativo tem por objetivo dar cumprimento às atribuições exclusivas do Poder Legislativo,
          portanto a iniciativa para sua elaboração decorre dos próprios membros desse órgão de Poder, sem a
          necessidade de sanção pelo Chefe do Poder Executivo. De acordo com o Regimento Interno da Assembleia
          Legislativa do Estado de Goiás, esse tipo de ato é utilizado especialmente para o exercício da função
          fiscalizadora, como a tomada de contas do Estado (§ 2º do art. 156), a aprovação da indicação de
          conselheiros dos Tribunais de Contas (art. 172) e de membros do alto escalão de autarquias e agências
          executivas (§ 1º do art. 174),a representação em crimes de responsabilidade (§ 3º do art. 181) e a sustação
          de ação penal contra deputados estaduais (§ 3º do art. 212).
        </p>
      </div>
      <div class="card esquerdo resolucoes">
        <label for="resolucoes">
          <h3>
            Resoluções
            <span>{{ abertos[5] ? '&#9650;' : '&#9660;'}}</span>
          </h3>
        </label>
        <input type="checkbox" id="resolucoes" v-model="abertos[5]" />
        <p>
          As resoluções são propostas para regulamentar as matérias de competência privativa do Poder Legislativo,
          com exclusão daquelas em que se exige a elaboração de decreto legislativo. Assim, sua ocorrência serve,
          quase totalmente, para assuntos internos de caráter político, processual e administrativo, motivo pelo
          qual se sujeitam a um único turno de discussão e votação (art. 134 do Regimento Interno da ALEGO).
          O inciso XV do art. 11 da Constituição do Estado de Goiás traz alguns exemplos de atos instrumentalizados
          via esse ato, como a elaboração de regimento interno da Assembleia Legislativa, a organização e o
          funcionamento da polícia legislativa, a criação, a transformação ou a extinção de cargos, empregos e
          funções da Secretaria desse órgão de Poder, bem como a fixação ou a alteração de sua remuneração ou subsídio.
        </p>
      </div>
    </div>
    <div class="grupo3">
      <div class="centro">
        <h2>Como se estruturam as leis</h2>
        <div>
          <p>
            A legislação goiana está estruturada de acordo com a Lei Complementar no 33, de 1º de agosto de 2001.
            Regulamentada pelo Decreto no 9697, de 16 de julho de 2020, ela dispõe sobre a elaboração, a redação,
            alteração e a consolidação dos atos normativos no Estado de Goiás.
          </p>
          <p>
            O texto de nossa legislação é formulado a partir de uma estrutura que parte de componentes maiores para
            menores. Assim, comumente, artigos, numerados com ordinais até o 10º e com cardinais a partir daí, dão
            lugar a parágrafos, também numerados com ordinais até o 10º e com cardinais na sequência. Na indicação
            de parágrafos que estão numa série, usa-se o símbolo “§” [§ 1º § 2º ... § 15.] para cada um deles.
            Caso no artigo só haja um parágrafo, será usado “Parágrafo único”.
          </p>
          <p>
            Os artigos ou os parágrafos podem se desdobrar em incisos, numerados com algarismos romanos acompanhados
            de travessões [I – ; II – ... XV –]. Os incisos, por sua vez, podem ser desdobrar em alíneas, marcadas
            pelas letras do alfabeto sequenciadas e acompanhadas de parênteses [a); b) ... h)]. As alíneas, por seu
            turno, também podem dar lugar a itens, que serão numerados com cardinais acompanhados de pontos
            [1. 2. ... 8.].
          </p>
          <p>
            Com o estabelecimento dessas regras de estruturação, a legislação goiana ganha maior padronização e
            qualidade. Além disso, evitam-se por meio delas incongruências na sua elaboração.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import metaDados from "../helpers/metaDados";

export default {
  name: "EntendaLegislacao",
  metaInfo() {
    return {
      title: "Entenda a Legislação",
      titleTemplate: "%s - Casa Civil do Estado de Goiás",
      meta: [
        {
          vmid: "description",
          name: "description",
          property: "description",
          content:
            "Página do Governo do Estado de Goiás que explica como uma legislação é criada, quais os tipos que podem existir e a descrição de cada tipo."
        },
        {
          vmid: "og:description",
          property: "og:description",
          content:
            "Página do Governo do Estado de Goiás que explica como uma legislação é criada, quais os tipos que podem existir e a descrição de cada tipo."
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: "Entenda a Legislação - Casa Civil do Estado de Goiás"
        },
        {
          vmid: "og:type",
          property: "og:type",
          content: "article"
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          property: "twitter:description",
          content:
            "Página do Governo do Estado de Goiás que explica como uma legislação é criada, quais os tipos que podem existir e a descrição de cada tipo."
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          property: "twitter:title",
          content: "Entenda a Legislação - Casa Civil do Estado de Goiás"
        },
        ...metaDados(this.$route)
      ]
    };
  },
  data() {
    return {
      abertos: {}
    };
  }
};
</script>

<style lang="scss" scoped>
p {
  text-indent: 2rem;
}

h1 {
  font-family: "Poppins-Bold";
  color: #2c3f2b;
  padding-left: 0.5rem;
  border-left: #ffa900 solid 0.15rem;
  margin: 2.5rem 0rem 1.5rem 1rem;
  font-size: 1.5rem;
  line-height: 2rem;
}
h2 {
  font-family: "Poppins-ExtraLight";
  font-size: 1.1rem;
  display: table;
  border-bottom: #ffa900 0.15rem solid;
  margin: 1.5rem 0rem 0rem 0.5rem;
}
.grupo1 {
  background-color: #375a3a;
  padding: 0.5rem 0.5rem;
  h2 {
    color: white;
    padding-right: 1rem;
    margin-bottom: 3rem;
  }
  h3 {
    font-family: "Poppins-Bold";
    font-size: 1.1rem;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  .um,
  .dois,
  .tres {
    height: 8rem;
    margin-top: 0.5rem;
  }
  .um {
    margin-left: 1rem;
  }
  .parte1 {
    display: grid;
    grid-template-columns: 5.6rem auto;
    margin: 1rem 0.5rem;
    color: white;
    strong {
      font-family: "Poppins-Bold";
      font-size: 0.9rem;
    }
    .texto {
      margin: 0rem 0.5rem 0rem 1rem;
      p {
        margin: 0rem 0rem 0.5rem;
        font-size: 0.9rem;
      }
      .row5 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        .item {
          text-align: center;
          img {
            height: 3rem;
          }
          p {
            font-family: "Poppins-Medium";
            text-indent: 0rem;
          }
        }
      }
    }
  }
  .parte2 {
    display: grid;
    grid-template-columns: 5.6rem auto;
    margin: 2rem 0.5rem;
    color: white;
    strong {
      font-family: "Poppins-Bold";
      font-size: 0.9rem;
    }
    .texto {
      margin: 0rem 0.5rem 0rem 1rem;
      p {
        margin: 0rem 0rem 0.5rem;
        font-size: 0.9rem;
      }
      .row2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        .item {
          text-align: center;
          img {
            height: 3rem;
          }
        }
      }
    }
  }
  .parte3 {
    display: grid;
    grid-template-columns: 5.6rem auto;
    margin: 2rem 0.5rem;
    color: white;
    strong {
      font-family: "Poppins-Bold";
      font-size: 0.9rem;
    }
    .texto {
      margin: 0rem 0.5rem 0rem 1rem;
      p {
        margin: 0rem 0rem 0.5rem;
        font-size: 0.9rem;
      }
      .row3 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        .item {
          text-align: center;
          img {
            height: 3rem;
          }
        }
      }
    }
    .texto-veto {
      margin-left: 0rem;
    }
  }
}
.grupo2 {
  h2 {
    font-size: 1.1rem;
    display: table;
    color: #375a3a;
    padding-right: 1rem;
  }
  p {
    font-size: 0.9rem;
  }
  .topo {
    margin: 2rem 0.5rem;
    text-indent: 2rem;
    color: #375a3a;
  }
  .card {
    background-color: #edf2ee;
    margin: 1rem 0.5rem;
    padding: 0.5rem;
    border-left: #ffa900 solid 0.15rem;
    color: #375a3a;
    h3 {
      margin: 0rem;
      font-family: "Poppins-Bold";
      position: relative;
      cursor: pointer;
      font-size: 1rem;
      span {
        position: absolute;
        right: 0.2rem;
      }
      .atv {
        display: none;
      }
    }
    input {
      display: none;
    }
    p {
      height: 0rem;
      overflow: hidden;
      margin: 0rem;
    }
  }
  .emenda-constitucional {
    input {
      display: none;
    }
    input:checked ~ p {
      height: 100%;
      margin: 0.5rem 0rem;
    }
  }
  .lei-complementar {
    input {
      display: none;
    }
    input:checked ~ p {
      height: 100%;
      margin: 0.5rem 0rem;
    }
  }
  .lei-ordinaria {
    input {
      display: none;
    }
    input:checked ~ p {
      height: 100%;
      margin: 0.5rem 0rem;
    }
  }
  .lei-delegada {
    input {
      display: none;
    }
    input:checked ~ p {
      height: 100%;
      margin: 0.5rem 0rem;
    }
  }
  .decreto-legislativo {
    input {
      display: none;
    }
    input:checked ~ p {
      height: 100%;
      margin: 0.5rem 0rem;
    }
  }
  .resolucoes {
    input {
      display: none;
    }
    input:checked ~ p {
      height: 100%;
      margin: 0.5rem 0rem;
    }
  }
  .decretos {
    input {
      display: none;
    }
    input:checked ~ p {
      height: 100%;
      margin: 0.5rem 0rem;
    }
  }
}
.grupo3 {
  background-color: #edf2ee;
  h2 {
    color: #375a3a;
    display: table;
    padding-right: 1rem;
    padding-top: 1rem;
  }
  p {
    margin: 0rem;
    padding: 1rem 0.5rem;
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 64rem) {
  h1 {
    font-size: 1.8rem;
    margin: 2.5rem 0rem 1.5rem 0rem;
  }
  .um,
  .dois,
  .tres {
    height: 10rem;
  }
  .grupo1 {
    h2 {
      width: 21rem;
      font-size: 1.5rem;
    }

    .parte1 {
      grid-template-columns: 6rem auto !important;
      gap: 1rem;
      strong {
        font-size: 1.2rem !important;
      }
      .texto {
        text-align: justify;
        line-height: 1.7rem;
        p {
          font-size: 1rem;
        }
        .row5 {
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important;
          margin: 2rem 0rem;
          .item {
            img {
              height: 4rem !important;
            }
          }
        }
      }
    }
  }
  .parte2 {
    grid-template-columns: 6rem auto !important;
    gap: 1rem;
    strong {
      font-size: 1.2rem !important;
    }
    .texto {
      text-align: justify;
      line-height: 1.7rem;
      p {
        font-size: 1rem !important;
      }
      .row2 {
        margin: 2rem 0rem;
        .item {
          text-align: center;
          img {
            height: 4rem !important;
          }
        }
      }
    }
  }
  .parte3 {
    grid-template-columns: 6rem auto !important;
    gap: 1rem;
    strong {
      font-size: 1.2rem !important;
    }
    .texto {
      text-align: justify;
      line-height: 1.7rem;
      p {
        font-size: 1rem !important;
      }
      .row3 {
        margin: 2rem 0rem;
        grid-template-columns: 1fr 1fr 1fr !important;
        .item {
          img {
            height: 4rem !important;
          }
        }
      }
    }
  }
  .grupo2 {
    margin-top: 2rem !important;
    h2 {
      width: 24rem;
      font-size: 1.3rem;
    }
    p {
      line-height: 1.7rem;
      font-size: 1rem;
    }
    .card {
      padding: 1rem 3rem;
      h3 {
        cursor: default;
        font-size: 1.1rem;
        span {
          display: none;
        }
      }
      p {
        height: 100%;
        overflow: hidden;
        margin: 0.5rem 0rem;
        text-align: justify;
      }
    }
    .direito {
      border-right: #ffa900 0.15rem solid;
      border-left: none;
      text-align: right;
    }
  }
  .grupo3 {
    padding-top: 0.5rem;
    padding-bottom: 2rem;
    margin-top: 3rem;
    text-align: justify;
    h2 {
      width: 21rem;
      margin-top: 1rem;
      font-size: 1.5rem;
    }
    p {
      font-size: 1rem;
    }
  }
}
.alto-contraste {
  .grupo1 {
    background-color: #213b27;
  }
  .grupo2 {
    h2 {
      color: white;
    }
    .topo {
      color: white;
    }
    .card {
      background-color: #202020;
      h3 {
        color: #ffa900;
      }
      p {
        color: white;
      }
    }
  }
  .grupo3 {
    background-color: #213b27;
    h2 {
      color: white;
    }
    p {
      color: white;
    }
  }
}
</style>