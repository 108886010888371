<template>
  <div>
    <div v-if="projeto.legislacao && projeto.legislacao.numero">
      <p>
        <strong>Legislação:&nbsp;</strong>
        <router-link
          :to="{name: 'PesquisaLegislacaoShow', params: {id: projeto.legislacao.id}}"
          target="_blank"
        >{{ legislacao }}</router-link>
      </p>
    </div>

    <div v-if="projeto.processo_sei_oficio_msg">
      <p>
        <strong>Processo SEI:&nbsp;</strong>
        {{projeto.processo_sei_oficio_msg}}
      </p>
    </div>

    <div v-if="projeto.numero_oficio_veto">
      <p>
        <strong>Número do Ofício de Veto:&nbsp;</strong>
        <span v-if="!projeto.mensagem_veto_id">{{ projeto.numero_oficio_veto }}</span>
        <router-link
          v-if="projeto.mensagem_veto_id"
          :to="{ name: 'PesquisaMensagemVetoShow', params: { id: projeto.mensagem_veto_id }}"
          target="_blank"
        >{{ projeto.numero_oficio_veto }}</router-link>
      </p>
    </div>

    <div v-if="projeto.data_oficio_veto">
      <p>
        <strong>Data do Ofício de Veto:&nbsp;</strong>
        {{ converterData(projeto.data_oficio_veto) }}
      </p>
    </div>

    <div v-if="projeto.tipo_veto">
      <p>
        <strong>Tipo de veto:&nbsp;</strong>
        {{ projeto.tipo_veto }}
      </p>
    </div>
  </div>
</template>

<script>
import { parseISO, format } from "date-fns";

export default {
  name: "DetalhesProjeto",
  props: {
    projeto: {
      type: Object,
      required: true
    }
  },
  methods: {
    converterData: function(data) {
      if (!data) return "";

      return format(parseISO(data), "dd/MM/yyyy");
    }
  },
  computed: {
    legislacao: function() {
      return `${this.projeto.legislacao.tipo_legislacao.nome} ${this.projeto.legislacao.numero}`;
    }
  }
};
</script>

<style lang="scss" scoped>
strong {
  font-family: "Poppins-Medium";
  color: #0a4a22;
}
.alto-contraste {
  strong {
    color: #ffa900;
  }
}
</style>
