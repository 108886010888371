import axios from "axios";
import axiosInstance from "./axiosInstance";

const queryString = require("query-string");

class MensagemVetoApi {
  cancelTokens = {
    getMensagensVeto: null,
    getMensagemVeto: null,
    getTiposVeto: null,
    getAnos: null,
    getTiposVetoAgrupado: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getMensagensVeto({
    page = 1,
    qtd_por_pagina,
    tipo_veto,
    tipo_legislacao,
    texto,
    numero,
    ano,
    periodo_inicial,
    periodo_final,
  }) {
    const query = queryString.stringify({
      tipo_veto,
      tipo_legislacao,
      texto,
      numero,
      ano,
      periodo_inicial,
      periodo_final,
      page,
      qtd_por_pagina,
    });

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getMensagensVeto = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/pesquisa/mensagens_veto?${query}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getMensagensVeto = null;

    return data;
  }

  async getMensagemVeto(mensagemVetoId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getMensagemVeto = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/pesquisa/mensagens_veto/${mensagemVetoId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getMensagemVeto = null;

    return data;
  }

  async getTiposVeto() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getTiposVeto = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/pesquisa/mensagens_veto/tipos_de_veto`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getTiposVeto = null;

    return data;
  }

  async getAnos() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getAnos = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/pesquisa/mensagens_veto/anos`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getAnos = null;

    return data;
  }

  async getTiposVetoAgrupado() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getTiposVetoAgrupado = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/pesquisa/mensagens_veto/tipos_de_veto_agrupados`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getTiposVetoAgrupado = null;

    return data;
  }

  async getTiposLegislacaoAgrupados() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getTiposLegislacaoAgrupados = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/pesquisa/mensagens_veto/tipos_de_legislacao_agrupados`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getTiposLegislacaoAgrupados = null;

    return data;
  }
}

export default MensagemVetoApi;
