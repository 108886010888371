<template>
  <div class="painel">
    <h3 class="centro">{{ titulo }}</h3>
    <div
      v-for="dado in dados"
      :key="dado.id"
      class="item centro"
      :data-group-id="groupId(dado)"
      :id="groupId(dado)"
    >
      <details>
        <summary>
          <span>
            {{ dado.nome }}
            <i>({{ dado.quantidade }})</i>
          </span>
        </summary>
        <div class="esconder">
          <router-link
            :to="generateUrl(ano, dado)"
            v-for="ano in dado.anos"
            :key="ano"
            >{{ ano }}</router-link
          >
          <router-link :to="generateUrl(null, dado)">TODOS OS ANOS</router-link>
        </div>
      </details>
    </div>
    <div class="sem-resultados" v-if="dados.length <= 0">
      Nenhum resultado encontrado
    </div>
    <div class="sumario" v-if="exibirIndice">
      <template v-for="indice in barraIndice">
        <span
          v-if="desabilitado(indice)"
          :key="indice"
          class="link-desabilitado"
          >{{ indice }}</span
        >
        <a
          :href="'#' + indice"
          :class="{ active: indice == itemAtivo }"
          :key="indice"
          v-else
          >{{ indice }}</a
        >
      </template>
    </div>
  </div>
</template>

<script>
const accents = require("remove-accents");

export default {
  name: "PainelBuscaSimplificada",
  props: {
    titulo: {
      type: String,
      required: true,
    },
    dados: {
      type: Array,
      required: true,
    },
    generateUrl: {
      type: Function,
      required: true,
    },
    exibirIndice: {
      type: Boolean,
    },
    // funcao que vai receber os dados e retorna a string utilizada para o indice
    // ex: recebe dado que tem nome de dep no formato 'DEP. FULANO' e retorna string 'FULANO'
    idProIndice: {
      type: Function,
    },
  },
  data() {
    return {
      itemAtivo: "A",
      habilitados: new Set(),
      barraIndice: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
    };
  },
  created() {
    for (let dado of this.dados) {
      this.habilitados.add(this.groupId(dado));
    }
    window.addEventListener("scroll", this.findFirstElementInViewPort);
  },
  destroyed() {
    window.removeEventListener("scroll", this.findFirstElementInViewPort);
  },
  mounted() {
    this.findFirstElementInViewPort();
    const { hash } = this.$route;
    if (hash) {
      this.$scrollTo(hash, 0, {
        offset: 0,
        cancelable: false,
      });
    }
  },
  methods: {
    groupId(dado) {
      const nome = this.idProIndice ? this.idProIndice(dado) : dado.nome;
      if (nome) {
        const indice = accents.remove(nome[0]);
        return indice;
      }
      return "";
    },
    isElementInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    findFirstElementInViewPort() {
      let items = document.querySelectorAll(".item");
      for (let i = 0; i < items.length; i++) {
        if (this.isElementInViewport(items[i])) {
          const { groupId } = items[i].dataset;
          if (groupId) {
            this.itemAtivo = groupId;
            return;
          }
        }
      }
    },
    desabilitado(valor) {
      return !this.habilitados.has(valor);
    },
  },
};
</script>

<style lang="scss" scoped>
.sumario {
  display: grid;
  position: fixed;
  z-index: 100;
  right: 0.5rem;
  top: 50%;
  background-color: rgba(90, 90, 90, 0.3);
  padding: 0rem 0rem;
  text-align: center;
  border-radius: 1rem;
  transform: translateY(-50%);
  width: 1.5rem;
  a {
    text-decoration: none;
    color: black;
    cursor: pointer;
    font-size: 1.75vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .active {
    background-color: #375a3a;
    // width: 1.5rem;
    height: 1.5rem;
    color: #ffa900;
    border-radius: 0.75rem;
  }

  .link-desabilitado {
    cursor: not-allowed;
    font-size: 1.75vh;
    color: gray;
  }
}
.sem-resultados {
  background-color: transparent !important;
  text-align: center;
  height: 5rem;
  color: rgb(73, 73, 73);
  font-style: oblique;
}
.painel {
  margin-bottom: 3rem;
}
h3 {
  font-size: 1rem;
  color: white;
  padding: 0.2rem 0.5rem;
  background-color: #368d49;
}
.item {
  padding: 0.8rem 0.5rem;
  text-transform: capitalize;
  span {
    font-family: "Poppins-Medium";
  }
  .esconder {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    padding: 1rem 0rem;
    width: calc(100% - 3.5rem);
    a {
      margin: 0.3rem 0.3rem;
      padding: 0.1rem 0.7rem;
      border: solid 0.08rem #375a3a;
      text-decoration: none;
      color: #375a3a;
      border-radius: 0.5rem;
      font-size: 0.9rem;
      font-weight: 500;
      letter-spacing: 0.05rem;
      font-family: "Poppins-Regular";
    }
    a:hover {
      background-color: #375a3a;
      color: white;
    }
  }
}
details {
  summary {
    cursor: pointer;
  }
}

details[open] summary ~ * {
  animation: open 0.3s ease-in-out;
}

@keyframes open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
details summary::-webkit-details-marker {
  display: none;
}

details summary {
  width: 100%;
  position: relative;
  cursor: pointer;
  font-weight: 300;
  list-style: none;
}

details summary:after {
  content: "+";
  color: black;
  position: absolute;
  font-size: 1.75rem;
  line-height: 0;
  top: 0.75rem;
  right: -1rem;
  font-weight: 200;
  transform-origin: center;
  transition: 200ms linear;
}
details[open] summary:after {
  transform: rotate(45deg);
}
details summary {
  outline: 0;
  width: calc(100% - 3rem);
}
.item:nth-child(odd) {
  background-color: #f7f7f7;
}
.alto-contraste {
  h3 {
    background-color: #19381c;
  }
  .item:nth-child(odd) {
    background-color: #202020;
  }
  span {
    color: white;
  }
  details summary:after {
    color: white;
  }
  .esconder {
    a {
      margin: 0.3rem 0.3rem;
      padding: 0.1rem 0.7rem;
      border: solid 0.08rem #ffa900;
      text-decoration: none;
      color: #ffa900;
      border-radius: 0.5rem;
      font-size: 0.9rem;
      font-weight: 500;
      letter-spacing: 0.05rem;
    }
    a:hover {
      background-color: #ffa900;
      color: white;
    }
  }
  .sumario {
    a {
      color: white;
    }
    .active {
      color: #ffa900;
    }
  }
}
</style>