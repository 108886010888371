<template>
  <div class="campo">
    <Loading v-if="removendo" />
    <ErroResultados
      class="error"
      texto="Houve um erro ao descadastrar o email"
      v-if="!removendo && erro"
    />
    <div class="mensagem" v-if="!mostrarModal && !erro && !removendo">
      <img src="../assets/images/email-no.png" class="email" alt=" " />
      <p>Email descadastrado com sucesso!</p>
    </div>
    <ModalConfirmacao
      v-if="mostrarModal"
      :email="email"
      @confirmar="descadastrarEmail"
      @cancelar="fecharModal"
    />
  </div>
</template>

<script>
import Loading from "../components/Loading";
import ErroResultados from "../components/ErroResultados";
import EmailAcompanhamentoPublicacaoApi from "../services/EmailAcompanhamentoPublicacaoApi";
import metaDados from "../helpers/metaDados";
import ModalConfirmacao from "../components/ModalConfirmacao";

export default {
  name: "DescadastroAcompanhamentoPublicacoes",
  metaInfo() {
    return {
      title: "Descadastro de email para acompanhamento de publicações",
      titleTemplate: "%s - Casa Civil do Estado de Goiás",
      meta: [
        {
          vmid: "description",
          name: "description",
          property: "description",
          content: "Descadastro de email para acompanhamento de publicações",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: "Descadastro de email para acompanhamento de publicações",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content:
            "Descadastro de email para acompanhamento de publicações - Casa Civil do Estado de Goiás",
        },
        {
          vmid: "og:type",
          property: "og:type",
          content: "article",
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          property: "twitter:description",
          content:
            "Descadastro de email para acompanhamento de publicações - Casa Civil do Estado de Goiás",
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          property: "twitter:title",
          content:
            "Descadastro de email para acompanhamento de publicações - Casa Civil do Estado de Goiás",
        },
        ...metaDados(this.$route),
      ],
    };
  },
  data() {
    return {
      removendo: false,
      erro: false,
      email: this.$route.query.email,
      mostrarModal: true,
    };
  },
  created() {
    this.emailAcompanhamentoPublicacaoApi = new EmailAcompanhamentoPublicacaoApi();
  },
  components: {
    Loading,
    ErroResultados,
    ModalConfirmacao,
  },
  methods: {
    async descadastrarEmail() {
      try {
        this.removendo = true;
        this.erro = false;
        this.mostrarModal = false;
        await this.emailAcompanhamentoPublicacaoApi.removerEmail({
          email: this.email,
        });
        this.removendo = false;
      } catch (err) {
        console.log(err);
        this.erro = true;
        this.removendo = false;
      }
    },
    fecharModal() {
      alert("Ação cancelada pelo usuário.");
      this.mostrarModal = false;
      this.$router.push('/')
    },
  },
};
</script>

<style lang="scss" scoped>
.campo {
  min-height: calc(100vh - 5rem);
  display: flex;
  justify-content: center;
  align-items: center;
  .error {
    color: red;
  }
  .mensagem {
    text-align: center;
    .email {
      height: 5rem;
    }
    p {
      font-size: 1.5rem;
      padding: 0 1rem;
      font-family: "Poppins-Bolder";
      color: #375a3a;
    }
  }
}
@media screen and (min-width: 64rem) {
  .campo {
    min-height: 50vh;
  }
}
.alto-contraste {
  .campo {
    .mensagem {
      p {
        color: white;
      }
    }
  }
}
</style>
