<template>
  <div class="grupo-parametros">
    <div v-for="param in parametrosDeBusca" :key="param.key.toString()">
      <BotaoParametroBusca
        :label="param.label"
        :valor="param.valor"
        :onClick="(event) => onClick(event, param)"
      />
    </div>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";

import BotaoParametroBusca from "../BotaoParametroBusca";

export default {
  name: "ParametrosBusca",
  props: {
    funcaoBuscaSimples: {
      type: Function,
      required: true,
    },
    funcaoBuscaAvancada: {
      type: Function,
      required: true,
    },
    tiposAtoInfralegal: {
      type: Array,
      required: true,
    },
    categorias: {
      type: Array,
      required: true,
    },
    orgaos: {
      type: Array,
      required: true,
    },
    filtro: {
      type: Object,
      required: true,
    },
  },
  components: {
    BotaoParametroBusca,
  },
  methods: {
    onClick: function (event, parametro) {
      const keys = Array.isArray(parametro.key)
        ? parametro.key
        : [parametro.key];
      for (let key of keys) {
        this.filtro[key] = undefined;
      }

      if (parametro.key === "termo") {
        this.funcaoBuscaSimples(event);
      } else {
        this.funcaoBuscaAvancada(event);
      }
    },
  },
  computed: {
    parametrosDeBusca() {
      //parametros de busca avancada
      const labelsPermitidas = [
        "numero",
        "conteudo",
        "tipo_ato_infralegal",
        "categoria_ato_infralegal",
        "ano",
        "orgao",
        "ementa",
      ];

      const keys = Object.keys(this.$route.query).filter((key) =>
        labelsPermitidas.includes(key)
      );

      let parametros = [];
      const labels = {
        numero: {
          label: "Número",
          valor: (valor) => valor,
        },
        conteudo: { label: "Texto", valor: (valor) => valor },
        ementa: { label: "Ementa", valor: (valor) => valor },
        tipo_ato_infralegal: {
          label: "Tipo",
          valor: (valor) => {
            const t = this.tiposAtoInfralegal.find(
              (tipo) => parseInt(tipo.id) === parseInt(valor)
            );
            if (t) {
              return t.nome;
            }
            return "";
          },
        },
        categoria_ato_infralegal: {
          label: "Categoria",
          valor: (valor) => {
            const c = this.categorias.find(
              (cat) => parseInt(cat.id) === parseInt(valor)
            );
            if (c) {
              return c.nome;
            }
            return "";
          },
        },
        orgao: {
          label: "Órgão",
          valor: (valor) => {
            const c = this.orgaos.find(
              (org) => parseInt(org.id) === parseInt(valor)
            );
            if (c) {
              return c.nome;
            }
            return "";
          },
        },
        ano: {
          label: "Ano",
          valor: (valor) => valor,
        },
      };

      for (let key of keys) {
        if (
          this.$route.query[key] != null &&
          this.$route.query[key] != undefined &&
          this.$route.query[key] !== ""
        ) {
          parametros.push({
            label: labels[key].label,
            key,
            valor: labels[key].valor(this.$route.query[key]),
          });
        }
      }

      const formatData = (data) => format(parseISO(data), "dd/MM/yyyy");

      if (
        this.$route.query.periodo_inicial_data &&
        this.$route.query.periodo_final_data
      ) {
        parametros.push({
          label: "Data do Ato Infralegal",
          key: ["periodo_inicial_data", "periodo_final_data"],
          valor: `${formatData(
            this.$route.query.periodo_inicial_data
          )} a ${formatData(this.$route.query.periodo_final_data)}`,
        });
      } else if (this.$route.query.periodo_inicial_data) {
        parametros.push({
          label: "Data do Ato Infralegal",
          key: ["periodo_inicial_data", "periodo_final_data"],
          valor: `A partir de ${formatData(
            this.$route.query.periodo_inicial_data
          )}`,
        });
      } else if (this.$route.query.periodo_final_data) {
        parametros.push({
          label: "Data do Ato Infralegal",
          key: ["periodo_inicial_data", "periodo_final_data"],
          valor: `Até ${formatData(this.$route.query.periodo_final_data)}`,
        });
      }

      if (
        this.$route.query.periodo_inicial_vigencia_inicio &&
        this.$route.query.periodo_inicial_vigencia_fim
      ) {
        parametros.push({
          label: "Período do Início da Vigência",
          key: [
            "periodo_inicial_vigencia_inicio",
            "periodo_inicial_vigencia_fim",
          ],
          valor: `${formatData(
            this.$route.query.periodo_inicial_vigencia_inicio
          )} a ${formatData(this.$route.query.periodo_inicial_vigencia_fim)}`,
        });
      } else if (this.$route.query.periodo_inicial_vigencia_inicio) {
        parametros.push({
          label: "Período do Início da Vigência",
          key: [
            "periodo_inicial_vigencia_inicio",
            "periodo_inicial_vigencia_fim",
          ],
          valor: `A partir de ${formatData(
            this.$route.query.periodo_inicial_vigencia_inicio
          )}`,
        });
      } else if (this.$route.query.periodo_inicial_vigencia_fim) {
        parametros.push({
          label: "Período do Início da Vigência",
          key: [
            "periodo_inicial_vigencia_inicio",
            "periodo_inicial_vigencia_fim",
          ],
          valor: `Até ${formatData(
            this.$route.query.periodo_inicial_vigencia_fim
          )}`,
        });
      }

      if (
        this.$route.query.periodo_final_vigencia_inicio &&
        this.$route.query.periodo_final_vigencia_fim
      ) {
        parametros.push({
          label: "Período do Fim da Vigência",
          key: ["periodo_final_vigencia_inicio", "periodo_final_vigencia_fim"],
          valor: `${formatData(
            this.$route.query.periodo_final_vigencia_inicio
          )} a ${formatData(this.$route.query.periodo_final_vigencia_fim)}`,
        });
      } else if (this.$route.query.periodo_final_vigencia_inicio) {
        parametros.push({
          label: "Período do Fim da Vigência",
          key: ["periodo_final_vigencia_inicio", "periodo_final_vigencia_fim"],
          valor: `A partir de ${formatData(
            this.$route.query.periodo_final_vigencia_inicio
          )}`,
        });
      } else if (this.$route.query.periodo_final_vigencia_fim) {
        parametros.push({
          label: "Período do Fim da Vigência",
          key: ["periodo_final_vigencia_inicio", "periodo_final_vigencia_fim"],
          valor: `Até ${formatData(
            this.$route.query.periodo_final_vigencia_fim
          )}`,
        });
      }

      //parametro de busca simples
      if (this.$route.query.termo) {
        parametros.push({
          label: "Termo",
          key: "termo",
          valor: this.$route.query.termo,
        });
      }

      return parametros;
    },
  },
};
</script>

<style lang="scss" scoped>
.grupo-parametros {
  margin: 0.5rem 0rem -0.5rem;
}
div {
  display: flex;
  flex-wrap: wrap;
  margin: 0rem 0rem 0.2rem 0.2rem;
}
@media screen and (min-width: 64rem) {
  .grupo-parametros {
    margin-top: 1.5rem;
  }
}
</style>