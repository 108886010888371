import axios from "axios";
import axiosInstance from "./axiosInstance";

const queryString = require("query-string");

class IndexAtoAdministrativoApi {
  cancelTokens = {
    getAtosAdministrativos: null,
    getTipos: null,
    getAnos: null,
    getTiposAgrupados: null
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getAtosAdministrativos({
    page = 1,
    qtd_por_pagina,
    tipo_ato,
    numero,
    nome,
    periodo_inicial_diario,
    periodo_final_diario,
    periodo_inicial_ato,
    periodo_final_ato,
    assunto,
    ano
  }) {
    const query = queryString.stringify({
      tipo_ato,
      numero,
      nome,
      periodo_inicial_diario,
      periodo_final_diario,
      periodo_inicial_ato,
      periodo_final_ato,
      ano,
      assunto,
      page,
      qtd_por_pagina,
    });

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getAtosAdministrativos = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/pesquisa/atos_administrativo?${query}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getAtosAdministrativos = null;

    return data;
  }

  async getTipos() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getTipos = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/pesquisa/atos_administrativo/tipos`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getTipos = null;
    return data;
  }


  async getAnos() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getAnos = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/pesquisa/atos_administrativo/anos`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getAnos = null;
    return data;
  }

  async getTiposAgrupados() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getTiposAgrupados = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/pesquisa/atos_administrativo/tipos_agrupados`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getTiposAgrupados = null;
    return data;
  }
}

export default IndexAtoAdministrativoApi;
